export default {
  account: {
    branding: {
      deleteLogoModal: {
        confirmationMessage: '¿Estás seguro que quieres eliminar el logotipo?',
        title: 'Eliminar Logotipo',
      },
      logoAlt: 'Logotipo de la empresa',
      message:
        'Su logotipo se utilizará en galerías compartidas, líneas de tiempos de proyectos compartidos y antes y después de las fotos, este debería ser <strong>.jpg</strong> or <strong>.png</strong> un tipo de archivo y debe ser de al menos 300 px de ancho.',
      noLogoAlt: 'Subir el logotipo',
      removeLogo: 'Eliminar el logotipo',
    },
    exports: {
      projects: {
        exportMessage:
          'Haga click en tel botón de abajo para generar un CSV que contenga información sobre todos los proyectos de su empresa. Le vamos a enviar por correo electrónico la exportación una vez que este listo.',
        exportProjects: 'Proyecto de exportación',
        submittedExportMessage:
          'Su exportación esta siendo generada y sera enviada a su correo electrónico en breve. Usted puede navegar de forma segura lejos de esta página.',
      },
      title: 'Las exportaciones',
      pickTimeAndData:
        'Elige el periodo de tiempo y los datos específicos que necesitas, y te enviaremos la exportación directamente a tu bandeja de entrada.',
      exportCSV: 'Exportar .CSV',
      exportToastTitle:
        'Le enviaremos un correo electrónico cuando tu exportación esté lista.',
      exportToastErrorTitle: 'Ocurrió un error',
      exportToastErrorMessage: 'Intenta exportar los datos nuevamente.',
      exportToastMessage:
        'Estamos generando tu exportación y se la enviaremos por correo electrónico en breve. No dude en abandonar esta página.',
    },
    integrations: {
      allIntegrations: 'Todas las Integraciones',
      common: {
        allSet: '¡Ya está todo listo!',
        connect: 'Conectar integración',
        creating: 'Creando…',
        deleteIntegration: 'Eliminar integración',
        integrate: 'Integrar',
      },
      connectedIntegrations: 'Integraciones Conectadas',
      emailUs: 'Envíanos un email a',
      estimatingProposals: 'Estimación/Propuestas',
      exploreTheFullList:
        'Explora la lista completa y conecta tus programas hoy.',
      headerTitle: 'Integraciones',
      help: {
        needHelp: '¿Necesitas ayuda integrando %{integrationPartner}?',
        tutorial: 'Echa un vistazo a nuestro tutorial',
      },
      integrationSuggestion:
        '¿Utilizas software que te encantaría integrar con CompanyCam?',
      newAndFeatured: 'Nuevo y Destacado',
      noConnectedIntegrations: 'No tienes ninguna integración conectada.',
      noResultsFound: 'No se han encontrado resultados',
      searchForIntegration: 'Buscar una Integración',
      tooltip: {
        accessibilityLabel: 'Más detalles',
      },
      input: {
        invalid: 'La entrada no es válida',
      },
      serviceTitan: {
        invalidCredential:
          'Formato de credenciales inválido: Consulta el tutorial para obtener ayuda.',
        invalidTenantId:
          'Tenant ID no válida: consulte el tutorial para obtener ayuda',
        missingCredential: 'Falta el Client ID o el Client Secret',
        missingTenantId: 'Falta el Tenant ID',
        actionRequiredMessage: {
          partOne:
            '¡Tu configuración está casi completa! Ingresa el Client ID y el Client Secret asociados con tu cuenta CompanyCam en ServiceTitan. Si tienes problemas para localizarlos, ',
          clickHere: 'haz clic aquí',
          partTwo:
            ' para obtener instrucciones más detalladas sobre dónde encontrarlos.',
        },
        pendingMessage: `Hemos recibido tu Tenant ID y estamos procesando tu solicitud. Esto no debería tomar más de unos minutos. Puedes actualizar esta página momentáneamente o volver a consultarla más tarde, donde podrás completar los pasos finales para conectar tu cuenta de ServiceTitan.\n\nSi ingresaste tu Tenant ID incorrectamente, puedes hacer clic en el botón "Cancelar Configuración" en la esquina superior derecha de la pantalla para reiniciar el proceso.`,
        connectedMessage:
          'La integración está conectada. Si necesitas actualizar tus credenciales, deberás desconectarte y comenzar el proceso de conexión de la integración de nuevo.',
      },
      shared: {
        cancelSetup: 'Cancelar Configuración',
        connect: 'Conectar',
        connectErrorMessage: 'Error al actualizar la integración',
        connectIntegrationName: 'Conectar %{integrationName}',
        connected: 'Conectado',
        crmFSM: 'CRM/FSM',
        disabled: 'Desactivado',
        disconnect: 'Desconectar',
        disconnectConfirmationMessage:
          '¿Seguro que quiere desconectar esta integración?',
        disconnectErrorMessage: 'Error al desconectar la integración',
        disconnectIntegrationName: 'Desconectar %{integrationName}',
        integrationsLabel: 'Integraciones',
        loading: 'Cargando Integraciones',
        overview: 'Descripción general',
        provider: 'Proveedor',
        settings: 'Configuración',
        updateErrorMessage: 'Error al actualizar la integración',
      },
      timeTracking: 'Rastreo de Tiempo',
      title: 'Manejar las integraciones',
      trySearchingDifferently:
        'Intente buscar diferentes palabras clave o seleccione un filtro de categoría diferente.',
      tutorial: 'Tutorial',
    },
  },
  activity: {
    tagContainer: {
      manageButton: 'Añadir / Administrar Etiquetas',
      updateTags: 'Actualizar etiquetas',
    },
    tagRecordablesModal: {
      manageTags: 'Administrar etiquetas',
      modalTitle: 'Seleccionar etiquetas',
      noResultsText: 'No se han encontrado resultados',
      selectOrCreatePlaceholder: 'Encuentra etiquetas o créalas…',
      selectPlaceholder: 'Seleccionar…',
      tagPhotos: 'Etiquetar Fotos',
      tagSectionPhotos: 'Tag all photos in a section',
    },
    comment: {
      subnote: 'Usuario invitado no puede ver las respuestas.',
    },
    commentContainer: {
      addComment: 'Añadir un comentario…',
    },
    commentList: {
      photoActivity: 'Comentarios',
    },
    commentPopup: {
      addComment: 'Añadir un comentario',
    },
    commentTranslate: {
      translate: 'Traducir a Español',
      seeOriginal: 'Ver Original',
    },
    tokenSuggestions: {
      noMatchingResults: 'No hay resultados coincidentes',
    },
    tokenizedInput: {
      somethingWentWrong: 'Algo salió mal con los comentarios.',
      alertMessage: 'Los colaboradores verán tus comentarios',
      commentsSeenByCollabs:
        'Los colaboradores con permiso verán tus comentarios',
      mentionSomeoneAriaLabel: 'Mencionar a alguién',
      post: 'Enviar',
    },
  },
  assetFeed: {
    filterRow: {
      assetTypeFilter: {
        photosOnlyLabel: 'Solo Fotos',
        videosOnlyLabel: 'Solo Videos',
      },
      clearAll: 'Borrar Selección',
      endDateFilter: {
        placeholder: 'Fecha Final',
      },
      groupFilter: {
        searchInput: 'Término de Búsqueda de Grupo',
      },
      noResultsLabel: 'No se encontraron %{filterType}',
      projectFilter: {
        searchInput: 'Término de Búsqueda de Proyectos',
      },
      sortedByLabel: 'Ordenados por Actividad Reciente',
      startDateFilter: {
        placeholder: 'Fecha Inicial',
      },
      tagFilter: {
        loading: 'Cargando tags...',
        searchInput: 'Término de Búsqueda de Tags',
      },
      uploadPhotos: 'Subir Fotos',
      userFilter: {
        searchInput: 'Término de Búsqueda de Usuario',
      },
    },
    viewMenu: {
      assetSizeToggle: {
        labelLarge: 'Grande',
        labelMedium: 'Mediano',
        labelSmall: 'Pequeño',
        title: 'Tamaño de Foto',
      },
      assetTypeToggle: {
        labelAll: 'Todo',
        labelPhotos: 'Solo Fotos',
        labelVideos: 'Solo Videos',
        title: 'Tipo',
      },
      showTagsToggle: {
        title: 'Mostrar Etiquetas',
      },
      sortOrderToggle: {
        labelNewest: 'Más Nuevo Primero',
        labelOldest: 'Más Viejo Primero',
        title: 'Orden',
      },
      tagsOperatorToggle: {
        andOperator: 'Y',
        orOperator: 'O',
        title: 'Filtrar Etiquetas Por',
      },
      buttonText: 'Vista',
    },
    altText:
      'Imagen de un conjunto de fotos en miniatura que representan una galería.',
    header: {
      title: 'Fotos',
    },
    emptyMessage: 'Cada foto agregada por tu equipo aparecerá aquí.',
    emptyTitle: 'Comience a Tomar Fotos',
    emptyState: {
      emptyTitle: 'Comienza a tomar fotos en la aplicación móvil.',
      emptyMessage:
        'Todas las fotos y videos tomados por tu equipo aparecerán aquí, de inmediato.',
      secondaryCta: 'Asignar Usuarios',
      emptyFilterList:
        'Lo sentimos, no hay fotos que se ajusten a los filtros seleccionados.',
      createProject: 'Crear proyecto',
      emptyStateQRTitle: '¿Tus fotos están desordenando tu galería?',
      emptyStateQRMessage:
        'Escanea este código QR para empezar a tomar y subir fotos a CompanyCam.',
      emptyStateInviteTitle: '¿Listo para recopilar fotos de tu equipo?',
      emptyStateInviteMessage:
        'Consejo: Saca las fotos de la galería de tu equipo invitándolos a tomar imágenes en CompanyCam.',
      emptyStateInviteButton: 'Invitar Usuarios',
    },
    noMorePhotos: '¡No hay más fotos que cargar!',
    uploadStatusCard: {
      successMessage: '¡Carga completada!',
      pendingCount: {
        one: 'un artículo esperando para subir',
        other: '%{count} artículos esperando para subir',
      },
      processingCount: {
        one: 'Procesando un artículo',
        other: 'Procesando %{count} artículos',
      },
    },
  },
  assets: {
    description: {
      addDescription: 'Agregar una descripción…',
      descriptionHint:
        'La información que sea agregada aquí puede ser utilizada en otros lugares en el futuro, como reportes!',
      header: 'Descripción',
      saved: 'Guardada',
    },
    galleryContainer: {
      editPhoto: 'Editar Foto',
      toggleFullscreen: 'Alternar Pantalla Completa',
      zoomIn: 'Acercar',
      zoomOut: 'Alejar',
    },
    reportModal: {
      clearSelected: 'Borrar Selección',
      selected: {
        one: 'Agregar una foto al informe',
        other: 'Agregar %{count} fotos al informe',
      },
    },
    selectedAssetBar: {
      actions: 'Acciones',
      addToReport: 'Agregar al informe existente',
      beforeAfterTooltip: 'Seleccione dos fotos para comenzar.',
      createBeforeAfter: 'Create Before & After',
      createFromTemplate: 'Crear Informe a Partir de la Plantilla',
      createNewReport: 'Crear nuevo informe',
      duplicate: 'Duplicar',
      gallery: 'Galería',
      hideInProjectTimeline: 'Esconder en el cronograma del proyecto',
      integrations: 'Integraciónes',
      mixedMediaWarning:
        '¡Atención! Cualquier video seleccionado no se añadirá a tu informe.',
      move: 'Mover',
      print: 'Imprimir',
      project: 'Proyecto',
      reports: 'Informes',
      setCoverPhoto: 'Establecer como imagen destacada',
      tag: 'Tag',
      unhideInProjectTimeline: 'Mostrar en el cronograma del proyecto',
    },
  },
  assignUser: {
    searchErrorMessage: 'Error cargando usuarios',
    searchNoResultsMessage: 'No se encontraron usuarios.',
  },
  assignUsers: {
    emptyStateMessage: 'Asigne a uno o más usuarios',
    restrictedUserConfirmationModal: {
      confirmButton: 'Sí, Asignar',
      message:
        'La asignación de este usuario le da acceso al proyecto. Podrá ver Fotos, agregar Fotos, unirse a Conversaciones y acceder a Informes y Listas de Tareas.',
      subtitle: '%{name} es un usuario Restringido',
      title: '¿Asignar Usuario Restringido?',
    },
    searchInProgressMessage: 'Encontrando a tu gente',
    searchInputPlaceholder: 'Buscar Usuarios',
    unassignButtonLabel: 'Desasignar',
  },
  billing: {
    itunesPlan: 'iTunes',
    basicPlan: 'Basic',
    premiumPlan: 'Premium',
    proPlan: 'Pro',
    elitePlan: 'Elite',
    unknownError:
      'Por favor, inténtalo de nuevo. Si el problema persiste, por favor contacte con soporte.',
    comparePlans: {
      annualOption: 'Paga una Vez al Año',
      monthlyOption: 'Paga una Vez al Mes',
      header: 'Comparar Planes',
      payMonthly: 'Paga mensualmente',
      payYearly: 'Paga Anualmente y Ahorra',
      returnToBilling: 'Volver a Facturación',
      goToBilling: 'Ir a Facturación',
      returnToProjects: 'Proyectos',
      billableUsers: {
        one: '1 Usuario Facturables',
        other: '%{count} Usuarios Facturables',
      },
      questions: '¿Preguntas?',
      chatWithSupport: 'Chatear con Soporte',
      reachOut:
        'Comuníquese con nuestro equipo de atencion al cliente y ellos podrán conectarlo con tu representante de ventas.',
      contact: 'Atención al Cliente',
      legacyWarningBase:
        'Estás en un plan antiguo. Si te cambias a otro plan, no podrás regresar, ',
      legacyWarningBold: 'a menos que estés en un periodo de prueba.',
      activateSampleError:
        'Se ha producido un problema al activar la prueba. Vuelve a intentarlo o ponte en contacto con nuestro equipo de asistencia.',
      activateSampleSuccess: '¡Prueba activada!',
      inactiveNoticeAdmin:
        'Tu cuenta está inactiva Elija un plan a continuación para reactivarlo.',
      inactiveNoticeNonAdmin: {
        header: 'Tu cuenta está inactiva',
        subheader:
          'Comuníquese con el Amin de tu cuenta para reactivar tu cuenta.',
      },
      inactiveNoticeAdminError: {
        header: 'Tu cuenta está inactiva',
        subheader:
          'Se produjo un error al obtener tus datos de facturación. Contacta al equipo de soporte.',
      },
    },
    billingCycle: {
      continueButton: 'Continuar',
    },
    card: {
      premiumFeaturesHeader: 'Todo en Pro y:',
      eliteFeaturesHeader: 'Todo lo que incluye Premium, y:',
      keyFeatures: 'Características Clave:',
      currentPlan: 'Plan Actual',
      choosePlan: 'Elija Plan',
      tryFreeSample: 'Pruébalo Gratis durante 14 días',
      freeTrialCTA: 'Empiza la Prueba Gratuita',
      perMonth: '/mes',
      popularTag: 'MÁS POPULAR',
      currentTrial: 'LA PRUEBA ACTUAL',
      limitedOffer: 'OFERTA LIMITADA',
      userIncludes: 'Incluye 3 Usuarios',
      singleUser: 'Incluye 1 Usuario',
      userPricing: '$%{userPrice} / Usuario Adicional / Mes',
      yearlyTag: 'USD / Facturación Anual',
      monthlyTag: 'USD / Facturación Mensual',
      websiteShowcaseWidget: 'Widget de Presentación del Sitio Web',
      reviews: 'Reseñas (Próximamente)',
      videoWalkthrough: 'Modo de Vídeo Dual',
      paymentProcessing: 'Procesamiento de Pagos',
      documentSigning: 'Firma de Documentos',
      checklistTemplates: 'Plantillas de Lista de Verificación',
      companyDashboard: 'Tablero de la Empresa',
      dedicatedSuccessManager: 'Gerente de Éxito Dedicado',
      logoStickers: 'Stickers con Logotipos',
      projectTemplates: 'Plantillas de Proyecto',
      reportTemplates: 'Plantillas de Informes',
      videoCapture: 'Captura de Vídeo de 10 minutos',
      aiReporting: 'Acciones Impulsadas por AI',
      photoWatermark: 'Fotos de Marca',
      collaboratorAccess: 'Acceso de Colaboradores',
      beforeAfters: 'Antes y Después',
      checklists: 'Listas de Tareas',
      integrations: 'Integraciones de Software',
      pdfReports: 'Informes en PDF',
      unlimitedStorage: 'Proyectos y Almacenamiento Ilimitados',
      unlimitedPhotoStorage: 'Almacenamiento Ilimitado de Fotos',
      fiveMinuteVideoCapture: 'Captura de Video de 5 minutos',
      unlimitedDocumentStorage: 'Almacenamiento Ilimitado de Documentos',
      unlimitedIntegrations: 'Integraciones Ilimitadas',
      unlimitedProjects: 'Proyectos Ilimitados',
      documentScanning: 'Escaneo de Documentos',
      photoAnnotations: 'Anotaciones en Fotos',
      photoCommentsTags: 'Comentarios y Etiquetas en Fotos',
      tenProjects: '10 Proyectos',
    },
    choosePlans: {
      header: 'Selecciona un plan para tu prueba gratuita de 14 días',
      annualOption: 'Paga una Vez al Año',
      monthlyOption: 'Paga una Vez al Mes',
      pageTitle: 'Elija Plan',
      reminderInfo:
        'Le enviaremos por correo electrónico un recordatorio de que su prueba finalizará pronto.',
      skipButton: 'Salta este paso',
      startTrial:
        'Comienza tu prueba gratuita de 14 días y obtén acceso instantáneo a la plataforma.',
      subscriptionInfo:
        'Tu suscripción comenzará a menos que la haya cancelado durante la prueba.',
      today: 'Hoy',
      trialInfoHeader:
        'Te enviaremos un recordatorio 2 días antes de que finalice tu prueba.',
      trialInfoSubHeader:
        'Cambia de plan o cancela en cualquier momento visitando tu página de facturación.',
      trialHeader: 'Elige un plan para tu prueba gratuita de 14 días',
      trialSubheader:
        'Recibirás un recordatorio antes de que finalice tu prueba. Puedes cambiar de plan o cancelar en cualquier momento desde tu página de facturación.',
    },
    creditCardForm: {
      addressLine_1: 'Dirección Linea 1',
      addressLine_2: 'Dirección Linea 2',
      city: 'Ciudad',
      country: 'País',
      postalCode: 'Código postal',
      processing: 'Procesando Pago',
      saveCard: 'Salve la tarjeta',
      state: 'Estado',
    },
    discount: {
      discountDetailsPart1:
        'Estás recibiendo un descuento de %{discountAmount}',
      discountDetailsPart2: 'que finaliza en %{end}',
    },
    index: {
      activeUsers: 'Usuarios activos',
      pageTitle: 'Datos de facturación',
      subscriptionType: 'Tipo de Suscripción',
    },
    invoice: {
      accountBalance: 'BALANCE DE LA CUENTA',
      coupon: 'Cupon: %{discountValue} monto descontado',
      greeting: 'Hola, %{companyName}',
      invoice: 'FACTURA: %{invoiceNumber}',
      paid: 'PAGADO',
      'paymentProcessed!': '¡Tu pago ha sido procesado!',
      print: 'Imprimir',
      questions: 'Preguntas? Correo electrónico hello@companycam.com',
      thanksCopy:
        'El total en la factura está basada en el número de usuarios activos en su cuenta. Tu suscripción es prepagada por el mes que viene.',
      thanksTitle: 'Gracias por usar CompanyCam',
      total: 'TOTAL',
    },
    invoiceLine: {
      subscriptionMessage: '%{quantity}x suscripción %{planName}',
    },
    invoiceList: {
      chargedAmount: 'Monto cargado',
      dateCharged: 'Fecha del cargo',
      invoice: 'Factura',
      servicePeriod: 'Periodo del servicio',
    },
    invoiceListItem: {
      view: 'Ver',
    },
    invoicesModal: {
      title: 'Historial de Pagos',
      ariaLabel: 'Contenido del Historial de Pagos',
    },
    paymentDetails: {
      addCard: 'Añadir tarjeta de crédito',
      cardEnding: '%{cardBrand} final %{cardLast4}',
      expires: 'Expiración %{expires_month}/%{expires_year}',
      updateCard: 'Actualizar tarjeta de crédito',
    },
    planDetails: {
      perMonth: 'por mes',
      perUser: 'por usuario',
    },
    planSummary: {
      basicFeatures: 'Funciónes Basicas',
    },
    stripePaymentModal: {
      timeline: {
        timelineHeaderOne: 'Hoy: Obtenga Acceso Instantáneo',
        timelineHeaderTwo: 'Día 12: Recordatorio de Prueba',
        timelineHeaderThree: 'Día 14: Comienza la Suscripción',
        timelineSubHeaderOne:
          'Desbloquee nuestras mejores funciones. Cancele en cualquier momento.',
        timelineSubHeaderTwo:
          'Le recordaremos que su prueba finalizará pronto. Tu suscripción empezará el',
        timelineSubHeaderThree: 'Tu suscripción empezará el',
        timelineTrialInfoFirst: 'Prueba gratuita de 14 días y después ',
        timelineTrialInfoMonth: '$%{price} al mes',
        timelineTrialInfoYear: '$%{price} al Año',
        timelineTrialInfoSubHeader:
          '3 usuarios incluidos en el precio inicial. Cada usuario adicional cuesta $%{monthlyPricePerUser} por mes. Más impuestos donde corresponda.',
      },
      paymentMethodFailed:
        'No se pudo cargar la información del método de pago existente',
      addPaymentMethod: 'Agregar Método de Pago',
      afterTrialDisclaimer:
        'La cantidad cobrada después de la prueba está sujeto a cambios según la cantidad de usuarios invitados. Mas impuestos donde apliquen',
      billingError:
        'Un error inesperado ha ocurrido, por favor inténtalo más tarde.',
      continue: 'Continuar',
      due: 'Pendiente',
      dueToday: 'Pendiente Hoy',
      paymentSaved: '¡Información de pago guardada!',
      annualPlan: 'Plan Anual %{planTitle} Annual Plan',
      monthlyPlan: 'Plan Mensual %{planTitle} Monthly Plan',
      reminderInfo: 'Te lo recordaremos 2 días antes de que finalice',
      startTrial: 'Comienza la Prueba Gratuita de %{planTitle}',
      timesThreeUsers: 'x 3 usuarios',
      tooltipAccessibilityLabel: 'Información de precios del usuario',
      verifying: 'Verificando',
      whenChargedInfo:
        'No se te cobrará hasta que finalices tu prueba gratuita.',
      confirm: 'Confirmar',
      paymentSource:
        '%{paymentSourceBrand} que termina en %{paymentSourceLast4}',
    },
    subscriptionCycle: {
      annualOption: 'Paga una Vez al Año',
      monthlyOption: 'Paga una Vez al Mes',
    },
    subscriptionDetails: {
      emptySeats: 'Tienes %{emptySeatsCount} asientos vacíos',
      savingsAmount: 'Cambiar a %{savingsAmount}',
      userCount: '%{userCount}',
      viewPaymentHistory: 'Ver Historial de Pagos',
    },
    subscriptionExpired: {
      accountNotActiveSubTitle:
        'Póngase en contacto con el administrador de su empresa para reactivar su cuenta.',
      accountNotActiveTitle: 'Su cuenta en CompanyCam no está activa.',
      addPaymentInfo: 'Agregar Información de Pago',
      chooseBillingCycle:
        'Elija si desea que se le facture mensual o anualmente y luego continúe.',
      signOutButton: 'Cerrar Sesión',
      signingOut: 'Cerrando Sesión...',
    },
    trialRemaining: 'La prueba termina %{readableTimeRemaining}',
    useSubscriptionPlanUpdate: {
      planUpdateFailed: 'Hubo un error al actualizar el plan',
      planUpdated: '¡Plan Actualizado!',
    },
    v2: {
      ach: {
        unableLinkBankAccount: 'No se pudo conectar la cuenta bancaria',
      },
      signaturesPromoModal: {
        notNow: 'Ahora No',
        getSignatures: 'Actualiza',
        signatures: 'Firmas',
        featureOne: 'Solicita, recolecta y guarda firmas digitales',
        featureTwo: 'Obten acceso con la tarifa plana para todo tu equipo',
        featureThree: 'Desarrollado por signo de DropBox',
      },
      addOn: {
        addOn: 'Servicio Adicional',
        cancelled: 'Cancelado',
        checkoutModal: {
          learnMore: 'Aprende mas',
          payNow: 'Pagar ahora',
          paymentError: {
            message:
              'No hemos podido procesar tu pago. Por favor, inténtalo de nuevo.',
          },
          paymentInformation: 'Información del pago',
          processingPayment: 'Procesando pago',
          signaturesTitle: 'Obtén Signatures en CompanyCam',
          totalDescription: 'Hoy se te cobrará una cantidad prorrateada',
          totalDue: 'Total debido',
        },
        manageAddOn: 'Administrar adición',
        nextPaymentOn: 'Próximo pago en %{date}',
        perMonth: '%{price}/mes',
        perYear: '%{price}/año',
        pricePerMonth: '%{price} / mes',
        pricePerYear: '%{price} / año',
        promoModal: {
          adminOnlyPrompt:
            'Solo el admin de la cuenta puede habilitar esta función.',
          upgrade: 'Actualiza',
          upgradePrompt:
            'Actualiza a una suscripción para comprar este complemento.',
        },
        renewAddOn: 'Renovar lo que añadió',
        signaturesPrice: '$49/mes',
        signaturesPurchasedModal: {
          subtitle:
            'Ahora puede obtener documentos firmados en CompanyCam. Haga clic en los tres puntos en cualquier documento PDF y elija Solicitar firmas para comenzar. Puede administrar esta suscripción en cualquier momento en su página de facturación.',
          title: '¡Agregado! Vamos a firmar.',
          watchTutorial: 'Ver el Tutorial',
        },
        startCheckoutSessionError:
          'Hubo un problema al preparar tu pago. Inténtalo de nuevo.',
        title: 'Servicio Adicional',
        tooltip:
          'Los complementos son características disponibles para los titulares de cuentas Pro y Premium para comprar a una tarifa plana (no por usuario)',
      },
      addPaymentInfo: 'Agregar Información de Pago',
      annualSavings: '($%{annualSavings} annual savings)',
      confirmYourPlan: 'Confirma Tu Plan',
      creditCardInfo: {
        addCard: 'Agregar Tarjeta de Crédito',
        message: 'expiración %{expiry}',
        subtitle: '%{cardBrand} final %{lastFour}',
        updateCard: 'Actualizar tarjeta de crédito',
      },
      currentPaymentInfo: {
        accountInfo: '%{institution} •••• %{lastFour}',
        linkPaymentMethod: 'Conectado a través de Stripe Link',
        updatePaymentMethod: 'Actualizar Método de Pago',
      },
      currentPlan: {
        activeUsers: 'Usuarios Activos',
        billingPeriod: 'Periodo de facturación',
        cancelPlan: 'Cancelar Plan',
        comparePlansPage: 'Ver Planes Disponibles',
        emptySeatsPlural: {
          one: '%{count} asiento abierto',
          other: '%{count} asientos abiertos',
        },
        manageUsers: 'Gestionar Usuarios',
        perUserPerMonth: '%{price}/usuario/mes',
        perUserPerYear: '%{price}/usuario/año',
        planCancelsOn: 'Programado para cancelar el %{cancellationDate}',
        switch: 'Cambiar a %{title}',
        switchAndSave: 'Cambiar a %{title} (ahorrar %{amount})',
        switchWithoutSave: 'Cambiar a %{title}',
        title: 'Plan Actual',
        trial: '%{title} (Prueba)',
        trialEndsIn: 'La prueba actual termina en %{date}',
      },
      index: {
        error: 'Occurió un error al cargar la información de facturación.',
        title: 'Facturación',
        tryAgain: 'Inténtalo de nuevo',
        selfServiceDisabled:
          'La facturación de autoservicio está deshabilitada en su cuenta. Comuníquese con el soporte para obtener ayuda.',
      },
      itunesInfo: {
        manageSub: 'Gestionar Suscripción iTunes',
        message:
          'Actualmente estás pagando con una suscripción a través de Apple.',
        subtitle: 'iTunes',
      },
      manualInfo: {
        emailSupport: 'Enviar un email al departamento de apoyo',
        message:
          'Actualmente estás en un plan personalizado. Envía un email a support@companycam.com para pedir cualquier cambio a tu plan actual.',
        subtitle: 'Plan Personalizado',
      },
      nextPayment: {
        on: 'Tu próxima factura será cargada el %{date}',
        planExpires: 'Plan Expires',
        title: 'Próxima factura',
        viewPaymentHistory: 'Ver Historial de Pagos',
      },
      paymentMethod: {
        addPaymentMethod: 'Agregar Método de Pago',
        cardUpdated: '¡Se actualizó la tarjeta!',
        paymentDetails: 'Detalles de Pago',
        title: 'Método de pago',
        upgradePlan: 'Actualizar Plan',
      },
      plaid: {
        linkBankAccount: 'Vincular cuenta bancaria',
        updateBankAccount: 'Actualizar Cuenta Bancaria',
      },
      premiumSample: {
        cancelPremiumFeatureFive: 'Plantillas de informes',
        cancelPremiumFeatureFour: 'Plantillas de proyecto',
        cancelPremiumFeatureOne: 'Plantillas de lista de verificación',
        cancelPremiumFeatureSix: '¡Stickers con logo y más!',
        cancelPremiumFeatureThree: 'Vídeos 4K',
        cancelPremiumFeatureTwo: 'Captura de alta calidad',
        cancelPremiumSampleBadge: 'Activo',
        cancelPremiumSampleCardButtonLabel: 'Cancelar Prueba',
        cancelPremiumSampleCardText:
          'Actualmente puede probar funciones adicionales sin costo adicional. Cuando finalice el periodo de prueba, se le actualizará a este plan y se le cobrará en consecuencia, a menos que vuelva a su plan anterior haciendo clic a continuación.',
        cancelPremiumSampleError:
          'Ha habido un problema al cancelar tu prueba. Ponte en contacto con el servicio al cliente.',
        cancelPremiumSampleHeader: '%{productName} Gratuita',
        cancelPremiumSampleLabel: 'Sí, cancelar mi actualización',
        cancelPremiumSampleModalTitle: 'Si cancelas, perderás el acceso a...',
        canceledPremiumSampleMessage: 'Tu prueba Premium ha terminado',
        declineCancelPremiumSampleLabel: 'No, No Cancelar',
        due: 'Pendiente',
        nevermind: 'Olvídalo',
        premiumCardTitle: 'Prueba Premium gratuita de 14 días',
        premiumCardSampleButtonLabel: 'Pruébelo gratis',
        premiumCardSampleFeaturesOne:
          'Obtenga plantillas de Proyectos, Informes, Documentos, y Listas de Tareas',
        premiumCardSampleFeaturesThree:
          '¡Impresione a los clientes con recorridos en video y más!',
        premiumCardSampleFeaturesTwo:
          'Prueba asignando las listas de Tareas para mantener a tu equipo al día',
        premiumSampleDaysLeft: 'Todavía te quedan %{time} de prueba gratuita',
        premiumSampleModalCancelCopy:
          'En cualquier momento durante tu prueba, visita tu página de facturación en la web y haz clic en el botón debajo de tu información de suscripción que dice “Volver a Pro“',
        premiumSampleModalCancelHeading: '¿Quieres volver a tu plan Pro?',
        premiumSampleModalTitle: 'Tu línea de tiempo de Prueba Premium',
        premiumSampleStartActivated: 'Prueba Premium activada.',
        premiumSampleTimelineHeadingOne: 'Hoy',
        premiumSampleTimelineHeadingThree: 'En 14 días',
        premiumSampleTimelineHeadingTwo: 'En 12 días',
        premiumSampleTimelineSubHeadingOne:
          'Obtenga acceso a funciones Premium como listas de Tareas y Plantillas de Proyectos.',
        premiumSampleTimelineSubHeadingThree:
          'Se le cobrará unay mantendrá tu acceso a todas las funciones Premium.',
        premiumSampleTimelineSubHeadingTwo:
          'Recibirá un recordatorio por correo electrónico de que tu prueba finalizará pronto.',
        startSample: 'Iniciar mi prueba gratuita',
        sampleLoading:
          'Activando funciones Premium para toda tu empresa. Esto puede tomar un momento.',
        sampleStartError:
          'Hubo un problema al iniciar su versión de prueba. Intenta de nuevo.',
        upgrade: 'Actualiza',
        whatIsPremium: '¿Qué está incluido con Premium?',
        whatIsIncluded: '¿Qué está incluido con %{productName}?',
        cancelModalTitle: 'Cancelar Prueba',
        cancelModalBody:
          '¿Estás seguro de que quieres cancelar tu prueba? Si la cancelas, perderás el acceso a las funciones avanzadas de inmediato.',
        cancelModalConfirm: 'Si, Cancelar',
        cancelModalDecline: 'No, No Cancelar',
        canceledSampleMessage: 'Su Período de Prueba ha Finalizado',
        disallowedPremiumSampleActions:
          'Esta acción no está disponible en su prueba actual. Para cancelar su prueba, haga clic abajo en el botón "Volver a Pro".',
      },
      sampleBanner: {
        title:
          'Desbloquee poderosas funciones como la firma de documentos, el procesamiento de pagos y mucho más - ¡gratis durante 14 días!',
        seeDetails: 'Ver Detalles',
      },
      sample: {
        actionNotAllowed:
          'Esta acción no está disponible en tu versión de prueba actual.',
        currentlyOnElite:
          'Actualmente estás en una prueba Elite. Al finalizar la prueba, se te cobrará por una cuenta Elite a menos que regreses a %{currentPlan} haciendo clic a continuación.',
        currentlyOnPremium:
          'Actualmente estás en una versión de prueba Premium. Cuando expire la versión de prueba, se le cobrará por una cuenta Premium a menos que vuelva a %{currentPlan} haciendo clic a continuación.',
        timeRemaining: 'Quedan %{timeRemaining}',
        errorActivatingTrial:
          'Se ha producido un problema al activar la prueba. Vuelve a intentarlo o ponte en contacto con nuestro equipo de asistencia.',
        learnMoreAboutButtonLabel: 'Más Información Sobre %{productName}',
        legacyPlanNotice:
          'Estás en un plan antiguo. Si te cambias a otro plan, no podrás regresar, a menos que estés en un periodo de prueba.',
        plan: 'Plan',
        returnToButtonLabel: 'Regresar a %{productName}',
      },
      trial: {
        subtext: 'No se te cobrará hasta que finalices tu prueba gratuita',
      },
      updatePaymentInfo: 'Actualizar Información de Pago',
      upgrade: {
        annual: '%{planTitle} por año',
        annualSavings: '(ahorrar $%{savings} por año)',
        monthly: '%{planTitle} por mes',
        total: 'Total Adeudado',
      },
      userCount: {
        one: '%{count} usuario',
        other: '%{count} usuarios',
      },
    },
  },
  checklistFeed: {
    checklistFilter: {
      all: 'Todos',
      complete: 'Completado',
      incomplete: 'Incompletado',
    },
    checklistRow: {
      completedProgress: '%{tasksCompleted} de %{totalTasks} Completado',
    },
    index: {
      exportTooltip:
        "Haz clic aquí para ir a 'Exportaciones' y descargar un resumen de todos los datos de la lista de verificación",
      exportLink: 'Exportar datos de la lista de verificación',
      completeEmptyState: 'No se encontró ninguna completa lista de Tareas',
      emptyStateDescription:
        'Las listas de Tareas creadas dentro de un proyecto se mostrarán aquí',
      emptyStateHeader: 'No se encontró ninguna lista de Tareas',
      errorState:
        'Tus listas de Tareas están seguras, pero debido a un problema técnico de nuestra parte, no pudimos cargarlas. Intente refrescar la página, pero si el problema persiste, comuníquese con Soporte.',
      featureName: 'Listas de Tareas',
      incompleteEmptyState: 'No se encontró ninguna incompleta lista de Tareas',
      searchPlaceholder: 'Buscar un lista de tarea',
      title: 'Listas de Tareas',
    },
  },
  colors: {
    red: 'Rojo',
    orange: 'Naranja',
    yellow: 'Amarillo',
    green: 'Verde',
    blue: 'Azul',
    purple: 'Morado',
    navy: 'Azul Marino',
    lightgray: 'Gris Claro',
    darkgray: 'Gris Oscuro',
  },
  common: {
    images: 'Imágenes',
    account: 'Cuenta',
    active: 'Activo',
    add: 'Add',
    all: 'Todos',
    and: 'y',
    ariaLabelWaveEmoji: 'Emoji de mano agitando',
    assign: 'Asignar',
    back: 'Volver',
    beta: 'BETA',
    cancel: 'Cancelar',
    cancelDelete: 'No, No Eliminar',
    checklists: 'Listas de Tareas',
    clear: 'Borrar',
    close: 'Cerrar',
    collaborations: 'Colaboraciones',
    comingSoon: 'Próximamente',
    comments: 'Comentarios',
    companyName: 'Nombre de la empresa',
    confirm: 'Confirmar',
    confirmDelete: 'Sí, Eliminar',
    confirmDeleteMessage: '¿Estás seguro que quieres eliminar este elemento?',
    contacts: 'Contactos',
    continue: 'Continuar',
    copied: 'Copiado',
    copy: 'Copiar',
    copyLink: 'Copiar Enlace',
    copySuccess: 'Enlace copiado al portapapeles',
    create: 'Crear',
    customers: 'Clientes',
    delete: 'Eliminar',
    deleted: 'Eliminado',
    deleteProject: 'Eliminar proyecto',
    documents: 'Documentos',
    done: 'Completo',
    download: 'Descargar',
    downloadModified: 'Descargar la modificada',
    downloadOriginal: 'Descargar la original',
    downloadPDF: 'Descargar PDF',
    edit: 'Editar',
    email: 'Email',
    edited: 'Editado',
    emailAddress: 'Email',
    error: 'O rayos!',
    export: 'Exportar',
    exportPdf: 'Exportar PDF',
    exportToPdf: 'Exportar a PDF',
    gotIt: '¡Entiendo!',
    groups: 'Grupos',
    hidePhoto: 'Ocultar Foto',
    hidePhotos: 'Ocultar Fotos',
    label: 'Etiqueta',
    labels: 'Etiquetas',
    lastUpdated: 'Última actualización',
    lastUsed: 'Último usado',
    learnMore: 'Aprende Más',
    learnHow: 'Aprende Como',
    loadMore: 'Cargar Más',
    loading: 'Cargando…',
    menu: 'Menu',
    next: 'Siguiente',
    moveToTrash: 'Mover a la Basura',
    new: 'Nuevo',
    numberSelected: {
      one: '%{count} seleccionada',
      other: '%{count} seleccionadas',
    },
    other: 'Otro',
    pages: 'Páginas',
    password: 'Contraseña',
    payments: 'Pagos',
    percentComplete: 'Porcentaje Completo',
    people: 'People',
    phoneNumber: 'Número de teléfono',
    photos: 'Fotos',
    previous: 'Anterior',
    projects: 'Proyectos',
    recentlyUsed: 'Usado recientemente',
    remove: 'Remover',
    reports: 'Informes',
    retry: 'Procesar de nuevo',
    save: 'Guardar',
    saveAll: 'Guardar Todo',
    scroll: {
      left: 'Desplazar hacia la izquierda',
      right: 'Desplazar hacia la derecha',
    },
    search: 'Buscar',
    share: 'Compartir',
    tags: 'Tags',
    taskList: 'Tareas',
    thisCompany: 'esta empresa',
    title: 'Título',
    toggleOff: 'No',
    toggleOn: 'Sí',
    tryAgain: 'Inténtalo de Nuevo',
    type: 'Tipo',
    uhOh: 'Uh Oh!',
    update: 'Actualizar',
    updating: 'Actualizando…',
    upgrade: 'Actualiza',
    users: 'Usuarios',
    view: 'Ver',
    workEmail: 'Dirección email de trabajo',
    untitled: 'Sin título',
    submit: 'Someter',
    templates: 'Plantillas',
    changesSaved: 'Cambios Guardados',
    unexpectedError:
      'Un error inesperado ha ocurrido, por favor inténtalo más tarde.',
    portfolio: 'Portafolio',
    notNow: 'Ahora No',
    thanks: '¡Gracias por usar CompanyCam!',
    thank: 'Gracias',
    notApplicable: 'No aplica',
    dismiss: 'Despedir',
    connect: 'Conectar',
    location: 'Ubicacion',
    exportingPdf: 'Exportar PDF…',
    name: 'Nombre',
    chooseAProject: 'Elija un Proyecto',
    payNow: 'Pagar ahora',
    somethingWentWrong: 'Algo Salió Mal',
    tryRefreshing:
      'Esta página no se cargó correctamente. Intenta actualizarla.',
  },
  company: {
    dashboard: {
      latest: {
        activityTooltip:
          'Los tipos de actividades incluyen: tomar fotos o videos, agregando documentos, dejando comentarios, agregando y completando campos de la lista de verificación y creando exhibiciones.',
      },
    },
  },
  companyDashboard: {
    surveyNoticeOpen: 'Abrir Encuesta',
    surveyNoticeClose:
      'Cerrar el banner de la encuesta del panel de la empresa',
    surveyNotice:
      '¿Tienes comentarios sobre las métricas mostradas? Agenda una platica con nuestro equipo de producto o cuéntanos más en una encuesta.',
    refreshTooltip: 'Los datos de esta página se actualizan cada 24 horas.',
    lastUpdated: 'Última actualización: {{date}} {{timezone}}',
    dataRefreshedNotice:
      'Los datos de esta página se actualizan cada 24 horas y no son en tiempo real. Es posible que la actividad que tenga lugar en tu empresa hoy no se incluya aquí hasta mañana.',
    dateFilter: {
      today: 'Hoy',
      allTime: 'De Todo Tiempo',
      lastSixtyDays: '60 Días',
      lastThirtyDays: '30 Días',
      week: '1 Semana',
      year: '1 Año',
      yesterday: 'Ayer',
    },
    metrics: {
      fieldsCompleted: 'Campos Completados',
      perProject: '%{number}/proyecto',
      showcases: '%{number} exhibiciones',
      fetchError:
        'Hubo un error al cargar las estadísticas de la empresa. Por favor actualiza la página y vuelve a intentarlo.',
    },
    pageTitle: 'Tablero de la Empresa',
    section: {
      overview: 'Resumen',
    },
    userTable: {
      addUserButton: 'Agregar Usuario',
      searchInput: 'Buscar Usuario...',
      columnHeaderDocuments: 'Documentos',
      columnHeaderFieldsCompleted: 'Campos Completados',
      columnHeaderLatestActivity: 'Última actividad',
      columnHeaderPhotos: 'Fotos',
      columnHeaderReports: 'Informes',
      columnHeaderRole: 'Role',
      columnHeaderUser: 'Usuario',
      deactivated: 'Desactivado',
      deactivatedUsersDisclaimer:
        'Los usuarios desactivados no están incluidos en estos resultados.',
      fetchError:
        'Hubo un error al cargar las estadísticas de los usuarios. Por favor actualiza la página\n          y vuelve a intentarlo.',
      invited: 'Invitado',
      loadingTitle: 'Compilando estadísticas (puede ser que tome un momento)',
      noActivity: 'Ninguna actividad',
      today: 'Hoy',
    },
    modal: {
      feature1:
        'Percepciones de alto nivel sobre la productividad en toda la empresa',
      feature2: 'Cómo cada miembro está utilizando la aplicación',
      feature3: '¡Y más!',
      introMessage: 'Los Administradores y Gerentes ahora pueden ver:',
      notNowButton: 'Ahora No',
      okButton: 'De Acuerdo',
      premiumAdMessage:
        'Hola 👋 ¿Tienes curiosidad por ver cómo los miembros de tu equipo están utilizando CompanyCam? Comunícate con tu administrador para mejorar tu cuenta y acceder a los Tableros de la Empresa.',
      premiumUpgradeMessage:
        'Actualiza tu plan para descubrir cómo puedes mejorar la eficiencia',
      upgradeButton: 'Actualizar',
    },
    modalTitle: '¡Los Tableros de la Empresa ya están aquí!',
  },
  companyDocument: {
    delete: {
      confirmationMsg:
        '¿Está seguro de que desea eliminar este Documento de proyecto? Se eliminará si se usa en una Plantilla de Proyecto, pero no de ningún proyecto existente',
      title: 'Eliminar documento de proyecto',
    },
    displayError: 'No podemos mostrar este documento.',
    errorDescription:
      'Este documento no es compatible con nuestra vista previa, pero puede descargarlo haciendo clic en el botón a continuación.',
  },
  companyDocuments: {
    delete: {
      errorMessage: 'Hubo un error al eliminar el documento',
      successMessage: 'Documento eliminado.',
    },
    updateErrorMessage:
      'No se pudo actualizar el nombre del documento. Por favor intente de nuevo',
    updateSuccessMessage: 'Nombre del documento actualizado',
    upload: {
      addDocuments: 'Subir documentos',
      dragAndDropMessage:
        'Arrastrar y suelta el documento aquí o haz clic para subirlo a este proyecto.',
      errorMessage: 'La subida falló. Por favor, inténtalo de nuevo.',
      errorMessagePlural:
        'No se pudo subir %{fileName} y %{count} otros archivos',
      maxFileSize: 'Tamaño máximo de archivo de 100MB.',
      maxFileSizeSignatures:
        'Si desea que se firme este documento, cárguelo como un archivo .pdf o .docx de menos de 40 MB y 500 páginas.',
      successMessage: 'Se ha cargado el archivo.',
      successMessagePlural: 'Se han cargado los archivos.',
      uploadMessaging: 'Uploading %{count} documents…',
    },
  },
  companySize: {
    label: 'Tamaño de la Empresa (número de empleados)',
    select: 'Seleccione uno...',
  },
  core: {
    confirmationModal: {
      contentLabel: 'Confirmación',
    },
    errorBoundaryFallback: {
      heading: '¡Uy!',
      message: 'Un error ha occurrido',
    },
    errorResetButton: {
      message: 'Inténtalo de nuevo',
    },
    forms: {
      wizard: {
        photoCount: {
          one: 'Review %{photoCount} seleccionado',
          other: 'Review %{photoCount} seleccionados',
        },
        nextStepButton: 'Paso Siguiente',
        previousStepButton: 'Paso Anterior',
      },
    },
    guestSelect: {
      noResultsText: 'No se han encontrado resultados',
      optionLabel: 'Crear invitado con correo electrónico: %{name}',
      placeholder: 'Tipear correo electrónico o crear usuario invitado',
    },
    projectSelect: {
      selectPlaceholder: 'Selecte un projecto',
    },
  },
  customers: {
    tabs: {
      disabledTooltip: 'Crear enlace de invitación primero.',
    },
    hypeModal: {
      checklist: {
        one: 'Toma y sube fotos que se sincronizan con CompanyCam',
        three: 'Manténgase actualizado con notificaciones de proyectos',
        two: 'Pague con tarjeta de crédito, ACH, Apple Pay o Google Pay',
      },
      description:
        'Invite a los clientes a utilizar Trusty, el nuevo portal para clientes de CompanyCam, donde podrán:',
      imageAltText: 'Aplicación Trusty en el teléfono',
      logoAltText: 'Logotipo de Trusty',
      title: 'Una manera más fácil de coordinar con tus clientes.',
    },
    index: {
      backToProjectLabel: 'Volver al Proyecto',
      customersHeaderTitle:
        'Envía invitaciones a Trusty, nuestra aplicación de portal para clientes.',
      photosHeaderTitle: 'Gestiona qué fotos se pueden ver en Trusty.',
      trustyUsers: 'Usuarios de Trusty',
    },
    invite: {
      get: {
        error: 'Algo salió mal al obtener el enlace de invitación de Trusty.',
      },
      post: {
        error: 'Algo salió mal al crear el enlace de invitación de Trusty.',
      },
      revokeAccess: {
        confirmLabel: 'Sí, revocar',
        failed: 'No pudimos revocar el acceso del cliente en este momento.',
        info: 'Está seguro de que desea revocar el acceso de los clientes a este proyecto? Debe crear una nueva invitación si cambia de opinión.',
        success: 'El acceso del cliente ha sido revocado.',
        title: 'Revocar acceso de cliente',
      },
    },
    photos: {
      allPhotos: 'Todas las Fotos del Proyecto',
      confirmLabel: 'Compartir Todo',
      confirmationMessage:
        'Todas las fotos y vídeos existentes en este proyecto serán visibles en la aplicación Trusty.',
      confirmationTitle: '¿Compartir Todas las Fotos del Proyecto?',
      selectPhotos: 'Seleccionar Fotos',
      shareAllError:
        'No se pudieron compartir todas las fotos. Por favor inténtalo de nuevo.',
      shareAllSuccess:
        'Compartiendo fotos del proyecto. Esto podría tardar unos minutos.',
      sharePhotos: 'Compartir Fotos',
    },
    projectInvitationLink: {
      create: 'Crear enlace de invitación',
    },
    restrictedMedia: {
      emptyAlt: 'Gráfico de dos manos chocando.',
      emptyMessage:
        'Los clientes pueden ver todas las fotos y videos de este proyecto.',
      emptyTitle: 'No queda nada que compartir',
      errorMessage: 'No pudimos cargar los medios para este proyecto.',
      errorToastMessage: 'No se pudieron compartir fotos. Inténtalo de nuevo.',
      loadingA11y: 'Cargando los medios restringidos del proyecto',
      selectToShare: 'Selecciona fotos del proyecto para compartir.',
      successToastMessage: {
        one: 'Review %{count} foto',
        other: 'Review %{count} fotos',
      },
    },
    selectedMediaActionBar: {
      confirmMessage:
        'Una vez que se oculta una foto, ya no será visible en la aplicación Trusty.',
      confirmTitle: '¿Ocultar Foto?',
      errorToastMessage:
        'Ocurrió un error al ocultar las fotos. Por favor inténtalo de nuevo.',
      photoHiddenToastMessage: 'foto ocultada.',
      photosHiddenToastMessage: 'fotos ocultadas.',
      pluralConfirmTitle: '¿Ocultar Fotos?',
    },
    trustyMedia: {
      emptyImageAltText:
        'Un conjunto de miniaturas de fotos que representan fotos en Trusty',
      emptyText:
        'Todas las fotos y vídeos visibles en Trusty se mostrarán aquí.',
      emptyTitle: 'Mantenga un Registro de las Fotos Compartidas',
      errorText: 'No pudimos cargar los medios compartidos para este proyecto.',
      skeletonLoaderAltText: 'Cargando los medios compartidos del proyecto',
    },
    trustyUsersList: {
      emptyImageAltText:
        'Trusty logo con pagos y gráficos en miniatura de fotografías',
      emptyText: 'Los clientes que tienen acceso a Trusty se mostrarán aquí.',
      emptyTitle: 'Gestionar el Acceso de los Clientes',
      errorText: 'No pudimos cargar los clientes para este proyecto.',
      infoColumnTitle: 'Información',
      nameColumnTitle: 'Nombre',
      skeletonLoaderAltText: 'Cargando clientes',
      statusColumnTitle: 'Estado de la Cuenta',
    },
    optionsMenu: {
      accessibilityLabel: 'Abrir Menú de Opciones de Invitación de Proyecto',
    },
  },
  documents: {
    addFromCompanyDocuments: {
      errorMessage:
        'Hubo un problema al agregar el documento. Inténtalo de nuevo.',
    },
    altText: 'Imagen de un conjunto de documentos.',
    cancelSignatureRequest: {
      error: 'Hubo un problema al cancelar esta solicitud de firma',
      success: 'Esta solicitud de firma ha sido cancelada',
    },
    cancelSignatureRequestModal: {
      message: 'Su documento se guardará en su estado original sin firmas',
      title: 'Cancelar solicitud de firma?',
    },
    companyDocument: {
      addDocumentButton: 'Agrega documento',
      addToProject: 'Agregar al Proyecto',
      addsDocuments: 'Añadir documentos',
      editTitle: 'Editar Título del Documento',
      emptyMsg: 'Optimice tus proyectos con Documentos de proyecto',
      manageDocuments: 'Manejar Documentos',
    },
    delete: {
      confirmCancel: 'Sí, cancelar',
      error: 'Hubo un error al eliminar este documento.',
      rejectCancel: 'No, no cancelar',
      success: 'Documento eliminado.',
    },
    deleteDocumentModal: {
      confirmationMessage: '¿Está seguro de que desea eliminar este documento?',
      secondaryConfirmation: 'Guarde una copia sin firmar del documento',
      title: 'Eliminar Documento',
    },
    document: {
      addons: 'Servicios Adicionales',
      cancelSignatureRequest: 'Cancelar solicitud de firma',
      connectingdropbox: 'Conectando a Dropbox Sign',
      creator: '%{creator}',
      manageAccess: {
        title: 'Administrar acceso',
      },
      rename: 'Dar un nuevo nombre',
      requestSignatureFileLimitation:
        'Los archivos de más de 40 MB no se pueden firmar',
      requestsignature: 'Solicitar Firmas',
      save: 'Salvar',
      signatures: 'Firmas',
      signaturesError: 'Solicitud Fallida',
      signaturesPending: 'Firmas Pendientes',
      signaturesSigned: 'Firmado',
    },
    documentViewer: {
      downloadError: 'Hubo un problema al descargar este documento',
      downloadUnsigned: 'Descargar sin firmar',
      error: {
        heading: '¡Uy!',
        noaccess: {
          backToProjectButton: 'Volver al Proyecto',
          heading: 'Documento restringido',
          subheading:
            'Solo la persona que lo subió, los Admins y los Managers pueden ver este documento.',
        },
      },
      errorResetButton: 'Inténtalo de nuevo',
      load: {
        error: {
          message: 'Un error ha occurrido',
        },
      },
      loadingFailed: 'Tenemos problemas para cargar tu documento',
    },
    index: {
      addDocuments: 'Subir Documentos',
      collabNotification: 'Sólo %{companyName} podrá ver los documentos.',
      collabNotificationStrong:
        'No se comparten los documentos entre colaboradores.',
      emptyMessage: 'Escanea y sube documentos, recibos y más.',
      emptyTitle: 'Mantenga los Archivos Organizados',
      fromCompanyDocuments: 'Utilizar la plantilla existente',
      fromComputer: 'Desde la Computadora',
      newestFirst: 'El más reciente primero',
      noResults: 'No resultados!',
      oldestFirst: 'El más antiguo primero',
      order: 'Ordenar',
      searchPlaceholder: 'Buscar un documento…',
    },
    requestSignatureRequest: {
      error: 'Hubo un problema al crear esta solicitud de firma',
    },
    sendSignatureRequest: {
      success: '¡Solicitud de firma enviada!',
    },
    singleShareModal: {
      shareDocument: 'Compartir documento',
    },
    upload: {
      addDocuments: 'Subir Documentos',
      dragAndDropMessage:
        'Arrastrar y suelta el documento aquí o haz clic para subirlo a este proyecto.',
      maxfilesize: 'Tamaño máximo de archivo de 100MB.',
      maxfilesizesignatures:
        'Si desea que se firme este documento, cárguelo como un archivo .pdf o .docx de menos de 40 MB y 500 páginas.',
      success:
        'Todos de sus archivos aceptados han sido cargados correctamente!',
      uploadMessaging: 'Cargados %{count} de documentos…',
    },
  },
  dropdown: {
    trigger: {
      defaultAriaLabel: 'Abrir menú de opciones',
    },
  },
  exportTaskList: {
    exportPdfToastMessage:
      'Estamos creando tu PDF - cuando esté terminado, te enviaremos una notificación y podrás descargarlo desde allí.',
    exportPdfToastTitle: 'Te avisaremos cuando tu PDF esté listo.',
  },
  filters: {
    noResultsFound: 'No se encontraron {filterType}',
    labelQueryTerm: 'Término de Búsqueda de Etiqueta',
    tagQueryTerm: 'Término de Búsqueda de Tags',
    userQueryTerm: 'Término de Búsqueda de Usuario',
    groupQueryTerm: 'Término de Búsqueda de Grupo',
    projectQueryTerm: 'Término de Búsqueda de Proyectos',
    lastUpdated: 'Última actualización %{date}',
  },
  filtersModal: {
    userSelect: {
      disabledMessage: 'Deshabilitado para la vista Mis proyectos',
    },
  },
  floatingActionBar: {
    closeButtonAltText: 'botón de cerrar',
  },
  freeLimitsAvailable: {
    contactAdmin:
      'Póngase en contacto con el admin de su empresa para actualizar tu plan.',
    remainingFreeProjects: {
      one: 'Queda %{remainingCount} proyecto gratuito.',
      other: 'Quedan %{remainingCount} proyectos gratuitos.',
    },
    remainingFreeUsers: {
      one: 'Queda %{remainingCount} usuario gratuito.',
      other: 'Quedan %{remainingCount} usuarios gratuitos.',
    },
    upgrade: 'Actualiza',
  },
  galleries: {
    configuration: {
      instructions: 'Incluir, quién, cuando, y donde la foto fue tomada.',
      submit: 'Conseguir enlace a la Galería',
      video: {
        submit: 'Get Link to Video',
      },
    },
    galleryModal: {
      shareGallery: 'Compartir Galería',
    },
    share: {
      video: {
        viewGallery: 'View Video',
      },
      viewGallery: 'Vea Galería',
    },
  },
  groups: {
    index: {
      title: 'Grupos',
    },
  },
  heard: {
    label: '¿Cómo te enteraste de nosotros?',
    detail: {
      manufacturer: '¿Puedes decirnos más?',
      other: '¿Puedes decirnos más?',
      social: '¿En cuál plataforma?',
      someone: '¿Quién te habló? Tenemos un regalo para elle.',
      tradeShow: '¿Cómo se llamaba?',
      which: '¿Cuál?',
    },
    manufacturer: 'Fabricante o grupo industrial',
    podcast: 'Podcast',
    select: 'Seleccione uno...',
    social: 'Medios de comunicación social o búsqueda',
    softwareIntegrationPartner: 'Socio de integración de software',
    someone: 'Alguien me habló',
    tradeShow: 'Feria comercial o evento',
    other: 'Otro',
  },
  helpers: {
    strings: {
      invalidFilename: "El nombre no debe comenzar con un '.'",
      nameEmpty: 'El nombre no puede estar vacío',
    },
  },
  inputClipboard: {
    copy: 'Copiar',
    copyError: 'No se pudo copiar este texto.',
    copySuccess: 'Enlace copiado al portapapeles',
    retry: 'Procesar de nuevo',
  },
  invitationSteps: {
    review: {
      inviteAnother: 'Invitar a otro usuario',
    },
  },
  invitations: {
    review: {
      body1: 'Establece el rol de cada usuario.',
      body2: 'No te preocupes, esto se puede cambiar más tarde.',
      sendInvites: {
        one: 'Enviar %{count} Invitacion',
        other: 'Enviar %{count} Invitaciones',
      },
    },
    stepper: {
      stepOne: 'Agregar Correo Electronico',
      stepThree: '¡Listo!',
      stepTwo: 'Revisar Invitaciones',
    },
    validation: {
      duplicateEmail: '%{email} ya está ocupado.',
      emailInUse: '%{email} no es una dirección válida de correo electrónico.',
      emailPlaceholder:
        'Escribe o pega los correos electrónicos y presione ingresar',
      duplicate: 'El correo electrónico es un duplicado',
      invalid: 'Correo electrónico no es válido',
      taken: 'Correo electrónico ya ocupado',
      unknown:
        'Por favor, inténtalo de nuevo. Si el problema persiste, por favor contacte con soporte.',
    },
    backModal: {
      cancelLabel: 'No, sigue invitando',
      confirmLabel: 'Sí, quiero irme',
      message:
        'Si sales, tu progreso no se guardará y tendrás que empezar de nuevo.',
      title: 'Estás a punto de salir.',
    },
    error: 'Hubo un error al enviar las invitaciones',
    form: {
      emailHelperText: 'Presiona ingresar para agregar',
      firstName: 'Primer nombre',
      inviteNewUser: 'Invitar Usuario',
      inviteUsers: 'Invitar Usuarios',
      lastName: 'Apellido',
      sendInvite: 'Enviar invitación',
      setRolesAndSend: 'Establecer Roles y Enviar ',
      userDetails: 'Detalles del usuario',
    },
    index: {
      resentMessage: 'La invitación ha sido reenviada',
    },
    invitations: {
      manuallyAcceptInvite: 'Manualmente aceptar la invitación',
    },
    new: {
      noticeBillableUsers: 'Los usuarios invitados se agregarán a tu factura.',
      noticeInTrial:
        'Las suscripciones comienzan con 3 asientos, ¡así que llénalos!',
      noticeOpenSeats: {
        one: 'Tienes %{openSeats} asiento abierto. Después de eso, los usuarios invitados se agregarán a tu factura.',
        other:
          'Tienes %{openSeats} asientos abiertos. Después de eso, los usuarios invitados se agregarán a tu factura.',
      },
      itunesOpenSeats: {
        one: 'Tienes %{openSeats} asiento abierto. Después de eso, deberás elegir un plan diferente para continuar agregando usuarios a tu cuenta.',
        other:
          'Tienes %{openSeats} asientos abiertos. Después de eso, deberás elegir un plan diferente para continuar agregando usuarios a tu cuenta.',
      },
      noticeOutOfTrial:
        'Los nuevos usuarios se agregarán a tu factura mensual.',
      reviewInvites: {
        one: 'Revisar %{count} Invitacion',
        other: 'Revisar %{count} Invitaciones',
      },
      reviewPlaceholder: 'Revisar Invitaciones',
    },
    notice: {
      itunesMaxUsers:
        'Elige un plan diferente para continuar agregando usuarios a tu cuenta.',
      seePlans: 'Ver Planes',
    },
    permissions: {
      userPermissions: 'Permisos del Usuario',
    },
  },
  map: {
    mapContainer: {
      dragLabel:
        'Arrastras %{type} para mover o clic a la derecha para eliminar.',
      drawGeofenceAriaLabel: 'Dibujar Geo-fence',
      placePinAriaLabel: 'Poner PIN',
      recenterMapAriaLabel: 'Centrar mapa',
      searchPlaceholder: 'Buscar una dirección',
    },
    filters: {
      noResultsFound: 'No se encontraron {filterType}',
      labelQueryTerm: 'Término de Búsqueda de Etiqueta',
    },
    geolocation: {
      alert: 'La geolocalización no es compatible con tu navegador.',
    },
    locationModal: {
      title: 'Acceso a la Ubicación Denegado',
      strong: {
        title: 'Para habilitar el acceso a la ubicación, sigue estos pasos',
        chrome: 'Chrome:',
        safari: 'Safari:',
        firefox: 'Firefox:',
      },
      chromeInstructions:
        'Ve a Configuración → Privacidad y seguridad → Configuración de sitios → Ubicación → Permitir acceso.',
      safariInstructions:
        'Ve a Preferencias → Sitios web → Ubicación → Permitir acceso.',
      firefoxInstructions:
        'Haz clic en el ícono de candado → Permisos → Habilitar acceso a la ubicación.',
    },
  },
  modal: {
    toast: {
      errorMessage: 'Error al Guardar',
      successMessage: 'Cambios Guardados!',
    },
  },
  navigation: {
    company: 'Empresa',
    createActionsMenu: {
      inviteUser: 'Invitar Usuarios',
      menuTitle: 'Crear',
      newGroup: 'Crear Grupo',
      newProject: 'Crear Proyecto',
    },
    menu: {
      checklistFeed: 'Listas de Tareas',
      groups: 'Grupos',
      map: 'Mapa',
      photos: 'Fotos',
      projects: 'Proyectos',
      reports: 'Reportes',
      showcases: 'Exhibiciones',
      todosMarketplace: 'Listas de Tareas de la Comunidad',
      users: 'Usuarios',
      getFreeStuff: '¡Refiere para obtener premios!',
      referGetPaid: 'Refiere, recibe $$$',
      moreBusiness: 'Gana Más Negocios',
      reviews: 'Reseñas',
      marketing: 'Marketing',
    },
    navLink: {
      templates: 'Plantillas',
      reports: 'Reports',
    },
    resources: 'Recursos',
    searchPlaceholder: 'Buscar',
    support: 'Chatear con Soporte',
    upgrade: 'Actualizar',
    upgradeValueProp: '¡Obtén videos, proyectos ilimitados y más!',
    userMenu: {
      accessTokens: 'Tokens de acceso',
      billing: 'Facturación',
      companySettings: 'Configuración de la empresa',
      companyTitle: 'Tu empresa',
      cookies: 'Gestionar Cookies',
      corporatePortal: 'Portal Corporativo',
      myPhotos: 'Mis fotos',
      mySettings: 'Configuración de mi cuenta',
      organizationDashboard: 'Tablero de la Organización',
      showCompanyDashboard: 'Tablero de Empresa',
      signout: 'Cerrar sesión',
      support: 'Chatear con Soporte',
      supportTitle: 'Apoyo',
      tags: 'Tags',
      todosMarketplace: 'Listas de Tareas de la Comunidad',
      tutorials: 'Centro de Ayuda',
    },
  },
  notificationSettings: {
    link: {
      title: 'Configuración de notificaciones',
    },
  },
  notifications: {
    badge: {
      hide: 'Esconder notificaciones',
      view: 'Ver notificaciones',
      viewAll: 'Ver todo',
    },
    common: {
      markAsRead: 'Marcar como leído',
      markAsUnread: 'Marcar como no leído',
      title: 'Notificaciones',
    },
    emptyState: {
      message:
        'Cuando tengas nuevas actualizaciones de tu equipo, aparecerán aquí.',
      title: 'Aún no hay notificaciones',
    },
    filters: {
      all: 'Todo',
      assignments: 'Asignaciones',
      comments: 'Comentarios',
      mentions: 'Menciones',
    },
    markAllAsRead: 'Marcar todas como leídas',
    markAssignmentsAsRead: 'Marcar asignaciones como leídas',
    markCommentsAsRead: 'Marcar comentarios como leídas',
    markMentionsAsRead: 'Marcar menciones como leídas',
    settings: {
      link: {
        title: 'Configuración',
      },
    },
  },
  onboard: {
    backButton: {
      text: 'Regresar a %{prev}',
    },
    common: {
      installMobileApp: 'Instalar la aplicación móvil',
    },
    demo: {
      createFirstProject: 'Crear el primer proyecto',
    },
    formValidationCompanyNameCharacterLimit:
      'Nombre de empresa debe tener menos de 50 caracteres',
    formValidationCompanyNameRequired: 'Nombre de empresa es requerido',
    formValidationEmailCharacterLimit:
      'El correo electrónico debe tener menos de 50 caracteres',
    formValidationEmailInvalid: 'Ingrese un correo electrónico válido',
    formValidationEmailRequired: 'Correo electrónico es requerido',
    formValidationEmailUnique: 'Email ya en uso',
    formValidationFieldRequired: 'Esta entrada es requerida',
    formValidationFirstNameCharacterLimit:
      'El nombre debe tener menos de 50 caracteres',
    formValidationFirstNameRequired: 'Nombre es requerido',
    formValidationIndustryRequired: 'Industria es requerido',
    formValidationLastNameCharacterLimit:
      'El apellido debe tener menos de 50 caracteres',
    formValidationLastNameRequired: 'Apellido es requerido',
    formValidationPasswordCharTypes:
      'La contraseña debe tener una letra mayúscula y una letra minúscula',
    formValidationPasswordLong:
      'La contraseña debe tener menos de 100 carácteres',
    formValidationPasswordRequired: 'Contraseña es requerido',
    formValidationPasswordShort:
      'La contraseña debe tener por lo menos 10 carácteres',
    formValidationPhoneNumberCharacterLimit:
      'El número de teléfono debe tener menos de 50 caracteres',
    formValidationPhoneNumberInvalid: 'Ingrese un número de teléfono válido',
    formValidationPhoneNumberRequired: 'número de teléfono requerido',
    formValidationRoleRequired: 'Rol es requerido',
    loading: {
      message: 'Preparando tu almacenamiento ilimitado en la nube...',
    },
    myProfile: {
      createProfile: 'Crear Perfil',
      title: 'Tómate un segundo para completar tu perfil.',
      titleCollab:
        'Tómate un segundo para completar tu perfil. Entonces, ¡empieza a trabajar en el proyecto!',
    },
    signUp: {
      button: 'Continuar',
      collabButton: 'Crear Empresa',
      collabTitle: 'Crea la cuenta de tu empresa para aceptar la colaboración.',
      industry: {
        prompt: '¿A qué te dedicas?',
        skip: 'Omitir',
        selectOne: 'Seleccione uno...',
        label: 'Industria',
      },
      mobileButton: 'Crear Cuenta',
      mobileTitle: 'Crea una cuenta para tu empresa.',
      title: '¡Bienvenidos! Crea una cuenta para tu empresa.',
    },
    stepper: {
      step1: 'Crear Una Cuenta De Empresa',
      step2: 'Completa Tu Perfil',
    },
    terms: {
      description:
        'Acepto los <tou>Términos de Uso</tou> y la <privacy> Política de Privacidad.</privacy>.',
    },
    usersForm: {
      addAnotherEmail: 'Add More',
      addTeamLater: {
        '': 'Tal vez mas tarde',
      },
      emailLabel: 'Correo electrónico de miembro del equipo',
      message:
        'Aproveche al máximo CompanyCam agregando miembros de su equipo. Le enviaremos un enlace por email a cada usuario para comenzar.',
      sendInvites: 'Enviar invitaciones',
      title: 'Invitar a un miembro del equipo',
    },
    brandMessage:
      'Únete a más de 39,000 empresas que confían en CompanyCam para la comunicación y responsabilidad.',
    quotePartOne:
      'CompanyCam definitivamente ha ayudado a nuestra comunicación, sin duda.',
    quotePartThree:
      '; se trata de qué tan rápido puedes poner tus ojos en una situación y encontrar una solución.',
    quotePartTwo: 'Todo en este negocio es urgente',
    userCompany: 'Brad Smith Roofing',
    userName: 'Brad Smith',
  },
  organizations: {
    editCompany: {
      backButtonText: 'Volver',
      backToPortal: 'Volver al portal',
      editCompanySuccessMessage:
        'Edición de empresa exitosa. Ahora puede cerrar esta pestaña y volver al Portal Corporativo.',
      loadingAccessibilityLabel: 'Cargando la forma de la empresa',
    },
    userEdit: {
      activateUserSuccessMessage:
        'El usuario ha sido activado. Ahora puede cerrar esta pestaña y volver al Portal Corporativo.',
      deactivateUserSuccessMessage:
        'El usuario ha sido desactivado. Ahora puede cerrar esta pestaña y volver al Portal Corporativo.',
      editUserSuccessMessage:
        'Su información ha sido guardada. Ahora puede cerrar esta pestaña y volver al Portal Corporativo.',
      forceLogoutSuccessMessage:
        'El usuario ha sido desconectado. Ahora puede cerrar esta pestaña y volver al Portal Corporativo.',
      loadingAccessibilityLabel: 'Cargando la forma de usuario',
    },
    company: {
      addCompanySuccessMessage: 'Tu información ha sido guardada.',
      backToPortal: 'Regresar al Portal',
      editCompany: 'Editar Empresa',
    },
    forms: {
      account: {
        form: {
          addressLine1: 'Dirección de cuenta 1',
          addressLine2: 'Dirección de cuenta 2',
          cancelAriaLabel:
            'No se guardará ningún dato en esta página que hayas cambiado.',
          city: 'Ciudad',
          country: 'País',
          createAccount: 'Crear cuenta',
          email: 'Correo electrónico de la cuenta',
          logo: 'Logotipo de la cuenta',
          name: 'Nombre de la cuenta',
          phoneNumber: 'Número de teléfono',
          postalCode: 'Código postal',
          saveAccount: 'Guardar cuenta',
          state: 'Estado',
          timeZone: 'Zona horaria',
        },
      },
      user: {
        form: {
          currentPassword: 'Contraseña actual',
          currentPasswordValidationError:
            'Tu contraseña actual es necesaria para cambiar el correo electrónico o la contraseña de este usuario.',
          emailAddress: 'Córreo electrónico',
          emailAddressValidationError: 'Correo electrónico es requerido',
          firstName: 'Nombre',
          firstNameValidationError: 'Nombre es requerido',
          lastName: 'Apellido',
          lastNameValidationError: 'Apellido es requerido',
          password: 'Contraseña',
          passwordValidationError: 'Se requiere contraseña',
          phoneNumber: 'Número de teléfono',
          phoneNumberValidationError: 'Ingrese un número de teléfono válido',
          userInformation: 'Información del usuario',
          userPermissions: 'Permisos del usuario',
        },
      },
    },
    newCompany: {
      backButtonText: 'Volver',
      newCompanySuccessMsg:
        'Empresa creada con éxito. Ahora puede cerrar esta pestaña y volver al Portal Corporativo.',
    },
    user: {
      addUserPreviewMessage:
        '<p>Hola %{name}, has sido agregado a CompanyCam. Descarga la aplicación aquí: %{downloadLink}</p><p>Nombre de usuario: %{emailAddress}</p>',
      addUserSuccessMessage:
        '<p><b>Tu información ha sido guardada.</b> %{name} ha sido añadido a %{orgName}.</p><p><b>Nombre de usuario: %{emailAddress}.</b></p><p><b>Ahora puedes cerrar esta pestaña y volver al Portal Corporativo.</b></p><p><b>%{br}Descarga la aplicación aquí: %{downloadLink}</b></p>',
      edit: {
        activateButtonLabel: 'Activar',
        deactivateButtonLabel: 'Desactivar',
        forceLogoutButtonLabel: 'Cerrar sesión con fuerza',
        saveButtonLabel: 'Guardar Usuario',
        title: 'Actualizar %{name}',
      },
      form: {
        create: {
          errorMessage:
            'Occurió un problema al enviar el formulario. Por favor de corregir los siguientes errores.',
          saveButtonLabel: 'Guardar Usuario',
        },
        edit: {
          errorMessage:
            'Occurió un problema al enviar el formulario. Por favor de corregir los siguientes errores.',
          foldTitle: 'Cambiar dirección email o contraseña',
        },
        passwordRequiredFold: {
          message:
            'Confirma <strong>tu contraseña actual</strong> (no la contraseña de este usuario) para editar información de la cuenta.',
          resetMessage:
            '¿No sabes tu contraseña? <resetlink>Cerrar sesión y restablecer la contraseña</resetlink>',
        },
      },
      invitationForm: {
        loadingAccessibilityLabel: 'Cargando opciones de invitación',
        sendEmailButtonLabel: 'Enviar Correo Electrónico',
        sendSMSButtonLabel: 'Enviar SMS',
        skipButtonLabel: 'Omitir',
        title: 'Agregar un Usuario',
      },
      list: {
        addUserLink: 'Agregar Usuario',
        deactivatedBadge: 'desactivado',
        header: 'Usuarios',
      },
      new: {
        back: 'Volver',
        saveButtonLabel: 'Agregar Usuario',
        title: 'Agregar un Usuario',
      },
    },
    organizationTableCell: {
      inactiveUsersCountLabel: {
        one: 'Review %{count} usuario inactivo',
        other: 'Review %{count} usuarios inactivos',
      },
      newUsersCountLabel: {
        one: 'Review %{count} usuario agregado',
        other: 'Review %{count} usuarios agregados',
      },
      unacceptedUsersCountLabel: {
        one: 'Review %{count} usuario tiene una invitación pendiente',
        other: 'Review %{count} usuarios tienen invitaciones pendientes',
      },
    },
  },
  pages: {
    toolbar: {
      addImage: 'Agregar Imagen',
      heading1: 'Encabezado 1',
      heading2: 'Encabezado 2',
      heading3: 'Encabezado 3',
      paragraph: 'Párrafo',
      layoutPopoverTitle: 'Insertar',
      layoutsPopoverCoverPage: 'Página de Portada',
      layoutsPopoverCoverPageLabel: 'Página de Portada',
      layoutsPopoverHorizontal: 'Bloque de fotos horizontal',
      layoutsPopoverImageLeftLabel:
        'Diseño horizontal con imagen a la izquierda',
      layoutsPopoverImageRightLabel:
        'Diseño horizontal con imagen a la derecha',
      layoutsPopoverVertical: 'Bloque de fotos verticales',
      layoutsPopoverTwoColumnLabel: 'Diseño vertical con dos columnas',
      layoutsPopoverThreeColumnLabel: 'Diseño vertical con tres columnas',
      textAndImageLayouts: 'Diseños de Texto e Imagen',
      addTable: 'Agregar tabla',
      addRowBefore: 'Agregar fila antes',
      addRowAfter: 'Agregar fila después',
      deleteRow: 'Eliminar fila',
      addColumnBefore: 'Agregar columna antes',
      addColumnAfter: 'Agregar columna después',
      deleteColumn: 'Eliminar columna',
      deleteTable: 'Eliminar tabla',
      paymentRequest: {
        toastSoon:
          'Gracias por tu interés. ¡Esta función estará disponible próximamente!',
      },
      tooltips: {
        bold: 'Negrita',
        italic: 'Cursiva',
        underline: 'Subrayado',
        checkbox: 'Casilla de verificación',
        bulletedList: 'Lista con viñetas',
        numberedList: 'Lista numerada',
        link: 'Enlace',
        addPhotos: 'Agregar Fotos',
        addTable: 'Agregar Tabla',
        paymentRequest: 'Agregar Solicitud de Pago',
        paymentRequestSoon: 'Agregar Solicitud de Pago: ¡Próximamente!',
        insert: 'Insertar',
        undo: 'Deshacer',
        redo: 'Rehacer',
      },
    },
    addCoverPage: 'Añadir Página de Portada',
    altText:
      'Imagen de un conjunto de páginas con un elemento de tarea e imágenes en cada uno.',
    blankPage: 'Página en Blanco',
    collabNotification: 'Sólo %{companyName} podrá ver las páginas.',
    collabNotificationStrong:
      'No se comparten las páginas entre colaboradores.',
    emptyMessage: 'Crea documentos editables con fotos y texto.',
    emptyTitle: 'Tu Nuevo Cuaderno Digital',
    filters: {
      exampleTemplates: 'Plantillas de ejemplo',
      yourCompany: 'Tu Empresa',
    },
    examples: {
      dailyLog: 'Registro Diario',
      estimate: 'Estimar',
      materialsList: 'Lista de Materiales',
      workOrder: 'Pedido de Trabajo',
      invoice: 'Factura',
      invoiceBannerAction: 'Abrir Encuesta',
      invoiceBannerScheduleAction: 'Programar',
      invoiceBannerMessage:
        '¿Tienes comentarios sobre la plantilla de Facturas? Programa una conversación con nuestro equipo de productos o cuéntanos más en una breve encuesta.',
    },
    examplesListTitle: 'Comienza con un Ejemplo',
    useTemplate: 'Utilizar la plantilla',
    linkDialog: {
      link: 'Enlace',
      text: 'Texto',
      removeLink: 'Eliminar Enlace',
    },
  },
  paymentBadgeStatus: {
    status: {
      draftLabel: 'Borrador',
      failedLabel: 'Pago fallido',
      paidLabel: 'Pagado',
      processingLabel: 'Procesado',
      refundPendingLabel: 'Reembolso Pendientes',
      refundedLabel: 'Reembolsado',
      sharedLabel: 'Compartido',
    },
  },
  paymentRequest: {
    copyLink: {
      button: 'Copiar enlace',
      error: 'No se pudo copiar este texto..',
      success: 'Enlace copiado al portapapeles',
    },
    modal: {
      clipboardTitle: 'Compartir Enlace',
      create: {
        title: 'Nuevo Pago',
      },
      createAndShare: 'Crear y compartir',
      invoice: {
        requestToast:
          '¡Gracias por informarnos! Hemos tomado nota de tu interés.',
        requestMessage:
          '¿Te gustaría poder agregar una factura a tu solicitud de pago?',
        requestAction: '¡Avísanos!',
      },
      memo: 'Nota',
      memoPlaceholder: "What's this payment for?",
      requestHigherLimit: 'Solicitar un límite más alto',
      statusHeader: 'Estado',
      titlePlaceholder: 'Pago #%{count}',
      titleRequired: 'Título Requerido.',
      total: 'Total',
      totalPlaceholder: '$0.00',
      totalRequired: 'La cantidad debe ser de al menos $0.50 y menos de',
      update: {
        title: 'Editar Pago',
      },
    },
    useNewPaymentRequest: {
      error: 'No se ha podido crear esta solicitud de pago.',
      success: 'Solicitud de pago creada.',
    },
    useUpdatePaymentRequest: {
      error: 'No se ha podido actualizar esta solicitud de pago.',
      success: 'Solicitud de pago actualizada.',
    },
  },
  paymentRequestList: {
    table: {
      emptyImageAlt:
        'Imagen que muestra un formulario de solicitud de pago y una tarjeta de crédito.',
      emptyMessage:
        'Solicitar y cobrar pagos de clientes, desarrollado por Stripe.',
      emptyTitle: 'Escribe un Número y Recibe tu Pago',
    },
    tableHeader: {
      amount: 'Cantidad',
      status: 'Estado',
      title: 'Título',
    },
  },
  paymentRequestListItem: {
    confirmRefundAction: 'Reembolsar',
    confirmRefundMessage:
      'Usaremos su saldo de Stripe para proporcionar un reembolso completo de %{formattedAmount} al cliente. Tenga en cuenta que no se le reembolsarán las tarifas de procesamiento de la transacción original.',
    confirmRefundTitle: 'Reembolsar',
    deletePaymentRequestError:
      'No se ha podido eliminar esta solicitud de pago.',
    deletePaymentRequestMessage:
      'Se perderá el acceso a esta solicitud de pago. No puede deshacer esta acción.',
    deletePaymentRequestSuccess: 'Solicitud de pago eliminada.',
    deletePaymentRequestTitle: 'Eliminar Solicitud de Pago',
    refundPaymentError: 'No se puede reembolsar este pago.',
    shareButtonTooltip: 'La cantidad debe ser de al menos $0.50 y menos de ',
    toast: {
      refundProcessing: 'Procesando reembolso.',
    },
  },
  paymentRequestOptionsMenu: {
    issueRefundOption: 'Emitir reembolso',
  },
  paymentRequestStatusBadge: {
    status: {
      draftLabel: 'Borrador',
      failedLabel: 'Pago fallido',
      paidLabel: 'Pagado',
      processingLabel: 'Procesado',
      refundPendingLabel: 'Reembolso Pendientes',
      refundedLabel: 'Reembolsado',
      sharedLabel: 'Pendiente',
    },
  },
  payments: {
    header: {
      create: 'Pago',
    },
    buttonLabel: 'Inténtalo de nuevo',
    error: {
      heading: '¡Uy!',
      message: 'Un error ha occurrido',
    },
    setupStripe: {
      askPermission: 'Pídale a un Manager o Admin que configure los pagos.',
      checklist: {
        one: 'Se aceptan ACH, tarjetas de crédito, Google Pay o Apple Pay',
        two: 'Con tecnología de Stripe. Tarifa de 1 % para ACH, tarifa de 3 % para todo lo demás',
        three: 'Disponible solo para planes Elite',
      },
      description: 'Establece un precio, envía un enlace y recibe el pago.',
      finishSetup: 'Finalizar la configuración',
      learnMore: 'Aprende mas',
      requestAccessButton: 'Solicitar acceso',
      setUpStripe: 'Configurar Stripe',
      title: 'Recolecta Pagos',
    },
    valuePropsModal: {
      description: 'Establece un precio, envía un enlace y recibe el pago.',
      title: 'Pagos',
      valueProp1: 'Se aceptan ACH, tarjetas de crédito, Google Pay o Apple Pay',
      valueProp2:
        'Con tecnología de Stripe. Tarifa del 1 % o hasta $10 por cada transacción ACH, tarifa del 3 % para todo lo demás.',
      valueProp3: 'Disponible solo para planes Elite',
    },
    common: {
      convertToDraft: 'Convertir a borrador',
      create: 'Crear',
      paymentRequest: 'Pago',
      payouts: 'Pagos',
      requestPayment: 'Solicitar Pago',
      saveAsDraft: 'Guardar como borrador',
    },
  },
  photoProjectTasks: {
    getErrorMessage: 'Hubo un error al obtener tus tareas.',
    tooltipOnboardingOne:
      '¿Ves algo que hay que hacer? ¡Crea una tarea de proyecto directamente a partir de esta foto!',
    tooltipOnboardingTwo:
      'Las tareas creadas a partir de fotos son agregadas automáticamente a la sección de Tareas en la pantalla del proyecto.',
    videoNotSupportedNotice:
      'Aún no se admite crear tareas a partir de un video.',
  },
  photos: {
    deletePhotosModal: {
      confirmMoveToTrash: 'Sí, Mover a la Basura',
      confirmationMessage: {
        one: 'Review %{photos} foto',
        other: 'Review %{photos} fotos',
      },
      confirmationMessageTrash:
        'Las fotos se eliminarán 90 días después de que las muevas a la Basura.',
      confirmationMessageTrashSecondary:
        'Los usuarios con permisos de Admin o Manager pueden volver a moverlos al proyecto dentro de los 90 días.',
      deletePhotos: 'Eliminar fotos',
      modalTitle: {
        one: 'Review %{photos} Foto',
        other: 'Review %{photos} Fotos',
      },
    },
    downloadModal: {
      completedTitle: '¡La descarga está lista!',
      downloadPhotos: 'Descargar fotos',
      instructions:
        'Entra tu correo electrónico y te notificaremos cuando la descarga esta lista. Tu enlace también aparecerá aquí cuando esté listo si te gustaría esperar.',
      packagingInstructionsEmail:
        'Estamos empaquetando tus fotos y te enviaremos un correo electrónico a %{email}. Tu enlace también aparecerá aquí cuando esté listo si te gustaría esperar.',
      packagingInstructionsNoEmail:
        'Estamos empaquetando tus fotos. Tu enlace aparecerá aquí cuando esté listo.',
      packagingTitle: 'Empacar fotos',
      pendingTitle: 'Descargar fotos',
      videoMessage: {
        withPhotos: {
          one: 'Desafortunadamente, no se pueden incluir los videos seleccionados en tu descarga. Pero la foto que has seleccionado se incluirá.',
          other:
            'Desafortunadamente, no se pueden incluir los videos seleccionados en tu descarga. Pero las fotos que has seleccionado se incluirán.',
        },
        withoutPhotos:
          'Desafortunadamente, hay que descargar videos uno a la vez.',
      },
    },
    printModal: {
      '10perpage': '10 por página',
      '1perpage': '1 por página',
      '2perpage': '2 por página',
      '4perpage': '4 por página',
      instructions: 'Incluir cuándo, dónde, y por quién la foto fue tomada.',
      modalTitlePrintPhotos: 'Imprimir fotos',
      printPhotos: 'Imprimir fotos',
    },
    reassignPhotosModal: {
      instructions:
        'Seleccionar a cuál proyecto te gustaría mover la foto seleccionada',
      movePhotos: 'Mover fotos',
      title: 'Mover fotos',
    },
    singleDownloadModal: {
      modalLabel: 'Modal de descargar fotos',
      downloadInprogress: 'Descargo en progreso',
      downloadPending: 'Su descarga se iniciará en breve',
      downloadPhoto: 'Descargar una foto',
      modified:
        'Parece que esta foto ha sido modificada. ¿Cuál versión te gustaría descargar?',
    },
    singleShareModal: {
      instruction: '¿Cuál versión te gustaría compartir?',
      large: 'Largo',
      medium: 'Mediano',
      sharePhoto: 'Compartir foto',
      small: 'Pequeño',
      viewPhoto: 'Ver Foto',
    },
  },
  public: {
    notFoundPage: {
      message: 'La página que estás intentando acceder no existe.',
      accountLinkMessage: 'cuenta de CompanyCam',
      supportLinkMessage: 'contáctenos',
      cta1: 'Vuelva a su',
      cta2: 'o',
      cta3: 'sobre su problema.',
    },
  },
  primaryUserTitle: {
    fieldTechInstallation: 'Técnico de Campo/Instalación',
    label: 'Rol',
    marketing: 'Marketing',
    officeAdministration: 'Administración de Oficina',
    operations: 'Operaciones',
    other: 'Otro',
    owner: 'Dueño',
    projectManagement: 'Gestión de Proyectos',
    sales: 'Ventas',
    select: 'Seleccione uno...',
  },
  project: {
    contacts: {
      confirmRemoveContact:
        '¿Estás seguro que quieres eliminar este contacto del proyecto?',
      editProjectContact: 'Editar Contacto del Proyecto',
      formAddContactButtonText: 'Agregar Contacto',
      formEmailInvalid: 'El correo electrónico debe ser válido',
      formNameLabel: 'Nombre',
      formNameRequired: 'Se requiere nombre del contacto',
      formNameTooShort: 'El nombre debe tener al menos 2 carácteres',
      formPhoneOnlyNumbers: 'El número de teléfono solo puede tener números',
      formPhoneTooLong:
        'El número de teléfono no puede tener más de 15 números',
      formPhoneTooShort: 'El número de teléfono debe tener al menos 9 números',
      formRemoveContactButtonText: 'Eliminar Contacto',
      projectContact: 'Contacto del Proyecto',
      removeProjectContact: 'Eliminar Contacto del Proyecto',
      yesRemove: 'Sí, Eliminar',
      errorSaving: 'Error al Guardar el Contacto',
    },
    addPhotos: 'Agregar fotos',
    collaboration: {
      endCollaborationMenuItem: 'Terminar colaboración',
    },
    noteEditor: {
      confirmDiscard:
        'Esto descartará cualquier cambio que haya hecho. Está seguro?',
      placeholder: 'Ingresa la descripción del proyecto aquí',
    },
    qrcode: {
      download: 'Descarga el Código QR',
    },
    reviews: {
      customerFlow: {
        copyReviewButton: 'Copiar reseña a Google',
        copyReviewClicked: 'Copiado. Por favor, pégalo en Google.',
        email: 'Correo electrónico (no será visible públicamente)',
        howDidWeDo: '¿Qué tal lo hicimos?',
        improveClosing:
          'Agradecemos tu negocio y el tiempo que dedicaste a brindarnos comentarios honestos.',
        improveClosingTitle: '¡Gracias!',
        improveHeading: 'Dinos cómo podemos mejorar.',
        improveSubHeading:
          'Comparte tu opinión sincera para que nuestro equipo entienda en qué no cumplimos con tus expectativas.',
        improvePlaceholder: 'Comparte tus pensamientos aquí.',
        poweredBy: 'Con la tecnología de',
        pubReviewClosingTitle:
          'Una última cosa... Te agradeceríamos mucho si pudieras copiar tu reseña en Google para nosotros.',
        pubReviewHeading: 'Escríbenos una reseña pública.',
        pubReviewSubHeading:
          'Comparte tu experiencia para que otras personas sepan qué esperar al trabajar con nosotros.',
        pubReviewLabel: 'Escribe una reseña pública',
        pubReviewPlaceholder:
          'Esto ayuda a los nuevos clientes a sentirse seguros al seguir adelante con nosotros.',
        starRatingAccessibilityLabel:
          'Califica tu experiencia de una a cinco estrellas',
        starRatingSummaryAccessibilityLabel:
          '%{starRating} estrellas seleccionadas',
        starRatingTitle: 'En general, ¿cómo nos fue?',
        takesTwoMinutes: 'Solo toma 2 minutos.',
        thanksForTrustingUs: '¡Gracias por confiar en nosotros!',
        yourName: 'Tu nombre (se mostrará en la reseña)',
        downloadingPhotos: 'Tus fotos se están descargando...',
        downloadPhotosError: 'Hubo un error al descargar tus fotos.',
        downloadZipQuestion: '¿Quieres descargar las fotos como archivo zip?',
        photosHelp: '¡Las fotos ayudan mucho con las reseñas!',
        imageSaved: 'Imagen Guardada',
        imagesSaved: 'Imágenes Guardadas',
      },
      downloadSinglePhoto: 'Guardar Foto',
      reviews: 'Reseñas',
      copyReviewLink: 'Copiar Enlace de Reseña',
      preview: 'Vista Previa del Cliente',
      howItWorks: 'Cómo Funciona',
      photosTitle: 'Mejora tu Posicionamiento en Buscadores (SEO)',
      photosMessage:
        '¡Comparte fotos de proyectos para que los clientes las incluyan en sus reseñas!',
      requestReview: 'Solicitar Reseñas',
      profileNotFound: 'Algo salió mal, no se pudo encontrar esta ubicación',
      messageRequired: 'Se requiere mensaje',
      messageLabel: 'Incluye un Mensaje',
      choosePhotosLabel: 'Elige Fotos para Compartir',
      messagePlaceholder: 'Ingresa un mensaje',
      urlCopy: 'Copiar Enlace y Compartir',
      previewHelper: 'Esta es una vista previa de lo que recibirá tu cliente.',
      builderTitle: 'Solicitar una Reseña',
      googleReview: 'Califícanos en Google',
      feedbackCta: 'Califica tu Experiencia',
      feedbackTitle: '¿Cómo fue tu experiencia con %{companyName}?',
      feedbackClose: '¡Gracias por confiar en nosotros!',
      assetSelectorTitle: 'Elegir Fotos',
      assetSelectorHeadline: 'Choose Photos',
      assetSelectorMessage: 'Choose Photos',
      downloadPhotos: 'Descargar fotos',
      betaEndNotice:
        '¡Dos meses gratis! Como usuario temprano de Reseñas, tienes acceso hasta el 7 de junio de 2025.',
      selectLocation: 'Seleccionar una Ubicación',
      upgradeMessage:
        '¿Quieres mejores reseñas? Conecta tu cuenta de Google y haz que sea fácil para tus clientes compartir su experiencia.',
      upgradeFeatureOne:
        'Solicitudes rápidas y sencillas: envía un enlace o deja que los clientes escaneen un código QR en el lugar.',
      upgradeFeatureTwo:
        'Destaca tu mejor trabajo: adjunta fotos para que las reseñas tengan más impacto y mejoren tu posicionamiento en buscadores.',
      reviewProfile: {
        confirmBusinessQuestion: 'Verifique la información de tu empresa.',
        confirmBusinessButton: 'Crear Perfil',
        title: 'Configura tu perfil para comenzar a recopilar reseñas.',
        instructions:
          'Busca tu empresa para sincronizar información desde Google.',
        poweredByGoogle: 'Con tecnología de Google',
        googleLinkSuccess: 'Perfil de Negocio de Google vinculado exitosamente',
        googleLinkError: 'Error al vincular el Perfil de Negocio de Google',
        googleConfirmTitle: 'Confirme tu listado de Google Maps',
        googleCheckboxLabel:
          'Abrí Google Maps para confirmar que mi listado es correcto',
        openGoogleMaps: 'Ver en Google',
        nickNameLabel: 'Apodo (Solo para uso interno)',
        nickNamePlaceholder: 'Ingresa un apodo',
      },
      reviewsHub: {
        noReviewsTitle: 'Agrega Reseñas a las Exhibiciones',
        noReviewsMessage:
          'Las reseñas de Trusty te ayudarán a conectar tu trabajo publicado con las reseñas de tus clientes, lo que te permitirá crear el portafolio más atractivo de tu trabajo.',
        noGoogleReviewsTitle: 'Obtén Más Reseñas',
        noReviewsGoogleMessage:
          'Aún no hay reseñas en Google. Comparte el enlace de reseña de tu proyecto para obtener más reseñas.',
        project: 'Proyecto',
        customerEmail: 'Cliente',
        rating: 'Calificación',
        feedback: 'Comentario',
        status: 'Estado',
        viewGoogleReview: 'Ver Todo en Google',
        googleReviewInfo: 'Mostrando las 5 reseñas más recientes',
        averageRating: 'Promedio de calificación',
        TotalReviews: 'Reseñas totales',
        connectGoogleTitle: 'Conéctate con Google',
        connectGoogleMessage:
          '¡Conecta el perfil de tu empresa y empieza a recopilar reseñas en el lugar de trabajo hoy mismo!',
        connectGoogleValueProp1: 'Consigue más reseñas, más rápido',
        connectGoogleValueProp2:
          'Adjunte fotos para que los clientes las incluyan',
        connectGoogleValueProp3: 'Aumenta tu visibilidad online',
        connectGoogleBusiness: 'Conectar Google Business',
        addLocation: 'Agrega una Ubicación',
        manageLocations: 'Administrar Ubicaciones',
        findReviewProject: 'Buscar proyectos listos para reseña...',
        noProjectsFound: 'No se Encontraron Proyectos',
        errorLoadingProjects:
          'Lo sentimos, se produjo un error al obtener tus proyectos',
        createShowcase: 'Crear Exhibición',
        selectShowcaseProject: 'Buscar proyectos listos para exhibir...',
      },
      reviewsEdit: {
        manageLocationsTitle: 'Administrar ubicaciones de Google Business',
        returnToReviews: 'Volver a reseñas',
        noLocations: 'Sin ubicaciones',
        noLocationsMessage: 'Agrega ubicaciones para gestionar tus reseñas',
        editNickname: 'Editar apodo',
        removeLocation: 'Eliminar ubicación',
        nicknameUpdatedSuccess: 'El nombre se ha actualizado correctamente',
        nicknameUpdatedError: 'Error al actualizar el nombre',
        deletePublicProfileMessage:
          '¿Estás seguro de que quieres eliminarlo %{locationName}?',
        deletePublicProfileHighlightOne:
          'Ya no podrás solicitar reseñas para esta ubicación.',
        deletePublicProfileHighlightTwo: {
          one: '%{count} enlace de solicitud de reseña enviado ya no será válido.',
          other:
            '%{count} enlaces de solicitud de reseña enviado ya no será válido.',
        },
        publicProfileDeletedSucessfully:
          'La ubicación se ha eliminado correctamente',
        publicProfileDeletedError: 'Error al eliminar la ubicación',
        profileNotFound: 'Algo salió mal, no se pudo encontrar esta ubicación',
        dropdownArrowAccessibilityLabel: 'Flecha desplegable',
      },
    },
    taskList: {
      addTask: 'Agregar Campo',
      assign: {
        errorMessage:
          '¡Ha habido un problema al buscar los usuarios asignados!',
      },
      emptyTaskMsg: 'Agregue algunos campos para comenzar.',
      emptyTaskRestrictedMsg: 'Aún no se han agregado campo',
      error: 'Algo ha ido mal al cargar la lista de tareas.',
      newSection: 'Nueva sección',
      newSectionTip:
        'Si desea organizar sus campos en secciones, puede comenzar haciendo clic en el botón Nueva Sección a continuación.',
      subTasks: {
        deleteMessage: '¿Está seguro de que quiere eliminar esta pregunta?',
        deleteTitle: 'Eliminar Pregunta',
        dragIconTitle: 'Icono para arrastrar y ordenar preguntas',
        label: {
          openTextAnswerInputLabel: 'Entrada de respuesta de texto',
          openTextAnswerInputPlaceholder: 'Añade respuesta…',
          placeholder: 'Descripción (opcional)',
          selectAll: '(seleccione todos los que correspondan)',
        },
        multiSelect: {
          multiAnswer: 'Respuesta múltiple',
          placeholder: 'Agregar una opción',
          sngleAnswer: 'Respuesta única',
        },
        new: 'Pregunta',
        taskType: {
          multiSelect: 'Opción múltiple',
          ratingScale: 'Valoración',
          textQuestion: 'Texto',
          yesNo: 'Sí/No',
        },
        update: 'Hubo un error al editar esta pregunta.',
        updateAnswerChoice:
          'Hubo un error al seleccionar la respuesta de la pregunta.',
        updatePosition: {
          error: 'Hubo un error al reorganizar las preguntas.',
        },
      },
    },
    taskListTemplate: {
      error: 'Algo salió mal al cargar su plantilla de lista de tareas.',
    },
    tasklist: {
      buildingHeader: 'Creando tu lista de tareas...',
      buildingMessage:
        'Esto estará listo en uno o dos minutos. Puede salir de esta pantalla y volver a comprobarlo más tarde.',
      hideCompletedTasks: 'Ocultar Completado',
      onboardPermissionsMessage:
        'Los usuarios con permisos Standard y Restricted ya no pueden editar Listas de Tareas creadas por otro usuario. Los usuarios con estos permisos aún pueden ver, completar y crear sus propias Listas de Tareas.\n\n Del mismo modo, los Colaboradores ya no podrán editar Listas de Tareas, pero seguirán pudiendo ver, completar y crear sus propias Listas de Tareas.',
      onboardPermissionsTitle:
        'Cambios en los permisos de usuario dentro de las Listas de Tareas',
      onboardSkipSectionsMessage:
        'En la aplicación móvil, los usuarios ahora pueden marcar secciones en la Lista de Tareas como N/A si no son relevantes al trabajo actual. \n\n Para usar este ajuste, ve al editor de Plantillas de Lista de Tareas y desactiva las secciones requeridas.',
      onboardSkipSectionsTitle: 'Marque las Secciones como No Aplicable',
      restrictedPermissionNotice:
        'La edición de esta Lista de Tareas está restringida. Comuníquese con tu Admin para realizar cambios.',
      toggle: {
        accessibilityLabel: 'Ocultar Completado',
      },
    },
    tasklists: {
      assign: {
        searchErrorMessage: 'Error cargando usuarios',
        searchInProgressMessage: 'Encontrando a tu gente...',
        searchInputPlaceholder: 'Buscar Usuarios',
        searchNoResultsMessage: 'No se encontraron usuarios.',
      },
      collaboratorNotice: {
        collaboratorPlanNotice:
          'Los Colaboradores deben tener un plan pagado de CompanyCam para poder interactuar con las Listas de Tareas.',
        collaboratorsHaveAccess: 'pueden colaborar en las Listas de Tareas',
        collaboratorsShouldUpdate:
          'Ahora puedes compartir las Listas de Tareas con los colaboradores.',
      },
    },
    trashCan: {
      changePreviewSize: 'Cambiar el tamaño de vista previa de la foto',
      daysToPurgeCount: '%{count} Dias',
      documentsComingSoon:
        'Estamos trabajando para que sus documentos entren y salgan del basurero.',
      emptyHeading: 'Nada que ver aqui...',
      emptyMessage: 'Actualmente no hay fotos en el basurero.',
      errorMessage:
        'Hubo un error al cargar el Basurero. ¡Probablemente nuestra culpa!',
      introMessage:
        'Los elementos se eliminarán 90 días después de que se hayan movido a la basura del proyecto.',
      moveBackToProject: 'Devolver imágenes/videos al proyecto',
      purgeToday: 'Hoy',
      reportsComingSoon:
        'Estamos trabajando para que sus informes entren y salgan del basurero.',
      restoreAssetsLoading: 'Moviendo fotos de vuelta al proyecto...',
      restoreFailureMessage: 'Hubo un error. No se han devuelto tus fotos',
      restoreSuccessMessage: {
        one: 'Review %{count} Foto',
        other: 'Review %{count} Fotos',
      },
      screenReaderPurgeToday: 'Este elemento se eliminará hoy.',
      screenreaderPurgeCount: 'Este artículo será eliminado en %{count} días',
      selectAll: 'Seleccionar todos',
      title: 'Basurero del Proyecto',
      videoIconTitle: 'Este es un video',
    },
  },
  projectMap: {
    expandProjectButton: {
      viewProject: 'Ver proyecto',
    },
    filterBar: {
      dateRange: 'Intervalo de fechas',
      infoSection: {
        count: 'Ver %{currentResultCount} de %{totalResultCount}',
        refiningResults: 'Refinar resultados…',
        zoomToRefineResults: 'Zoom para refinar los resultados',
      },
      usersAndGroups: 'Usuarios y grupos',
    },
    filterbar: {
      dateSelector: {
        clear: 'Despejar',
        filterByDate: 'Filtrar por fechas',
        from: 'de',
        labelCustom: 'a medida',
        labelLast30Days: 'Ultimos 30 días',
        labelThisMonth: 'Este mes',
        labelThisWeek: 'Esta semana',
        labelThisYear: 'Este año',
        labelToday: 'Hoy',
        to: 'Para',
      },
    },
    projectMap: {
      pageTitle: 'Mapa del proyecto',
      clearSearchLabel: 'Borrar resultados de búsqueda',
    },
    projectMarker: {
      lastImageTaken: {
        lastImageTaken: 'La última foto %{date}',
        today: 'Hoy',
        yesterday: 'Ayer',
      },
      projectMarker: {
        details: 'Este proyecto no tiene ninguna foto',
      },
    },
    sidebar: {
      loadingAccessibilityLabel: 'Cargando proyectos.',
      errorHeading: 'Algo Salió Mal',
      errorMessage: 'Esta página no se cargó correctamente. Intenta refrescar.',
      emptyStateHeading: 'No Se Encontraron Resultados',
      emptyStateMessage: 'Intenta buscar un proyecto diferente.',
      projectCountPlural: '%{projectCount} resultados',
      projectCountSingular: '%{projectCount} resultado',
    },
  },
  projectPage: {
    pages: {
      createPage: 'Crear Página',
      watchVideo: 'Ver demostración de 1 minuto',
    },
    projectPageList: {
      emptyPreview: 'Esta nota está vacía',
      emptyTitle: 'Sin título',
      column: {
        title: 'Titulo de página',
        dateCreated: 'Fecha de creación',
        latestActivity: 'Última actividad',
      },
    },
    usePageTemplateModal: {
      title: 'Elegir Plantilla de Página',
      emptyStateImageAlt:
        'Una imagen de las páginas que no se pueden encontrar',
      emptyStateMessage:
        'Crea una página y guardala como plantilla para encontrarla aquí.',
      exampleTemplates: 'Plantillas de ejemplo',
      onboardTooltipMessage:
        ' Guarda esta Página como una plantilla para rápido acceso y reutilizarla fácilmente en proyectos futuros.',
    },
    assetPicker: {
      title: 'Seleccionar Fotos para Agregar',
      addPhotos: 'Agregar Fotos',
      addPhoto: 'Agregar Foto',
    },
    photoPicker: {
      title: 'Seleccionar Foto',
      addPhotos: 'Agregar Fotos',
      addPhoto: 'Agregar Foto',
    },
    changesSaved: {
      edited: 'Editado',
      success: 'Cambios Guardados',
    },
    closeTranscript: 'Cerrar Transcripción',
    deleteModal: {
      body: '¿Estás seguro de que quieres eliminar esta nota? No podrás deshacer esta acción.',
      title: 'Eliminar Nota',
    },
    descriptionLabel: 'Descripción (Opcional)',
    hooks: {
      deleteProjectPageSuccess: '¡Eliminado!',
      shareProjectPageError:
        'Hubo un error al compartir la página de notas del proyecto.',
    },
    saveAsTemplate: 'Guardar como Nueva Plantilla',
    saveAsTemplateModalTitle: 'Guardar Página como Plantilla',
    templatePaywallModalTitle: 'Plantilla de Página',
    templatePaywallMessage:
      'Ahorra tiempo y mantente constante - ¡convierte cualquier Página en una plantilla!',
    templatePaywallModalChecklist: {
      one: 'Crea flujos de trabajo estandarizados',
      two: 'Reduce el trabajo repetitivo',
      three: 'Personaliza y modifica con facilidad',
    },
    saveAsTemplateNoticeMessage:
      'Las fotos de esta página se incluirán en la plantilla guardada.',
    shareAction: {
      sharePage: 'Compartir Página',
      sharePageDescription: 'Cualquiera con este enlace puede ver la página',
      viewPage: 'Ver Página',
    },
    toast: {
      saveAsTemplateSuccess: 'Plantilla guardada',
      saveAsTemplateAction: 'Ver plantillas',
    },
    getPageError: 'Algo salió mal al cargar su Página',
    backToProject: 'Volver al Proyecto',
    transcript: {
      alertCopied: 'Copiado al Portapapeles',
      copyTranscript: 'Copiar Transcripción',
      title: 'Transcripción',
    },
    viewTranscript: 'Ver transcripción',
  },
  projectTask: {
    assignUserErroMsg:
      'Se ha producido un error al asignar un usuario a la tarea',
    deleteModal: {
      confirm:
        '¿Está seguro de que quiere eliminar esta tarea? No puede revertir esta acción.',
      title: 'Eliminar Tarea',
    },
  },
  projectTasks: {
    createTaskErrorMsg: 'Hubo un error al crear una tarea.',
    deleteTaskErrorMsg: 'Hubo un error al eliminar una tarea',
    deleteTaskSuccessMsg: 'Tarea borrada',
    editTaskErrorMsg: 'Hubo un error al editar una tarea',
    emptyStateMessage:
      'Agrega tareas para ayudar a mantener tu proyecto en marcha.',
    getErrorMessage: 'Hubo un error al obtener tus tareas.',
    hideCompletedButton: 'Ocultar Completadas',
    newTaskButton: 'Nueva Tarea',
    showCompletedButton: 'Mostrar Completadas',
    updateTaskErrorMsg: 'Hubo un error al actualizar la tarea.',
    updateTaskPositionErrorMsg: 'Se produjo un error al reorganizar una tarea',
    updateTaskStatusErrorMsg:
      'Hubo un error al actualizar el estado de la tarea.',
  },
  projectView: {
    projectView: {
      noPhotos: 'Este proyecto no tiene ninguna foto',
    },
  },
  projects: {
    projectListItem: {
      noPhotos: 'Aún no se han agregado fotos a este proyecto.',
    },
    projectListItemBadgeRow: {
      archivedLabel: 'Archivado',
      collabEndedNotice: 'colaboración finalizada',
      collabOwnerNotice: 'Tu empresa es propietaria de esta colaboración',
      collabProjectNotice: 'Este proyecto es parte de una colaboración',
    },
    taskList: {
      templates: {
        preview: 'Vista Previa de la Plantilla',
        templateDetailsInput: {
          descriptionLabel: 'Descripción de la plantilla',
          titleLabel: 'Título de plantilla',
        },
        all: 'All Templates',
        backToTemplates: 'Volver a las Plantillas',
        createToDo: 'Crea lista de tareas',
        defaultFilter: {
          label: 'Todas las Plantillas',
        },
        delete: 'Eliminar plantilla',
        deleteConfirm: '¿Estás seguro que quieres eliminar esta plantilla?',
        edit: 'Editar Plantilla',
        empty: 'No se encontró ninguna plantilla',
        emptyMessage:
          'Crea una lista de tareas en blanco y guardala como plantilla para encontrarla aquí.',
        error: 'Hubo un error al obtener los plantillas.',
        list: {
          heading: 'Librería de las Plantillas',
        },
        recent: 'Recently Used Templates',
        success: {
          delete: '¡Se eliminó la plantilla!',
          use: 'La lista de tareas se creó exitosamente',
        },
        templateHeader: {
          reorderTasks: 'Reordenar Campos',
          taskTemplatesCount: '%{count} Campos',
          templateDescriptionError:
            'Algo salió mal al actualizar los detalles de la plantilla.',
          templateDescriptionPlaceholder:
            'Agregue una descripción de plantilla',
          templateDisclaimer:
            'Actualmente estás editando una plantilla. Los cambios que realices no afectarán a las listas de tareas creadas anteriormente.',
          templateTitlePlaceholder: 'Escribir título de la plantilla',
        },
        useTemplate: 'Utilizar la plantilla',
      },
      other: 'Otro',
      qA: 'Control de Calidad',
      taskListFormModal: {
        buttonLoadingLabel: 'Creando Plantilla…',
        templateCreate: 'Crear Nueva Plantilla',
        templateCreateFromToDo: 'Guardar Lista de Tareas como Plantilla',
        templateCreated: 'Plantilla Creada',
        templateCreatedError: 'Ocurrió un error al crear la plantilla',
        templateDescriptionLabel: 'Descripción de la plantilla',
        templateDescriptionPlaceholder: 'Agregue una descripción de plantilla',
      },
      addNewTaskListError: 'Ocurrió un error al agregar esta Lista de Tareas',
      addPhotosToTask: 'Agregar fotos',
      addReferencePhoto: 'Descargue Imágenes De Referencia',
      addTaskButton: {
        newTask: 'Nuevo Campo',
      },
      addTaskListButtonText: 'Agregar Nueva Lista de Tareas',
      addTaskListModalTitle: 'Nueva Lista de Tareas',
      addTitle: 'Agregar Lista de Tareas',
      assign: {
        assignUserEmptyStateMessage:
          'La asignación de listas de verificación puede aumentar la tasa de finalización en un 17%.',
        assignUsersButton: 'Asignar',
        createTaskListAssignment: {
          errorMessage:
            'Hubo un error al asignar un usuario. Vuelve a cargar la página y inténtalo de nuevo.',
          successMessage: 'Usuario asignado con éxito.',
        },
        removeTaskListAssignment: {
          errorMessage:
            'Hubo un error al eliminar la asignación del usuario. Vuelve a cargar la página y inténtalo de nuevo',
          successMessage: 'Asignación eliminada con éxito.',
        },
        restrictedUserConfirmationModal: {
          confirmButton: 'Sí, Asignar',
          message:
            'La asignación de este usuario a una lista de tareas le da acceso al proyecto. Podrá ver Fotos, agregar Fotos, unirse a Conversaciones y acceder a Informes y Listas de Tareas.',
          subtitle: '%{name} es un usuario Restringido',
          title: '¿Asignar Usuario Restringido?',
        },
        unassignButton: 'Desasignar',
      },
      changesSaved: {
        lastUpdated: 'última actualización',
        loading: 'Guardando',
        success: 'Cambios Guardados',
      },
      completedProgress: '%{tasksCompleted}/%{totalTasks} Completado',
      create: 'Crear Lista de Tareas',
      customerHappiness: 'Satisfacción del Cliente',
      deleteBody:
        '¿Está seguro que desea eliminar esta Lista de Quehaceres? No puede revertir esta acción.',
      deleteError: 'Ocurrió un error al borrar la Lista de Tareas.',
      deleteTitle: 'Eliminar Lista de Quehaceres',
      editTaskListError: 'Ocurrió un error al editar esta Lista de Tareas',
      editTitle: 'Editar Lista de Quehaceres',
      exportPdf: {
        completeDescription:
          'Le enviaremos una notificación cuando esté listo para usar. Esto no debería tomar más de uno o dos minutos.',
        completeMessage:
          'Hemos recibido tu solicitud y actualmente estamos construyendo tu Lista de Tareas en PDF.',
        description:
          'Esta exportación de PDF será una captura de esta lista en el estado actual.',
        errorDescription:
          'Se produjo un error al recuperar el PDF de la lista de tareas.',
        errorMessage: 'Error',
        exportBtn: 'Exportar',
        includePhotoDescriptionToggle: 'Incluir Descripción de la Foto',
        includePhotosToggle: 'Incluir Fotos',
        includeSkippedSectionsToggle:
          'Incluir Secciones Marcadas N/A en la Exportación',
        photosPerRowToggle: 'Fotos por fila',
        saveToProjectDocumentsToggle: 'Guardar en documentos del proyecto',
        savedDescription:
          'También se ha guardado una copia en los documentos del proyecto.',
        successDescription: 'Ya exportamos tu PDF',
        successMessage: 'Éxito!',
        viewPdf: 'View PDF',
      },
      initialInspection: 'Inspección Inicial',
      maintenanceOrder: 'Orden de Mantenimiento',
      materials: 'Materiales',
      noTaskLists: 'Este proyecto ya no tiene ninguno campo.',
      referenceModal: {
        addReferencePhoto: 'Agregar Imágenes De Referencia',
        addReferencePhotoDisabled:
          'Se permite un máximo de 5 imágenes de referencia',
        comingSoon: 'Próximamente',
        confirmDelete:
          '¿Seguro que quieres eliminar esta imagen de referencia?',
        maxReferencePhotoUploadCount: 'Cargas máximas: %{count}',
        referencePhotoAddProjectPhotos: 'Agregar fotos desde un proyecto',
        referencePhotoModalSubtitle:
          'Las imágenes de referencia ayudan a tu equipo para saber cómo debería de verse una foto ideal para completar esto campo apropiadamente.',
        referencePhotoModalTitle: 'Imágenes De Referencia',
      },
      referencePhotoUploadModal: {
        error: 'Hubo un error al subir %{fileName}.',
        success: {
          one: 'Review %{assetCount} imagen de referencia',
          other: 'Review %{assetCount} imágenes de referencia',
        },
      },
      removePhotoFromTask: 'Remover del campo',
      removeReferenceAsset: 'Eliminar imagen de referencia',
      reorderSectionsContainer: {
        heading: 'Algo salió mal al reordenar tus secciones.',
      },
      reorderTasks: {
        error: 'Algo salió mal al reordenar tus campos.',
      },
      reorderTasksContainer: {
        heading: 'Arrastrar y soltar para reordenar los campos',
      },
      safety: 'Seguridad',
      saveTemplateError: 'Hubo un error al guardar de la plantilla',
      saveTemplateSuccess: 'Guardado',
      siteSurvey: 'Relevación de Sitio',
      sortableSection: 'Reordenar Secciones',
      sortableTasks: {
        newTask: 'Nuevo Campo',
        taskMoveError: 'Ocurrió un error al mover la tarea a esta sección',
      },
      subTasks: {
        createSubtask: {
          error: 'Ocurrió un error al crear la pregunta',
        },
        deleteError: 'Ocurrió un error al eliminar esta pregunta',
        multiSelect: {
          answerChoiceAlert:
            'Realización de cambios: cambiar las opciones borrará las selecciones de preguntas y la finalización de tareas.',
          comingSoon: 'Próximamente',
        },
      },
      task: {
        taskCompleteUndo: 'Deshacer',
        taskCompleted: 'Campo completado',
        taskCreateError: 'Ocurrió un error al crear este campo',
        taskDelete: 'Eliminar Campo',
        taskPhotoRequired: 'Fotos Requeridas',
        taskStatusUpdateError: 'Error al actualizar el estado de la campo',
        taskUpdateError: 'Error al actualizar el campo',
      },
      taskAssetFooter: {
        uploadPhotos: 'Subir Fotos',
      },
      taskAssetsModal: {
        taskAddProjectPhotos: 'Agregar fotos desde un proyecto',
      },
      taskDeleteModal: {
        deleteError: 'Hubo un error al borrar este campo.',
        deleteTaskBody:
          '¿Está seguro de que quiere eliminar este campo? No puede revertir esta acción.',
        deleteTaskTitle: 'Eliminar Campo',
      },
      taskImageDeleteModal: {
        taskImageDeleteBody:
          'Está seguro de que desea remover esta foto de esto campo? \n Esto no borrará completamente la foto de su proyecto.',
        taskImageDeleteCancel: 'No, No Remover.',
        taskImageDeleteConfirm: 'Si, Remover',
        taskImageDeleteError: 'Hubo un error al borrar esta foto',
        taskImageDeleteTitle: 'Eliminar foto del campo',
      },
      taskInputs: {
        addNotes: 'Más notas/instrucciones',
        untitledTask: 'Campo sin título',
      },
      taskListAssigneesColumnHeader: 'Cesionarios',
      taskListAssigneesTooltip: 'Personas asignadas a la lista de Tareas',
      taskListCategoriesInstructions:
        "Tell us a little more about how you'll use this To-Do List (optional)",
      taskListDateColumnHeader: 'Fecha',
      taskListFormLabel: 'Título de Lista de Quehaceres',
      taskListHeader: {
        backToTaskLists: 'Volver al Proyecto',
        deleteTaskList: 'Delete List',
        manageTaskList: 'Administrar %{taskListName}',
        editTaskList: 'Rename List',
        exportToPdf: 'Export to PDF',
        reorderTasks: 'Reorder Fields',
        saveAsTemplate: 'Save List as Template',
        tasksCompleted: 'campos completados',
        tooltipMsg:
          'Guarda como plantilla para rápidamente crear listas de tareas futuras',
        tooltipUpgradeMsg:
          'Actualiza para guardar como plantilla y crear rápidamente listas de tareas futuros',
      },
      taskListNameInputPlaceholder: 'Nombra la lista...',
      taskListProgressColumnHeader: 'Progreso',
      taskListSection: {
        applyTagsToImagesWithinSection: 'Etiquetar fotos de la sección',
        deleteSection: 'Eliminar seccion',
        deleteSectionError: 'Ocurrió un error al eliminar esta sección.',
        newSection: 'Nueva sección',
        reorderSections: 'Reordenar Secciones',
        sectionCreateError: 'Ocurrió un error al crear esta sección',
        sectionDeleteError: 'Un error ocurrió actualizando esta sección',
        sectionEditError: 'Ocurrió un error al editar esta sección',
        sectionSkipError:
          'Ha ocurrido un error al alternar la Sección Requerida',
        skipSectionLabel: 'Marcado N/A',
        skipSectionTooltip: 'Marcado como No Aplicable por %{user}, %{date}',
        templateSectionTooltip:
          'Desactiva para permitir que usuarios en móvil marquen esta sección como No Aplicable.',
        toggleSkipSectionLabel: 'Sección Requerida',
        updateSectionError: 'Ocurrió un error al actualizar esta sección.',
      },
      taskListSectionDeleteModal: {
        sectionDeleteBody1:
          '¿Está seguro que desea eliminar esta sección? No puede revertir esta acción.',
        sectionDeleteBody2:
          'Esto también eliminará todos los campos dentro de esta sección.',
        sectionDeleteTitle: 'Eliminar Sección',
      },
      taskListTitleColumnHeader: 'Lista de Tareas',
      taskListViewOthersPhotosPermission:
        'No tiene permiso para acceder a esta Lista de Tareas. Por favor contacte a su administrador.',
      taskPhotoUploadModal: {
        addPhotoError: 'Hubo un error al agregar la foto',
        addPhotosSuccess: 'Fotos agregadas',
        delete: {
          error: 'Hubo un error al eliminar esta imagen de referencia.',
        },
        photosFinished:
          'Sus fotos se han terminado de subir y se estan procesando. Aparecerán en breve en su proyecto y campo.  \n    Para continuar agregando fotos, haga click aquí o arrastre y suelte fotos adicionales.',
        uploadPhoto: 'Subir Fotos',
      },
      template: {
        beingEditedStatus: 'Actualización en proceso',
      },
      templateBodyContent: {
        createSectionError: 'Ocurrió un error al crear esta sección.',
        newSection: 'Nueva sección',
        referencePhotoHeading: 'Fotos de referencia',
      },
      templateHeader: {
        breadcrumb: 'Volver al Proyecto',
      },
      useExistingTemplate: 'Utilizar la plantilla existente',
      viewReferenceImages: 'Ver Imágenes De Referencia',
      workOrder: 'Orden de Trabajo',
    },
    activeFilters: {
      clearAllAriaLabel: 'Borrar Selección',
    },
    banner: {
      close: 'Cerrar promoción',
      premiumTrial:
        'Obtenga acceso GRATUITO a las funciones Premium durante 14 días',
      seeDetails: 'Ver Detalles',
    },
    collaboration: {
      collaborationPrivilegeToggles: {
        addContent: {
          description: 'El colaborador podrá agregar fotos al proyecto',
          header: 'Agregar fotos',
        },
        comment: {
          description:
            'El colaborador podrá comentar y ver comentarios en el proyecto',
          header: 'Comentar',
        },
        viewContent: {
          description:
            'El colaborador podrá ver y compartir todas las fotos del proyecto',
          header: 'Ver fotos',
        },
      },
      collaboratorCard: {
        addedBy: 'Agregado por %{creatorName} • %{creationDate}',
      },
      collaboratorCardsList: {
        activeInviteHead:
          'No collaboration invitations have been accepted yet.',
        activeInviteSubhead:
          "When your invitations are accepted, you'll receive a notification and see the collaborator listed on this page.",
        emptyCta: 'Invite a company to collaborate',
        emptyHead:
          'Working with a subcontractor or service partner on this project? %{strong}',
        emptySubhead:
          'Collaborations are unlimited with your current plan. You decide what each company can see and share, and you can stop the collaboration at any time.',
        errorCta: 'Reload this page',
        errorHead: 'Shoot. We had trouble loading your collaborators.',
        errorSubhead:
          'Please check your internet connection, and try reloading this page.',
        noCollaboratorsNoPerms: 'This project has no collaborators.',
      },
      createInvitationModal: {
        alText: 'Imagen de dos personas chocando los cinco',
        defaultTitle: 'Invitar a una empresa a colaborar',
        finePrint:
          'Esta invitación caduca después de 7 días y solo se puede usar para invitar a una empresa.',
        linkCopiedToClipboard: 'Enlace copiado al portapapeles',
        linkCopiedToEmail: 'Enlace copiado a un mensaje de email',
        permissionNotice:
          'Los colaboradores podrán agregar fotos, ver y compartir todas las fotos y ver y hacer comentarios en este proyecto.',
        sunsetCollabNotice:
          'Para brindarle colaboración en la lista de verificación y documentos, eliminaremos las tres configuraciones de permisos el 3 de febrero de 2023.',
      },
      emptyErrorCollaboratorList: {
        altText: 'Ilustración que muestra dos trabajadores colaborando',
      },
      endCollaborationConfirmModal: {
        cancel: 'Cancelar',
        confirm: 'Sí, finalizar la colaboración',
        endingMessage: 'Finalizando...',
        modalCollaboratorWarning:
          'no podrás ver ninguna actualización en curso de este proyecto a menos que te vuelvan a invitar.',
        modalInstructions: 'Si terminas la colaboración con',
        modalTitle: 'Estás seguro?',
        modalWarning:
          'no podrán ver ninguna actualización en curso de este proyecto a menos que los vuelvas a invitar',
      },
      index: {
        searchInputPlaceholder: 'Buscar una colaboración...',
        title: 'Colaboradores',
        tryAgain: 'Intentar de nuevo',
        viewYourProjects: 'Ver tus proyectos',
        invitationBtnLabel: 'Invitación a colaborar con otras empresas',
        noAccess:
          '¡Lo siento! Parece que no tienes acceso a esta funcionalidad.',
        errorMessage: '¡Ups! Hubo un problema al cargar el proyecto.',
        projectInactive: '¡Uy! Este proyecto no está activo.',
        inviteCta: 'Invitar a una empresa a colaborar',
        cantFindProject: 'Rayos. No podemos encontrar este proyecto.',
        backToProjects: 'Volver al Proyectos',
      },
      invitationLink: {
        creating: 'Creando enlace de invitación…',
        noPrivilegesActive:
          'Aprueba al menos un privilegio por encima para crear el enlace.',
      },
      invitationLinkShareButtons: {
        copyToClipboard: 'Copiar al portapapeles',
        emailLine1:
          'Ha sido invitado a colaborar con %{projectOwningCompany} en el proyecto %{projectName} usando CompanyCam.',
        emailLine2:
          'Esta invitación vence en **7 días**. Acepte la invitación aquí: %{linkUrl}',
        emailLine3: '¡Diviértete colaborando!',
        emailSubject: 'Colaborar con %{projectOwningCompany} en %{projectName}',
        sendViaEmail: 'Enviar por correo electrónico',
        settingPrivs: 'Configurando privilegios de la invitación...',
      },
      invitationLinkText: {
        accessiblityLabel: 'Enlace de invitación de colaboración',
        error: '¡Uy! Hubo un error al cargar el enlace.',
      },
      invitationSharedSuccess: {
        emailed: 'enviado',
        inviteAnother: '¿Quieres invitar a una otra empresa?',
        nextSteps:
          'Una ves que hayas %{action} el enlace, puedes cerrar esta ventana. Recibirás una notificación desde CompanyCam cuando se acepta la invitación.',
        shared: 'compartido',
      },
      menu: {
        endCollaboration: 'Finalizar colaboración',
        reInvite: 'Reinvitar a la empresa',
      },
      privilegeToggle: {
        addPhotoPermissionLabel: 'Permisión de agregar fotos',
        commentPermissionLabel: 'Permisión de comentar',
        todosToolTip:
          'Agregar Fotos, Ver Fotos, y Comentarios deben estar activados para que los colaboradores puedan acceder a las Listas de Tareas.',
        viewPhotoPermissionLabel: 'Permisión de ver fotos',
      },
      table: {
        active: 'ACTIVO',
        activeInviteHead:
          'Ya no se ha aceptado ninguna invitación de colaboración.',
        activeInviteSubhead:
          'Cuando se acepten tus invitaciones, recibirás una notificación y verás la empresa colaboratriz en la lista en esta página.',
        altTextNoResultsFound:
          'Ilustración de una lente de aumento que no encuentra resultados.',
        contactInfoHeader: 'Información de Contacto',
        emptyCta: 'Invitar a una empresa a colaborar',
        emptyHead:
          '¿Trabajando con un subcontratista o socio de servicio en este proyecto? %{strong}',
        emptyState:
          'Esta búsqueda no devolvió ningún resultado. Por favor, intenta con un término de búsqueda diferente.',
        emptySubhead:
          'Las colaboraciones son ilimitadas con tu plan actual. Tú puedes decidir qué cada empresa colaboratriz puede ver y compartir, y puedes terminar la colaboración en cualquier momento.',
        ended: 'FINALIZADO',
        errorCta: 'Cargar esta página de nuevo',
        errorHead: '¡Ups! Hubo un problema al cargar los colaboradores.',
        errorSubhead:
          'Por favor, revisa tu conexión e intenta cargar la página de nuevo.',
        nameHeader: 'Nombre',
        noCollaboratorsNoPerms: 'Este proyecto no tiene ningún colaborador.',
        statusHeader: 'Estado',
      },
    },
    customersRow: {
      trustyCustomers: 'Clientes de Trusty',
    },
    deleteConfirmationModal: {
      error: 'Occurió un error al eliminar el proyecto',
      modalInstructions:
        '¿Estás seguro/a que quieres eliminar este proyecto? <strong>Todas las fotos en este proyecto también serán eliminadas.</strong>',
      modalTitle: 'Eliminar proyecto',
    },
    forms: {
      edit: {
        nameOrAddressRequired: 'Se requiere un nombre o una dirección',
        pageTitle: 'Editar %{title}',
      },
      form: {
        cancelAriaLabel:
          'No se guardará ningún dato en esta página que hayas cambiado.',
        city: 'Ciudad',
        country: 'País',
        createNewProject: 'Crear proyecto',
        createProject: 'Crear proyecto',
        editProject: 'Editar proyecto',
        postalCode: 'Código postal',
        projectAddress1: 'Dirección del proyecto 1',
        projectAddress2: 'Dirección del proyecto 2',
        projectName: 'Nombre del proyecto',
        saveProject: 'Guardar proyecto',
        state: 'Estado',
        selectAState: 'Selecciona un estado',
        emptyProjectTemplatesMessage:
          'No se encontraron plantillas de proyecto. Crea uno ahora para optimizar tus proyectos.',
        emptyProjectTemplatesButton: 'Ir a Plantillas de Proyecto',
        emptyProjectTemplatesPlaceholder: 'Aplicar una plantilla de proyecto',
      },
      new: {
        drawaGeofence: 'Dibujar un Geofence',
        closeAriaLabel: 'Cerrar y entrar manualmente los detalles del proyecto',
        modalTitle: 'Crear Proyecto',
        nameOrAddressRequired: 'Se requiere un nombre o una dirección',
        noAddress:
          '¿No tienes una dirección para su proyecto? ¡No te preocupes!',
        pageTitle: 'Crear un proyecto nuevo',
        placeaPin: 'Colocar un Pin',
        searchPlaceholder: 'Buscar dirección o nombre en Google',
      },
      projectTemplates: {
        addButton: 'Usar plantilla al proyecto',
        defaultTemplateErrorMsg:
          'Hubo un problema al obtener la plantilla de proyecto predeterminada',
        description: 'Precargue sus proyectos con listas de tareas e informes',
        header: 'Plantillas de proyecto',
        nonAdminUpgradeMessage:
          'No tienes acceso a Plantillas de Proyecto. Ponte en contacto con tu administrador para actualizar tu plan.',
        upgrade: 'Actualizar Plan',
      },
    },
    guests: {
      guestProjectsList: {
        addGuestUser: 'Agregar Acceso de Invitado',
      },
      guestForm: {
        accessExpiration: 'Fecha de expiración del acceso',
        addGuest: 'Agregar invitado',
        guestEmail: 'Email del invitado',
        guestName: 'Nombre del invitado',
        guestPhone: 'Teléfono del invitado',
        message: 'Mensaje',
        saveGuest: 'Guardar invitado',
        toggleLabel:
          'Permitir a este invitado que vea todas las fotos en este proyecto.',
      },
      guestProject: {
        accessExpiration: 'Expiración de acceso',
        accessLevel: 'Nivel de acceso',
        allProjectPhotos: 'Todas las fotos del proyecto',
        confirm: 'Esta usted seguro/a?',
        justTheirPhotos: 'Solo sus fotos',
        never: 'Nunca',
        resendInvite: 'Re-envie la invitación',
      },
      index: {
        addCollaborator: 'Añadirlos como colaborador',
        modalMessage:
          'Guest Access allows homeowners to view and add photos through a simple web link; no app install required. Guest Access accounts only see photos — nothing else.',
        modalSubtitle: 'Invite a Client / Homeowner',
        modalTitle: 'Acceso de Invitados',
      },
    },
    listContainer: {
      projectsTitle: 'Proyectos',
      activeProjectsNoResults: 'Aún no se han creado proyectos.',
      activeProjectsNoResultsSubTitle:
        'Sincroniza las fotos y video de tu equipo con los proyectos.',
      allProjectsNoResults: 'Aún no se han creado proyectos.',
      allProjectsNoResultsSubTitle:
        'Sincroniza las fotos y video de tu equipo con los proyectos.',
      archivedProjectsNoResults:
        'Aún no has archivado ningún proyecto. Cuando lo haga, aparecerán en esta lista.',
      archivedProjectsNoResultsAlt:
        'Una imagen de un mapa conectado a proyectos.',
      archivedProjectsNoResultsTitle: 'Aún no hay proyectos archivados',
      createProject: 'Crear Proyecto',
      emptyProjectFeed: 'Create a project for every job.',
      emptyProjectFeedSubTitle:
        'Las fotos, documentos y comunicaciones de cada trabajo vivirán en el proyecto.',
      loadMoreProjects: 'Cargar más proyectos',
      myProjectsNoResults:
        'Aún no has contribuido a ningún proyecto. Cuando lo hagas, aparecerán en esta lista.',
      myProjectsNoResultsAlt: 'Una imagen de un mapa conectado a proyectos',
      myProjectsNoResultsSubTitle:
        'Aún no has contribuido a ningún proyecto, pero toda tu actividad aparecerá en esta lista.',
      noFilterResults: 'Ningún proyecto se ajusta a los filtros seleccionados.',
      pageTitle: 'Lista de Proyectos',
      searchNoResults:
        'Su búsqueda no coincide con ninguno de los proyectos de su empresa.',
      searchPlaceholder: 'Buscar un proyecto…',
      starredProjectsNoResults:
        'Aún no has protagonizado ningún proyecto. Cuando lo hagas, aparecerán en esta lista.',
      starredProjectsNoResultsAlt:
        'Una imagen de una carpeta destacada con archivos dentro',
      starredProjectsNoResultsSubTitle:
        'Controle fácilmente los proyectos más importantes marcándolos con una estrella para destacarlos.',
      newProject: 'Nuevo Proyecto',
      workFasterWithTemplates: 'Trabaja más rápido con Plantillas de Proyectos',
      createOneNow: 'Crea una ahora para agilizar tus proyectos.',
      goToProjectTemplates: 'Ve a Plantillas de Proyecto',
    },
    listItem: {
      docs: 'Documentos',
      lastUpdated: 'última actualización',
      recentUserLabel: 'Reciente usuario',
      recentUsersLabel: 'Recientes usuarios',
    },
    members: {
      add: {
        ariaLabel: 'Agregar Usuarios',
        loadingMessage: 'Buscando %{companyName}',
        placeholder: {
          groups: 'Escribe el nombre de un Grupo',
          users: 'Escribe el nombre de un Usuario',
        },
        searchGroupsLabel: 'Grupos',
        searchModalSubtitle: 'Agregar Usuarios o Grupos de %{companyName}',
        searchUsersLabel: 'Usuarios',
        success: {
          one: 'Review %{userCount} usuario se asignaro',
          other: 'Review %{userCount} usuarios se asignaro',
        },
        title: 'Agregar Usuarios al Proyecto',
        titleConfirm: '¿Estás seguro?',
        titleError: 'Ups.',
      },
    },
    onboardCards: {
      connect:
        '<strong>Conecta una integración</strong> para simplificar tu flujo de trabajo.',
      connectAccessibilityLabel: 'Conecta una integración',
      invite:
        '<strong>Invita a tu equipo</strong> a mantener cada foto en un solo lugar.',
      inviteAccessibilityLabel: 'Invita a tu equipo',
      qr: 'Escanea este código QR para <strong>obtener la aplicación y empezar a tomar fotos.</strong>',
      qrAccessibilityLabel: 'Escanea este código QR para obtener la aplicación',
      demo: '<strong>Vea una demostración</strong> para obtener una descripción general de todas las funciones',
      demoAccessibilityLabel: 'Ver una demostración',
    },
    pages: {
      exportPdf: {
        includeCoverPage: 'Incluir Página de Portada',
      },
    },
    projectFilterModal: {
      applyFilter: 'Aplicar filtro',
      endDate: 'La fecha de finalización',
      modalTitle: 'Filtre el proyecto',
      selectTags: 'Selecte etiqueta(s)',
      startDate: 'Fecha de inicio',
      usersAndGroups: 'Usuarios y Grupos',
    },
    projectTemplatesModal: {
      defaultTemplateBadge: 'Plantilla Predeterminada',
      emptyStateHeader: 'No has creado plantillas de proyecto',
      emptyStateMessage:
        'Para crear una plantilla de proyecto, visite Plantillas en la aplicación web de CompanyCam',
      header: 'Plantillas de proyecto',
      lastUpdated: 'Última actualización hace %{time}',
      upgradeModal: {
        altText: 'Crear Plantilla de Proyecto',
        primaryButton: 'Actualizar',
        secondaryButton: 'Ahora No',
        header: 'Plantillas de proyecto',
        subheading: 'Trabaja más rápido con Plantillas de Proyecto',
        featureOne: 'No más comenzar proyectos desde cero.',
        featureTwo:
          'Precarga proyectos con Listas de Tareas, Documentos, Etiquetas, Páginas y Informes.',
        featureThree: '¡Actualiza ahora para optimizar tus proyectos!',
      },
    },
    recentProjectsToolbar: {
      allProjectsOption: 'Todos',
      archived: 'Archivado',
      filterProjects: 'Filtrar',
      myProjectsOption: 'Mis proyectos',
      refreshProjects: 'Actualizar',
      showButton: 'Mostrar',
      starredProjectsOption: 'Destacados',
    },
    show: {
      googleCalendarLink: {
        googleCalendarDetailsMessage:
          'Evento creado de este proyecto desde CompanyCam: %{projectLinkURL}',
        googleCalendarLinkTitle: 'Enviar al Calendario de Google',
      },
      collaborationRow: {
        callout: {
          cta: '¡Invítales a colaborar a tus subcontratistas y socios de servicio, así que puedan agregar fotos también!',
          dismiss: 'Ok, lo entiendo',
        },
        countedText: 'Colaboradores (%{count})',
        defaultText: 'Agregar Colaboradores',
        error: '¡Uy! Hubo un error al cargar los colaboradores.',
        ownerScreenReaderContent: '%{companyName} es el dueño de este proyecto',
        restrictedFeatureModal: {
          confirm: 'De Acuerdo',
          content:
            'No tienes los permisos necesarios para ver la información del colaborador. Por favor, contacta al administrador del sistema para obtener ayuda.',
          title: 'Lo siento, no tienes permiso para acceder a eso.',
        },
      },
      downloadProjectPhotosModal: {
        modalLabel: 'Descargar todas las fotos',
        downloadPhotos: 'Descargar fotos',
        instructions:
          'Entre su correo electrónico y nosotros le notificamos a usted cuando su descarga este lista. Su enlace también aparecerá aquí cuando este listo si a usted le gustaría esperar.',
        modalInstructionsSubmitted:
          'Nosotros estamos empaquetando sus fotos y enviaré ellas por correo electrónico a %{email}. Su enlace también aparecerá aqui cuando esté listo si desea esperar.',
        modalTitle: 'Descargar todas las fotos',
        modalTitleCompleted: 'Su descarga está lista',
        modalTitleSubmitted: 'Empaquetar fotos',
      },
      integrationActions: {
        requestSentMsg: 'Solicitud Enviada!',
      },
      manageActions: {
        archiveProject: 'Archivar proyecto',
        downloadAllPhotos: 'Descargar todas las fotos',
        editProject: 'Editar el proyecto',
        integrationActions: 'Integrations',
        mergeProject: 'Unir el proyecto',
        project: 'Proyecto nuevo',
        trashCan: 'Ver basurero de proyecto',
        unarchiveProject: 'Desarchivar proyecto',
        viewGoogleMap: 'Ver el mapa en Google',
      },
      membersRow: {
        countedText: 'Usuarios del Proyecto (%{count})',
        defaultText: 'Usuarios del Proyecto',
        error: '¡Uy! Hubo un error al cargar los usuarios.',
      },
      mergeProjectModal: {
        selectOneToKeep: 'Selecte UNO para mantener:',
        selectProject: 'Seleccione un proyecto de la lista de arriba.',
        assignedUsers: 'Asignar Usuarios',
        deleteThis: 'Eliminar este proyecto.',
        documents: 'Documentos',
        keepThis: 'Mantener este proyecto y mover todas las fotos aqui.',
        mergeProjects: 'Unir el proyecto',
        modalTitle: 'Unir el proyecto',
        pages: 'Páginas',
        photos: 'Fotos',
        projectConversation: 'Conversación del proyecto',
        projectLabels: 'Etiquetas de proyecto',
        projectNotepad: 'Notas del proyecto',
        projectShowcase: 'Exhibición',
        reports: 'Informes',
        searchForAProject: 'Buscar un proyecto:',
        todoLists: 'Lista de tareas',
        willMove: 'Se moverá al otro proyecto:',
        willNotMove: 'NO se moverá al otro proyecto y se eliminará:',
      },
      photoUploadModal: {
        dismissErrors: 'Despedir',
        errorMessageExceedLimit:
          'La subida falló: Se permite un máximo de 5 imágenes de referencia. Por favor, inténtalo de nuevo.',
        errorMessageTooLarge:
          'La subida falló: el tamaño de %{fileName} es demasiado grande. Por favor, selecciona un archivo diferente e inténtalo de nuevo.',
        errorMessageWrongFormat:
          'La subida falló: No se acepta el formato de %{fileName}. Por favor, selecciona un archivo diferente e inténtalo de nuevo.',
        modalInstructions:
          'Arrastrar y baje fotos aqui o clic para descargar a este proyecto.',
        modalInstructionsCompleted:
          'Sus fotos han terminado de cargarse y ahora están procesando. Ellos aparecerán dentro de poco en su proyecto. Para continuar añadiendo fotos, Haga clic aquí o arrastre y suelte fotos adicionaless.',
        modalTitle: 'Subir Fotos',
        uploading: 'Descargando %{count} de fotos…',
      },
      project: {
        archiveBannerMessage: 'Proyecto archivado por %{name} el %{date}.',
        archiveBannerMessage2:
          'Este proyecto estará oculto en todos los feeds de la empresa, pero aún podrás verlo en la pestaña',
        archiveBannerMessage3: 'en el Feed de Proyectos',
        archiveMessage:
          'Proyecto archivado por %{name} el %{date}. Este proyecto se ocultará del feed de Proyectos cercanos en la aplicación móvil.',
        inactive: 'Se ha eliminado este proyecto el %{deletedOn}.',
        unarchiveBtn: 'Desarchivar',
      },
      projectLabelsContainer: {
        labelsEditMessage: 'Editar Etiquetas',
        labelsMessage: 'Agregar Etiquetas',
        manageLabels: 'Etiquetas',
      },
      projectSidebar: {
        conversationTitle: 'Conversación del proyecto',
        hideSidebarLabel: 'Esconder Barra Lateral',
        modalContentLabel: 'Editar Descripción del Proyecto',
        notepadReadAll: 'Leer todas las notas',
        notepadTitle: 'Descripción',
        showSidebarLabel: 'Mostrar Barra Lateral',
        sidebarOnboardingMessage:
          'Ahora puede contraer la barra lateral para tener más espacio para explorar el contenido en la pantalla del proyecto. Haga clic en el icono de flecha para abrirlo y cerrarlo.',
        tasks: 'Tareas',
      },
      shareActions: {
        addCollaborators: 'Agregar Colaboradores',
        addGuestUsers: 'Agregar Acceso de Invitado',
        disableTimelineLink: 'Deshabilitar Línea Temporal',
        enableTimelineLink: 'Habilitar Línea Temporal',
        project: 'Proyecto',
        shareAllImages: 'Compartir todas las imágenes',
        shareTimelineLink: 'Compartir enlace de la linea de tiempo',
        timeline: 'Línea de tiempo',
      },
      shareTimelineModal: {
        instructions:
          'Usar este proyecto con el enlace de la línea de tiempo para compartir con gente quien le gustaría mantenerse al dia con este proyecto.',
        modalTitle: 'Compartir la línea de tiempo',
        viewTimeline: 'Ver la línea de tiempo',
      },
      sharingPermissions: {
        alertMessage: 'Los colaboradores verán tus fotos',
        photosAreSeenByCollabs: 'Los colaboradores con permiso verán tus fotos',
      },
    },
    tasklist: {
      templates: {
        templatesHeader: {
          projectBackLink: 'Plantillas',
        },
      },
    },
    trialBanner: {
      addPaymentMethod: 'Agregar Método de Pago',
      seePlans: 'Ver Planes',
      timeRemainingEliteSingular:
        'Queda %{trialDaysRemaining} de tu prueba Elite.',
      timeRemainingElitePlural:
        'Quedan %{trialDaysRemaining} de tu prueba Elite.',
      timeRemainingPremiumSingular:
        'Queda %{trialDaysRemaining} de tu prueba Premium.',
      timeRemainingPremiumPlural:
        'Quedan %{trialDaysRemaining} de tu prueba Premium.',
      timeRemainingProSingular: 'Queda %{trialDaysRemaining} de tu prueba Pro.',
      timeRemainingProPlural: 'Quedan %{trialDaysRemaining} de tu prueba Pro.',
    },
    users: {
      add: {
        errorMessage:
          'Hubo un error al asignar usuarios al proyecto: %{errorMessage}',
        inviteNew: 'Invitar un usuario nuevo',
        noGroupsFound: 'No se encontraron grupos.',
        noUsersFound: 'No se encontraron usuarios.',
        submit: 'Asignar',
      },
      anotherCompany: '¿Estás trabajando con otra empresa?',
      assigned: 'Asignado',
      collabCta: 'Invítalos a colaborar',
      collabTooltip:
        'Consulte nuestra función de Colaboración para invitar a un subcontratista o socio de servicio a agregar fotos en este proyecto',
      contributors: 'Contribuidores',
      cta: 'Asignar Usuarios',
      deactivated: 'Desactivado',
      errorMessage:
        'Se ha producido un error al cargar los usuarios del proyecto.',
      listItem: {
        introPara:
          'Usuarios que hayan contribuido y/o estén asignados a %{projectName}:',
        lastContributedAt: 'Última actividad: %{lastContributedAt}',
      },
      loadMore: 'Cargar Más',
      selectOption: {
        groupNameFormat: '%{groupName} (grupo)',
      },
      title: 'Usuarios del Proyecto',
      tryAgain: 'Inténtalo de Nuevo',
      unassign: {
        success: '¡Usuario no asignado!',
        undo: 'Deshacer',
      },
      unassigned: 'No asignado',
    },
  },
  qBO: {
    modal: {
      customerRecord: 'Registro de Cliente',
      estimate: 'Estimación',
      invoice: 'Factura',
      noCustomerRecord:
        'Parece que no tienes un registro de cliente correspondiente de Quickbooks para este proyecto, así que se creará uno nuevo para ti.',
      noInvoicesOrEstimates:
        'Parece que no tienes ninguna factura o estimación para el cliente correspondiente de Quickbooks. Sin embargo, aún puedes enviar fotos al registro del cliente.',
      selectPlaceholder: 'Selecciona un artículo',
      selectRecord:
        'Selecciona la factura, estimación o registro de cliente al que deseas enviar estas fotos:',
      send: 'Enviar',
    },
  },
  referral: {
    modal: {
      creatingLink: 'Creando enlace personalizado',
      description:
        'Comparte tu enlace de referencia único por mensaje de texto, correo electrónico o lo que sea más fácil. Recibirás $100 si tu referido se convierte en cliente pagador durante 2 meses.',
      linkError: 'Hubo un error al cargar tu enlace de referido personalizado.',
      programDetails: 'Ver Detalles del Programa',
      title: 'Gana $100 por referencias',
    },
  },
  reportTemplate: {
    section: {
      summaryLabel: 'Sección Resumen',
      titleInputLabel: 'Título de Sección',
    },
  },
  reports: {
    emptyState: {
      title: 'Reportes Fotográficos Profesionales en Segundos.',
      subtitle:
        'Consejo: Comparte un PDF de fotos con agencias de seguros y clientes.',
      tutorialCta: 'Ver Tutorial',
    },
    addEntryMenu: {
      photo: 'Insertar foto',
    },
    altText: 'Imagen de un conjunto de informes fotográficos.',
    collab: {
      alert: 'Sólo %{companyName} podrá ver los informes.',
      alertStrong: 'No se comparten los informes entre colaboradores.',
    },
    coverPage: {
      companyLogoSubtitle:
        'Muestre su logotipo, si su empresa subió uno a CompanyCam',
      companyLogoTitle: 'Logotipo de la empresa',
      companyNameSubtitle: 'Mostrar el nombre de la empresa',
      companyNameTitle: 'Nombre de la empresa',
      coverPhotoLabel: 'Foto destacada',
      coverPhotoSubtitle: 'Mostrar la foto de portada, si seleccionó una',
      creatorNameSubtitle:
        'Mostrar el nombre de la persona que creó el informe',
      creatorNameTitle: 'Nombre del creador',
      dateCreatedSubtitle: 'Mostrar la fecha en que se creó el informe',
      dateCreatedTitle: 'Fecha de creación',
      featuredImgAlt: 'Foto destacada para mostrar en la página de cubierta',
      logoImgAlt: 'Logotipo de la empresa para mostrar en la portada',
      photoCountSubtitle: 'Mostrar el número de fotos en el informe',
      photoCountTitle: 'Recuento de fotos',
    },
    create: {
      error: 'Hubo un problema al crear su informe',
      layoutRequired: 'Seleccione un diseño de página de fotos',
      modalTitle: 'Crear un nuevo informe',
      titleRequired: 'Se requiere título del informe',
    },
    createForm: {
      createButton: 'Crear Informe',
      title: 'Título del informe',
    },
    createReport: 'Crear Informe',
    delete: {
      confirm:
        '¿Está seguro de que desea eliminar este informe? Se perderán todas las secciones, fotos y descripciones.',
      error: 'Hubo un problema al eliminar el informe.',
    },
    editing: {
      createFirstSection: 'Crear primera sección',
      deleteReport: 'Eliminar Informe',
      fetchError: 'Hubo un problema al obtener el informe.',
      manage: 'Gestionar',
      saveAsTemplate: 'Guardar como Plantilla',
      settings: 'Configuración',
      tooltipMsg:
        'Guarda como plantilla para rápidamente crear informes futuros',
      tooltipUpgradeMsg:
        'Actualiza para guardar como plantilla y crear rápidamente informes futuros',
      viewReport: 'Ver informe',
    },
    entries: {
      createError: 'Hubo un problema al agregar un elemento al informe',
      deleteConfirm:
        '¿Estás seguro de que deseas eliminar esta foto? Esta acción no se puede deshacer.',
      deleteEntry: 'Delete Entry',
      deleteError: 'Hubo un problema al eliminar el elemento.',
      deleteSuccess: 'Elemento eliminado',
      loadPhotoComments: 'Cargar Comentarios de la Foto',
      loadPhotoDescription: 'Load Photo Description',
    },
    fetch: {
      error: 'Hubo un problema al obtener los informes.',
    },
    generatePdf: 'Generar PDF',
    getReport: {
      fetchError: 'Hubo un problema al obtener el informe.',
    },
    getReports: {
      fetchError: 'Hubo un problema al obtener los informes.',
    },
    loadMore: 'Cargar Más',
    messages: {
      deleteSectionModalTitle: 'Eliminar seccion',
    },
    newReport: 'Nuevo informe',
    noReportsFound: 'No se encontraron informes',
    noteEditor: {
      clickToAddTextSnippets: 'Haz clic aquí para agregar texto',
    },
    photoEntry: {
      coverPhotoLabel: 'Foto destacada',
      editPhotoTooltip: 'Haz clic para editar la foto',
      setCoverPhoto: 'Establecer como foto destacada',
    },
    placeholderText: 'Encontrar un informe...',
    readOnly:
      'Los Reportes existentes son de solo lectura en tu plan actual. Actualiza para ver informes.',
    rearrange: {
      label: {
        confirmSelection: 'Confirmar y agregar fotos',
        selectPhotos: 'Reorganizar Fotos',
        selectSection: 'Seleccionar Sección',
      },
      saveButton: 'Guardar',
      sectionNoContent:
        'Click “Confirm and Add Photos“ to show it a little love. Your photos will be moved to this section in the order they were selected.',
      sectionNoContentTitle: 'This section needs some content!',
    },
    reportSelect: {
      selectReport: 'Seleccionar un informe',
    },
    reportSelectModal: {
      addPhotostoReport: 'Agregar fotos al informe',
      addtoReport: 'Agregar al informe',
      selectReport: 'Seleccionar un informe',
    },
    section: {
      addPhotos: 'Agregar Fotos',
      createError: 'Hubo un problema al crear la sección.',
      createSuccess: 'Sección creada',
      cta: 'Hemos mejorado la forma en que puede organizar y\n presentar informes. Comience creando una sección y\n agregando sus fotos en ella.',
      deleteConfirm:
        '¿Está seguro de que desea eliminar esta sección? Todo el contenido de la sección se eliminará permanentemente de este informe. Esta acción no se puede deshacer.',
      deleteError: 'Hubo un problema al eliminar la sección.',
      deleteSection: 'Delete Section',
      deleteSuccess: 'Sección eliminada',
      emptySummary: 'A summary page is how you can tell your story.',
      emptySummaryCTA: 'Click to Add a Summary',
      loadComments: 'Cargar Comentarios de la Foto',
      loadPhotoDescriptions: 'Load Photo Descriptions',
      movePhotos: 'Mover Varias Fotos',
      newSection: 'Nueva Sección',
      noSections: 'Parece que aún no ha creado ninguna sección.',
      photoOptions: 'Opciones de Foto',
      rearrangePhotos: 'Reorganizar Fotos',
      summaryWarning:
        'Actualmente, los resúmenes de las secciones están ocultos. Para habilitarlos, vaya a la configuración de este informe y active las páginas de título de la sección.',
      titleInputLabel: 'Título de Sección',
      updateError: 'Hubo un problema al actualizar la sección.',
      updateSuccess: 'Sección actualizada',
    },
    sections: {
      createError: 'Hubo un problema al crear la sección.',
    },
    settings: {
      charLimit: 'Límite de caracteres',
      commentsError: 'Hubo un problema al importar los comentarios de la foto.',
      commentsLoaded: 'Imported photo comments.',
      coverError: 'Hubo un problema al actualizar la foto de portada.',
      coverUpdated: 'Foto de portada actualizada.',
      dateDesc: 'Fecha y hora de tomar la foto',
      dateLabel: 'Cuándo se tomó la foto',
      descriptionsError: 'There was a problem importing photo descriptions.',
      descriptionsLoaded: 'Imported photo descriptions.',
      headerExplanation:
        'Se mostrará esta información en el encabezado de cada página de fotos cuando crees el informe PDF.',
      hidden: 'Oculto',
      labelCenter: 'Central',
      labelLeft: 'Izquierda',
      labelRight: 'Derecha',
      photoNumbersDesc: 'Números aparecerán en la orden de las fotos',
      photoNumbersLabel: 'Números de fotos',
      photoPageHeader: 'Encabezado de las páginas de fotos',
      photoPageLayout: 'Diseño de las páginas de fotos',
      reportDate: 'Report Date',
      settingsHeading: 'Configuración del informe',
      showHide: 'Mostrar/Ocultar',
      subtitle: 'Subtítulo del informe',
      tagsDesc: 'Mostrar una lista de los tags de la foto',
      tagsLabel: 'Los tags de la foto',
      title: 'Título del informe',
      toggleSummaryDesc: 'Mostrar título y resumen en página completa',
      toggleSummaryTitle: 'Páginas de Título de Sección',
      toggleSummaryWarning:
        'Aviso: Desactivar esta configuración significa que se ocultarán los resúmenes de las secciones.',
      visible: 'Visible',
      whereDesc: 'Proyecto en que se tomó la foto',
      whereLabel: 'Dónde se tomó la foto',
      whoDesc: 'Mostrar el usuario quien tomó la foto',
      whoLabel: 'Quién tomó la foto',
    },
    shareReportLabel: 'Compartir informe',
    shareReportModal: {
      errors: {
        generateLink: 'Hubo un problema al generar un nuevo enlace.',
        getLink: 'Hubo un problema al obtener tu enlace compartible.',
      },
      generateNewLink: 'Generar nuevo enlace',
      securityExpiredMessage: 'Este enlace expiró hace',
      securityMessage: 'Este enlace expirará',
      title: 'Compartir tu Informe',
    },
    templateDescription: {
      label: 'Descripción de la Plantilla',
      placeholder: 'Escriba una descripción de la plantilla',
    },
    templateEditModal: {
      deleteSectionConfirmation:
        '¿Está seguro que desea eliminar esta sección?',
      errorHeadline: 'O rayos.',
    },
    templateListModal: {
      errorMessage: 'Ocurrió un error al cargar las plantillas.',
      header: 'Utilice una Plantilla para crear un Informe',
      noTemplatesHeadline: 'Ninguna Plantilla Encontrada',
      noTemplatesMessage:
        'Por favor, primero guarda un informe como plantilla.',
      spinnerMessage: 'Cargando plantillas...',
      useTemplateHint: 'Haz clic para utilizar la plantilla',
    },
    templateSaveModal: {
      error:
        'Hubo un error al crear tu plantilla. ¡Probablemente nuestra culpa! Actualice la página y vuelva a intentarlo.',
      successButtonLabel: 'Ok, entiendo',
      successMessage:
        '%{title} se ha guardado como una plantilla que puede utilizar al crear un informe nuevo.',
      successTitle: 'Plantilla Guardada',
      templateDescriptionLabel: 'Descripción de Plantilla (opcional)',
      templateNameLabel: 'Nombre de Plantilla',
      templateNamePlaceholder: 'e.g., Plantilla de Inspección',
      title: 'Guardar como Plantilla de Informe',
      titleLength:
        'El nombre de la plantilla debe tener menos de 100 caracteres',
      titleRequired: 'Nombre de plantilla requerido',
    },
    templateValues: {
      coverPageSettings: 'Configuración de la portada',
      description:
        'Su nueva plantilla incluirá lo siguiente de %{reportTitle}:',
      pageLayout: 'Diseño de página',
      reportSettings: 'Configuración del informe',
      sectionAndOrder: 'Nombres y orden de las secciones',
    },
    templates: {
      onboardModalAction: 'Echa un vistazo a las plantillas',
      onboardModalDismiss: 'Despedir',
      onboardModalHeader: 'El editor de plantillas se está moviendo!',
      onboardModalSubheader:
        '¿Quieres editar una plantilla de informe? Echa un vistazo a nuestro nuevo administrador de plantillas todo en uno para informes, tareas y proyectos. Puede encontrarlo haciendo clic en tu avatar en la barra lateral y luego haciendo clic en el elemento del menú Plantillas.',
    },
    title: 'Informes',
    useExistingTemplate: 'Usar Plantilla Existente',
  },
  resources: {
    partnerStatus: {
      requested: 'Solicitado',
      restricted: 'Restringido',
      subscribed: 'Suscrito',
    },
  },
  richTextEditor: {
    editor: {
      saveSelectedTextSnippet:
        'Crear nuevo fragmento de texto a partir de lo seleccionado',
      saveTextSnippet: 'Crear un nuevo fragmento de texto con contenido',
    },
    toolbar: {
      addSnippet: 'Agregar fragmento de texto',
      link: 'Vincular',
      snippets: 'Fragmentos de texto',
      unlink: 'Desvincular',
    },
  },
  roles: {
    adminDescription:
      'Control total. Este es el único nivel que puede actualizar o cancelar la cuenta.',
    adminFitFor1: 'Presidente o dueño',
    adminFitFor2: 'Interventor o controlador',
    adminFitFor3: 'Administrador de la oficina',
    adminModalDescription:
      'Estos usuarios tienen toda su confianza y acceso completo a todo. Pueden administrar usuarios y configurar integraciones. Los administradores pueden crear o eliminar cualquier cosa, así como actualizar, degradar o cancelar su cuenta.',
    fitFor: 'Un buen ajuste para:',
    managerDescription:
      'Puede acceder a todos los proyectos/funciones, administrar usuarios y eliminar. No se puede administrar la facturación.',
    managerFitFor1: 'Jefe de equipo',
    managerFitFor2: 'Capataz',
    managerFitFor3: 'Supervisor',
    managerModalDescription:
      'Estos usuarios tienen acceso a todos los proyectos y funciones. Pueden administrar usuarios y configurar integraciones. Los gerentes pueden crear o eliminar cualquier cosa, pero no pueden actualizar, degradar o cancelar su cuenta.',
    restrictedDescription:
      'Solo pueden acceder a los proyectos que crean o a los que están asignados.',
    restrictedFitFor2: 'Empleado a tiempo parcial',
    restrictedFitFor3: 'Empleados en régimen de "necesidad de conocimiento"',
    restrictedModalDescription:
      'Estos usuarios no pueden acceder a todos los proyectos de la empresa. Los usuarios restringidos solo pueden ver los proyectos que crean o que les asigne. Dentro de esos proyectos, pueden ver contenido, agregar contenido y acceder a informes y tareas pendientes. No pueden administrar usuarios ni eliminar nada.',
    restritedFitFor1: 'Subcontratista',
    standardDescription:
      'Puede acceder a todos los proyectos/funciones. No se puede eliminar ni administrar la facturación/usuarios.',
    standardFitFor1: 'Miembro de la cuadrilla o equipo',
    standardFitFor2: 'Empleado a tiempo completo',
    standardFitFor3: 'Técnico',
    standardModalDescription:
      'Estos usuarios pueden ver todos tus proyectos, administrar colaboraciones y usar todas las funciones. No pueden administrar usuarios, eliminar nada ni actualizar, degradar o cancelar su cuenta.',
  },
  search: {
    input: {
      placeholder: 'Buscando...',
    },
    no: {
      results: 'Prueba con diferentes palabras clave.',
      title: 'No se han encontrado resultados',
    },
    paginationbar: {
      nextpage: 'Página siguiente',
      previouspage: 'Página anterior',
    },
    result: {
      empty: {
        state:
          'Busque Proyectos, Usuarios, Comentarios, Etiquetas, Informes, Contactos de Proyecto y más.',
      },
      group: {
        groupBadge: 'Grupo',
      },
    },
    results: {
      loading: 'Cargando Resultados',
    },
    title: 'Buscar',
    view: {
      all: 'Ver Todos',
    },
  },
  shared: {
    dropDown: {
      singleDropDown: {
        idleText: 'No resultados',
      },
    },
    favoritedStar: {
      favoritedStar: {
        starItem: 'Marcar este artículo como destacada',
        unStarItem: 'Eliminar este artículo de la lista destacada',
      },
    },
    header: {
      userMenuItem: {
        integrations: 'Integraciones',
      },
    },
    restrictedAccessBanner: {
      action:
        'Ponte en contacto con el administrador de tu empresa para actualizar tu plan.',
      freePremiumSampleLink: 'Prueba Premium Gratis',
      upgradePlanLink: 'Actualizar Plan',
    },
    userGroupSearchSelect: {
      idleText: 'Escribe para buscar',
      placeholder: 'Buscar usuarios y grupos',
    },
    messageModal: {
      defaultConfirmLabel: 'Ok',
    },
  },
  showcase: {
    create: {
      v2PublishWizard: {
        main: {
          description1: 'Consulte nuestro ',
          description2: 'guía de configuración ',
          description3:
            'para obtener más información sobre cómo crear y publicar exhibiciones en su sitio web.',
        },
      },
    },
    list: {
      column: {
        createdAt: 'Fecha de Creación',
        projectName: 'Proyecto',
        showcaseName: 'Titulo de la exhibición',
        status: 'Habilitado',
      },
      emptyStateButtonPrimary: 'Aprende Como',
      emptyStateHeadline:
        'Mostrando sus trabajos en tu sitio web es ahora más fácil.',
      emptyStateSubhead:
        'A su clientes potenciales les encanta ver las fotos de sus trabajos, pero no tienes horas para dedicar a actualizar tu sitio web. Con las exhibiciones es muy sencillo publicar tus fotos de CompanyCam directamente en tu sitio web.',
      getShowcaseProject: {
        error: 'Algo falló al cargar esta exhibición.',
      },
      installShowcaseWidget: 'Instalar Widget de Exhibición',
      query: {
        error:
          'Lo siento, hubo un problema y no se puede cargar la lista de exhibiciones. Por favor inténtalo más tarde.',
      },
      setupGuide: 'Guía de Configuración',
      title: 'Exhibiciones',
    },
    publishDropdown: {
      createShowcase: 'Crear Nueva Exhibición',
      editShowcase: 'Editar Exhibición',
    },
    wizard: {
      media: {
        error:
          'Ocurrió un error al cargar las fotos. Por favor inténtelo de nuevo.',
      },
      selectPhotos: {
        headerDescription:
          'Select any photos you want to share in your published showcase.',
        headerTitle: 'Choose Photos to Share',
      },
    },
  },
  showcases: {
    create: {
      publishWizard: {
        closeConfirm: {
          cancelButton: 'No, no salir',
          confirmButton: 'Sí, salir',
          description:
            'Cualquier cambio que hayas hecho a la Exhibición no será guardado.',
          heading: 'Está seguro que desea salir?',
        },
        deleteConfirm: {
          cancelButton: 'No, no eliminar',
          confirmButton: 'Sí, eliminar',
          description:
            'Está seguro que desea eliminar esta Exhibición de su sitio? Tendrá que comenzar de nuevo si desea crear esta Exhibición nuevamente.',
          heading: 'Eliminar Exhibición',
        },
        main: {
          materials: {
            emptyState: 'No se encontraron materiales',
          },
          tags: {
            error: 'Algo salió mal. Por favor, inténtalo de nuevo.',
          },
          types: {
            emptyState: 'No se encontraron tipos',
          },
        },
        tags: {
          createMessage: 'Crear %{inputValue}',
          select: 'Seleccionar %{tagType}',
        },
      },
      v2PublishWizard: {
        coverPhoto: {
          description:
            'Esto se mostrará en las tarjetas de vista previa, así como en el área de encabezado principal de la página de presentación individual.',
          title: 'Elige Una Foto de Portada',
        },
        main: {
          coverPhotoButton: 'Cambiar Foto de Portada',
          descriptionLabel: 'Descripción/Resumen (Opcional)',
          products: {
            label: 'Productos utilizados',
          },
          productsUsedLabel: 'Productos Usados',
          suggestedTitleButton: 'Usar Este Título',
          suggestedTitleLabel: 'Título Sugerido:',
          title: {
            create: 'Nueva Exhibición',
            edit: 'Editando Exhibición',
          },
          typeLabel: 'Tipo de Exhibición',
          types: {
            label: 'Tipos',
          },
          visbilityLabel: 'Habilitado',
        },
        minAssetsError: 'Seleccione al menos una foto.',
        minCoverPhotoError: 'Selecciona una foto de portada.',
        minProductsError:
          'Para continuar, debes seleccionar al menos un producto del proyecto.',
        minTypesError:
          'Para continuar, debes seleccionar al menos un tipo del proyecto.',
        noTitleError: 'Para continuar, dale a la exhibición un título.',
        showcaseCreated: 'Exhibicion creada exitosamente.',
        showcaseFailed: 'No se pudo crear la exhibicion.',
      },
    },
    list: {
      pagination: {
        rowLimitLabel: 'Filas por página',
      },
    },
    publishDropdown: {
      deleteShowcaseModal: {
        error: 'Oops! Hubo un error al borrar esta Galería de Proyecto',
        success: 'Borrada exitosamente!',
      },
      main: {
        deleteConfirmButton: 'Eliminar Exhibición',
      },
      visibilityToggle: {
        error: {
          publish: 'No pudimos activar esta exhibición en este momento.',
          unpublish: 'No pudimos desactivar esta exhibición en este momento.',
        },
      },
    },
    setupGuide: {
      buttonLabel: 'Copiar fragmento de código',
      heading: 'Instalar Widget de Exhibiciones',
      help: {
        instructions1: '¿Necesitas más ayuda? Echa un vistazo a nuestro ',
        instructions2: 'guía de configuración ',
        instructions3:
          'que incluye un recorrido en video e instrucciones con más detalles sobre instalar el widget en los creadores de sitios web más populares.',
      },
      nullShowcase: {
        instructions:
          'Ya no has publicado ningún proyecto. Selecciona un proyecto que quieres publicar y pasa por el proceso de "Crear nueva exhibición".',
      },
      root: {
        instructions:
          'Copia y pega este codigo en el lugar que quieras que se muestre tu galería en tu sitio. Puedes mostrarlo en cualquier lugar en el <body> mientras sea puesto arriba del codigo del Paso 1.',
      },
      script: {
        instructions:
          'Copia y pega este código en el tag <body> de tu sitio. Recomendamos ponerlo justo antes de el tag de cierre </body>.',
      },
      stepLabel: 'Paso %{number}',
    },
    v2PublishWizard: {
      editShowcase: {
        error: 'Oops! No pudimos actualizar esta exhibición.',
        success: 'Actualizada Existosamente!',
      },
    },
    popover: {
      publish: 'Publica',
      trusty: {
        heading: 'Publica exhibiciones en Trusty',
        description:
          'Sé uno de los primeros en compartir proyectos y obtener clientes potenciales de Trusty.',
        edit: 'Editar perfil',
        view: 'Ver perfil de Trusty',
        setup: 'Configurar perfil de Trusty',
        alt: 'Publicar en Trusty',
      },
      web: {
        heading: 'Publica exhibiciones en mi sitio web',
        description: 'Mantén tu sitio web actualizado con tu mejor trabajo.',
        install: 'Instalar widget para el sitio web',
        alt: 'Publicar en el sitio web',
      },
    },
    promoModal: {
      title: 'Widget de Exhibiciones',
      valueProp1: 'Muestra tu mejor trabajo a clientes potenciales',
      valueProp2: 'Incorpora fotos de trabajos directamente en tu sitio web',
      valueProp3: 'Crea y actualiza fácilmente un portafolio profesional',
    },
    trusty: {
      mapHype: {
        title: 'Pon tu Trabajo en el Mapa',
        message:
          'Publica exhibiciones en tu mapa interactivo en Trusty y consigue más negocios locales.',
        graphicAlt: 'Pines de proyectos en un mapa',
      },
    },
  },
  snippetEditor: {
    create: {
      createErrorMsg: 'No se pudo crear el fragmento. Inténtalo de nuevo',
      createSuccessMsg: 'Fragmento guardado exitosamente',
      header: 'Nuevo fragmento',
    },
    delete: {
      deleteErrorMsg: 'No se pudo eliminar el fragmento. Inténtalo de nuevo',
      deleteSuccessMsg: 'Fragmento eliminado exitosamente',
      header: 'Editar fragmento',
    },
    edit: {
      editErrorMsg: 'No se pudo editar el fragmento. Inténtalo de nuevo',
      editSuccessMsg: 'Fragmento guardado',
      header: 'Editar fragmento',
      useSnippetBtn: 'Usar fragmento',
    },
  },
  snippets: {
    form: {
      errorMsg:
        'Hmmm. Hubo un problema. Asegúrate de haber agregado un título y un fragmento de texto.',
      title: 'Título',
    },
    modal: {
      title: 'Fragmentos de texto',
    },
    savedSnippets: {
      createBtn: 'Crear nuevo',
      searchPlaceholder: 'Buscar un fragmento de texto…',
    },
    snippetEditor: {
      snippetsDetailMsg: 'Haz clic en un fragmento para ver más detalles.',
      snippetsInfoContent:
        'No más copiar y pegar o escribir todas las palabras de nuevo. Los fragmentos te permiten guardar bloques de texto reutilizables.',
      snippetsInfoHeader: '¡Ya has ahorrado tiempo!',
    },
    snippetsList: {
      createBtn: '¡Crear un fragmento!',
      emptyMsg: '¡Qué pena! No hay fragmentos.',
      newSnippetInfo: '(Los fragmentos guardados aparecerán aquí)',
      newSnippetTitle: 'Título del fragmento de texto',
      useBtn: 'Usar',
    },
  },
  taskLists: {
    altText:
      'Imagen de una lista de tareas con indicador de progreso y fotos incluidas.',
    emptyMessage:
      'Realice un seguimiento de lo que se debe hacer y quién debe hacerlo.',
    emptyTitle: 'No Dejes Caer la Pelota',
  },
  template: {
    shared: {
      addTemplates: 'Agregar a la Plantilla de Proyecto',
      clearSelection: 'Borrar Selección',
      createChecklistErrorMsg:
        'Algo salió mal al crear una plantilla de lista de verificación. Inténtalo de nuevo.',
      createReportErrorMsg:
        'Algo salió mal al crear una plantilla de informe. Inténtalo de nuevo.',
      defaultEmptyStateMsg:
        'Crear una nueva plantilla en la página de Plantillas',
      deleteChecklistTemplateMsg:
        '¿Estás seguro que quieres eliminar esta plantilla?',
      deleteChecklistTitle: 'Eliminar plantilla de lista de verificación',
      deleteReportTemplateMsg:
        '¿Estás seguro que quieres eliminar esta plantilla?',
      deleteReportTitle: 'Borrar plantilla de informe',
      deleteTemplateButtonLabel: 'Eliminar',
      editingReportTemplateError: 'Hubo un error al guardar de la plantilla',
      emptyChecklistTemplatesMsg:
        'Optimiza tus proyectos con plantillas de lista de verificación',
      emptyCompanyDocumentMsg:
        'Optimice tus proyectos con Documentos de proyecto',
      emptyProjectTemplatesMsg:
        'Optimice tus proyectos con plantillas de proyectos',
      emptyReportTemplatesMsg:
        'Optimiza tus proyectos con plantillas de informes',
      genericEditTemplateError: 'Hubo un error al guardar de la plantilla',
      templateCreateError:
        'Algo salió mal al crear una plantillas de página. Inténtalo de nuevo.',
      goToTemplates: 'Ve a Plantillas',
      manageDocuments: 'Manejar Documentos',
      projectTemplatesTooltip: 'Administra plantillas de proyectos aquí',
      templateDeletedError: 'Hubo un error al eliminar esta plantilla',
      templateDeletedSuccess: 'Plantilla eliminada',
      templateCreatedError: 'Ocurrió un error al crear la plantilla',
    },
  },
  pageTemplates: {
    preview: {
      editTemplate: 'Editar Plantilla',
      createFromTemplate: 'Crear desde Plantilla',
      createFromTemplateSuccess: 'Página creada exitosamente',
      useTemplate: 'Utilizar la Plantilla',
    },
  },
  templates: {
    shared: {
      addLabels: 'Agregar etiquetas de proyecto',
      editLabels: 'Editar Etiquetas',
      addChecklists: 'Agrega lista de tarea ',
      addDocumentButton: 'Agrega documento',
      addPages: 'Agrega páginas',
      addReports: 'Agrega informes',
      addsDocuments: 'Agrega documentos',
      checklistModalHeader: 'Agrega listas de tareas',
      checklists: 'Listas de Tareas',
      companyDocuments: 'Documentos de Proyecto',
      createChecklistTemplate: 'Crear plantilla de lista de verificación',
      createPage: 'Crear plantilla de página',
      createProject: 'Crear plantilla de proyecto',
      createReport: 'Crear una plantilla de informe ',
      defaultProjectTemplateTooltip:
        'Establezca una plantilla de proyecto predeterminada para que se preseleccione automáticamente en cualquier proyecto nuevo, incluidos los creados a través de integraciones.',
      defaultTemplateBadge: 'Plantilla Predeterminada',
      deletePageTemplateMsg:
        '¿Estás seguro que quieres eliminar esta plantilla de página? Eliminando esta plantilla de página no eliminará las plantillas individuales contenidas en ella.',
      deletePageTitle: 'Borrar plantilla de página',
      deleteProjectTemplate: {
        message:
          '¿Estás seguro de que deseas eliminar esta plantilla de proyecto? Eliminando esta plantilla de proyecto no eliminará las plantillas individuales contenidas en ella.',
        title: 'Eliminar plantilla',
      },
      deleteTemplateError:
        'Algo salió mal al eliminar tu plantilla. Inténtalo de nuevo.',
      deleteTemplateSuccess: 'La plantilla se eliminó con éxito',
      documentFileTypeColumn: 'Tipo de Archivo',
      documentsTab: 'Documentos',
      editingReportTemplateDisclaimer:
        'Actualmente estás editando una plantilla. Los cambios que realices no afectarán a los informes creados anteriormente.',
      emptyPageTemplatesMsg: 'Optimiza tus proyectos con plantillas de páginas',
      errorSetDefaultProjectTemplate:
        'Hubo un error al configurar esta plantilla predeterminada',
      errorUnsetRemoveDefaultProjectTemplate:
        'Hubo un error al eliminar esta plantilla predeterminada',
      lastUpdatedHeader: 'Última actualización',
      managePageTemplates: 'Manejar Plantillas de Página',
      manageReportTemplates: 'Manejar Plantillas de Informe',
      manageTaskListTemplates: 'Manejar Plantillas de Lista de Tareas',
      multiSelectModal: {
        empty: 'No se encontró ninguna plantilla',
      },
      onboardModalBtnLabel: 'Vamos',
      onboardModalDescription:
        'Crea, edita y guarda tus plantillas de listas de tarea e informes en un solo lugar, sin tener que entrar en un proyecto.',
      pages: 'Páginas',
      deleteProjectTemplateMessage:
        '¿Estás seguro de que deseas eliminar esta plantilla de proyecto? Eliminando esta plantilla de proyecto no eliminará las plantillas individuales contenidas en ella.',
      deleteProjectTemplateTitle: 'Eliminar plantilla',
      pagesTab: 'Páginas',
      projectTemplateCompanyDocumentEmptyState:
        'Todas las plantillas de documentos de proyecto disponibles se han agregado a esta plantilla de proyecto.',
      projectTemplatePageEmptyState:
        'Todas las plantillas de página disponibles se han agregado a esta plantilla de proyecto.',
      projectTemplateReportEmptyState:
        'Todas las plantillas de informes disponibles se han agregado a esta plantilla de proyecto.',
      projectTemplateTaskListEmptyState:
        'Todas las plantillas de lista de verificación disponibles se han agregado a esta plantilla de proyecto.',
      projectsTab: 'Proyectos',
      reportModalHeader: 'Agrega reportes',
      reports: 'Reportes',
      reportsTab: 'Reportes',
      setDefaultProjectTemplate: 'Establecer como plantilla predeterminada',
      setDefaultProjectTemplateCancel: 'No, no establecer',
      setDefaultProjectTemplateConfirm: 'Establecer plantilla predeterminada',
      setDefaultProjectTemplateMessage:
        'Está seguro de que desea usar esto como una plantilla predeterminada? Cualquier proyecto creado a través de una integración aplicará automáticamente esta plantilla',
      setDefaultProjectTemplateTitle: 'Establecer Plantilla Predeterminada',
      succcesUnsetRemoveDefaultProjectTemplate:
        'Plantilla predeterminada eliminada con éxito',
      succcessSetDefaultProjectTemplate:
        'Establecer correctamente la plantilla predeterminada',
      taskListsTab: 'Listas de Tareas',
      templateDescriptionPlaceholder: 'Agregue una descripción de plantilla',
      templateNameHeader: 'Nombre de Plantilla',
      templateTitlePlaceholder: 'Escribir título de la plantilla',
      templatesHeader: 'Plantillas',
      templatesSubheader: 'Crea y gestione plantillas en toda tu empresa.',
      unsetDefaultProjectTemplate: 'Eliminar como plantilla predeterminada',
      unsetDefaultProjectTemplateCancel: 'No, no eliminar',
      unsetDefaultProjectTemplateConfirm: 'Eliminar plantilla predeterminada',
      unsetDefaultProjectTemplateMessage:
        'Está seguro de que desea eliminar esto como plantilla predeterminada? Los proyectos creados a través de integraciones no tendrán una plantilla aplicada automáticamente',
      unsetDefaultProjectTemplateTitle: 'Eliminar plantilla predeterminada',
      noRecentlyUsedTemplates: 'No hay plantillas usadas recientemente',
      updatedTime: 'Actualizado el %{date} a las %{time}',
    },
  },
  todosMarketplace: {
    index: {
      description:
        'Listas diseñadas por líderes de la industria para hacer tu vida mas fácil.',
      emptyPartnersMsg: 'No se han encontrado socios',
      onboardModalBtnLabel: 'Vamos',
      onboardModalDescription:
        'Añade a tu cuenta plantillas de fabricantes, franquicias, o comercio en general. Cualquier persona de tu empresa puede utilizarlas al crear una nueva lista.',
      restrictedAccessBanner:
        'Tu plan actual no incluye el acceso a %{featureName}.',
      restrictedAccessBannerMsg:
        'Tu plan actual no incluye el acceso a las %{featureName}.',
      title: 'Listas de Tareas de la Comunidad',
    },
    partner: {
      accessRequested: 'Acceso solicitado',
      addTemplateButton: 'Añadir',
      addTemplateDeleteMsg: 'Plantilla eliminada de la biblioteca',
      addTemplateErrorMsg:
        'No se pudo agregar la plantilla a la biblioteca. Inténtalo de nuevo',
      addTemplateSuccessMsg: 'Plantilla Agregada a la Biblioteca',
      added: 'Agregado',
      addedColHeader: 'Agregado a librería',
      addedTemplateModalMsg:
        'Ahora puedes usar esta plantilla cuando crees una nueva lista de tareas de una Plantilla Existente.',
      addedTemplateModalTitle: 'Plantilla Agregada',
      cardTooltip: 'Haga clic en un socio para explorar sus plantillas',
      deleteTemplateErrorMsg:
        'No se pudo eliminar la plantilla de la biblioteca. Inténtalo de nuevo',
      detailsColHeader: 'Detalles de plantilla',
      notAdded: 'No agregado',
      onboardToolTipMsg:
        'Agregue automáticamente plantillas nuevas y existentes a su biblioteca.',
      removeTemplateButton: 'Eliminar',
      removeTemplateTooltip:
        'Anular la suscripción para añadir o eliminar individualmente',
      requestAccess: 'Solicitar acceso',
      requestErrorMsg:
        'No se ha podido solicitar el acceso. Por favor, inténtalo de nuevo.',
      requestModalConfirmBtn: 'Lo entiendo',
      requestModalMessage:
        'Se le notificará por email cuando este socio haya respondido. Si se concede el acceso, las plantillas de este socio estarán disponibles en la opción de Usar Plantillas Existentes en el menú de Crear lista de tareas de un proyecto.',
      restrictedToolTipMsg:
        'Se requiere permiso para acceder a las plantillas.',
      subscribe: 'Suscribir',
      subscribedErrorMsg: 'No se ha podido suscribir al socio',
      subscribedModalMsg:
        'Ahora puedes usar todas las plantillas de listas de tareas de este socio. Las plantillas nuevas seran agregadas automaticamente a tu libreria también.',
      subscribedModalTitle: 'Todas las Plantillas Agregadas',
      subscribedSuccessMsg: {
        one: 'Review %{templateCount} plantilla agregado',
        other: 'Review %{templateCount} plantillas agregados',
      },
      unsubscribe: 'De-suscribir',
      unsubscribeModalMessage:
        'Desuscribirse removerá todas estas plantillas de su libreria de plantillas.',
      unsubscribeModalTitle: '¿Estás seguro?',
      unsubscribedErrorMsg: 'No se ha podido de-suscribir al socio',
      unsubscribedSuccessMsg: {
        one: 'Review %{templateCount} plantilla removido',
        other: 'Review %{templateCount} plantillas removidos',
      },
    },
  },
  trusty: {
    valuePropsModalNew: {
      description:
        'Da acceso a Trusty, el nuevo portal de clientes de CompanyCam, y coordina proyectos como nunca antes.',
      title: 'Portal del Cliente',
      valueProp1: 'Recibe fotos de clientes en CompanyCam',
      valueProp2: 'Haz que los clientes te paguen directamente desde Trusty',
      valueProp3: 'Recibe notificaciones de la actividad del cliente',
    },
  },
  upgradeBadge: {
    label: 'Actualiza para acceso',
  },
  useTemplateModal: {
    redesignTitle: 'Elige Una Plantilla de Proyecto',
    redirectButton: 'Nueva plantilla de proyecto',
    defaultTitle: 'Elegir plantilla',
    chooseButton: 'Elegir plantilla',
    loadingProjectTemplates: 'Cargando plantillas de proyecto...',
    loadingPreview: 'Cargando vista previa de la plantilla...',
    navigateToTemplates: 'Ver Plantillas de Proyecto',
    emptyStateTitle: 'Trabaja Más Rápido con Plantillas',
    emptyStateSubTitle:
      'Ve a Plantillas para crear tu primera Plantilla de Proyecto',
    emptyStateSubTitleRestricted:
      'Contacta a tu administrador o gerente para crear una Plantilla de Proyecto',
    emptyStateListTitle: 'No se Encontraron Plantillas',
    emptyStateListSubTitle: 'Intenta buscar con otro término',
    emptyStatePreviewTitle: 'Vista Previa No Disponible',
    noItemsAdded: 'No se han agregado %{item}',
    deleteModal: {
      body: '¿Estás seguro de que quieres eliminar esta plantilla de página? No podrás deshacer esta acción.',
      title: 'Eliminar plantilla de página',
    },
    dropdownArrowAccessibilityLabel: 'Dropdown arrow',
    editButtonLabel: 'Editar Plantilla',
    previewButtonLabel: 'Vista Previa de la Plantilla',
    searchPlaceholder: 'Buscar una plantilla',
    getTemplatesErrorMessage: 'Algo salió mal al cargar su plantilla',
    previewCaption: 'Vista previa',
  },
  user: {
    index: {
      newInvitationButton: 'Invitar Usuarios',
    },
    notificationSettings: {
      activityOnInteractedPhotoLabel: 'Responde a mis comentarios',
      activityOnInteractedPhotoTooltip:
        'Si un usuario interactua con cualquier foto despúes de usted, se lo haremos saber.',
      notificationsSettingsMessageSectionOne:
        'Me gustaría recibir notificaciones...',
      notificationsSettingsMessageSectionTwo: 'Notifíquenme cuando alguien...:',
      notifyByEmailLabel: 'Como emails',
      notifyByEmailTooltip:
        'Las notificaciones serán enviadas a su bandeja de entrada.',
      notifyByPushNotificationLabel: 'Como notificaciones push',
      notifyByPushNotificationTooltip:
        'Las notificaciones serán enviadas a su dispositivo móvil.',
      photoReceivesActivityLabel: 'Comente en fotos que he tomado',
      photoReceivesActivityTooltip:
        'Si hay actividad en una de las fotos que usted creó, se lo haremos saber.',
      unexpectedError: 'Un error inesperado ha ocurrido',
    },
    notificationsSettingsModal: {
      notificationsSettings: 'Configuración de notificación',
    },
    profilePhoto: {
      changePhoto: 'Cambiar foto',
    },
    uploadProfilePhotoModal: {
      previewYourPhoto: 'Vista previa de su foto',
      uploadProfilePhoto: 'Descargue la foto del perfil',
      modalInstructions:
        'Arrastra y soltar una foto aqui o clic para descargar una nueva foto del perfil.',
      upload: 'descargar',
      uploading: 'Cargando…',
    },
  },
  users: {
    deactivateUserModal: {
      confirmationMessage:
        '¿Estás seguro que quieres desactivar "%{userName}"?',
      noDontDeactivate: 'No, no desactivar',
      title: 'Desactivar Usuario',
      yesDeactivate: 'Sí, desactivar',
    },
    users: {
      deactivate: 'Desactivar',
      deactivated: 'Desactivado',
      editNameAccessiblityLabel: 'Editar nombre',
      editUser: 'Editar usuario',
      inviteSent: 'Invitación creada el %{date}',
      emptyState: {
        title: 'Recopila fotos de tu equipo.',
        message:
          'Consejo: Saca las fotos de trabajo de la galería de tu equipo invitándolos a tomar imágenes en CompanyCam.',
      },
      invitedUser: 'Usuario Invitado',
      nameSavedSuccessMessage: 'Nombre guardado con éxito',
      noActivityMessage: 'No actividad',
      reactivate: 'Reactivar',
      manage: 'Administrar %{userName}',
      resendInvite: 'Reenviar invitación',
      roleTooltipMessage: 'Cambia los roles de usuario aquí!',
      roles: {
        admin: {
          description: 'Tiene control total sobre la cuenta.',
          label: 'Admin',
        },
        manager: {
          description:
            'Accesao a todos los proyectos y puede administrar usuarios.',
          label: 'Manager',
        },
        restricted: {
          description:
            'Solo pueden acceder a los proyectos que crean o a los que están asignados.',
          label: 'Restricted',
        },
        standard: {
          description:
            'Acceso a todos los proyectos pero no puede administrar usuarios.',
          label: 'Standard',
        },
      },
      toastBody: {
        one: 'Revisa %{count} usuario',
        other: 'Revisa %{count} usuarios',
      },
      toastHeadline: {
        one: 'Invitacion enviadas!',
        other: 'Invitaciones enviadas!',
      },
    },
    user: {
      inlineNameEdit: {
        cancel: 'Cancelar edición de nombre',
        firstNameLabel: 'Primer Nombre',
        firstNameRequiredError: 'El nombre no puede estar en blanco',
        firstNameTooLongError: 'El primer nombre es demasiado largo',
        lastNameLabel: 'Apellido',
        lastNameRequiredError: 'El apellido no puede estar en blanco',
        lastNameTooLongError: 'El apellido es demasiado largo',
        save: 'Guardar nombre',
      },
    },
  },
  valueProps: {
    beforeAfter: {
      description:
        'Combina tus fotos de antes y después en una sola imagen que muestre tu trabajo.',
      title: 'Cámara antes y después.',
      valuePropA: 'Elige fotos de tus proyectos.',
      valuePropB: 'Selecciona un diseño de nuestra galería de planillas',
      valuePropC: 'Marca tu imagen con tu logo',
      valuePropD: 'Compartir en tu sitio o páginas sociales',
    },
    collabChecklists: {
      description: 'Organízalo. Hazlo.',
      title: 'Colabora en las Listas de Tareas',
      valuePropA:
        'El dueño del proyecto te echa una Lista de Tareas. Complétala, gana dinero, y ¡vete a casa!',
      valuePropB:
        'Mantén a tu gente enfocada en lo que necesitan hacer, mientras sigues el progreso.',
      valuePropC:
        'Evita los errores de "oh rayos, se me olvidó hacer eso". Oye, nos pasa a todos.',
    },
    gallerylinks: {
      description:
        'Selecciona imágenes especificas o comparte cada foto en un proyecto con un solo link.',
      title: 'Compartir Galería',
      valuePropA:
        'Elige si quieres incluir los detalles de la foto (como quien la tomó y donde)',
      valuePropB: 'Genera un link para copiar y pegar o enviar por sms o email',
      valuePropC: 'Enviar a quien sea, incluso si no tienen CompanyCam',
    },
    projectCollaboration: {
      description:
        'Invita a las empresas con las que trabajas a unirse a tus proyectos.',
      title: 'Colaboración en Proyectos',
      valuePropA: 'Accede instantáneamente  tus fotos y videos',
      valuePropB: 'Incluirlos en la conversación del proyecto',
      valuePropC: 'Añadir colaboradores ilimitados',
    },
    reports: {
      description:
        'Create clean, customized reports that you can share from anywhere.',
      title: 'Reportes de Fotos',
      valuePropA: 'Elige tu diseño y agrega fotos',
      valuePropB: 'Personaliza la información de proyecto.',
      valuePropC: 'Guarda tu reporte como PDF',
      valuePropD: 'Genera un link para compartir en segundos',
    },
    shared: {
      dismiss: 'Entendido',
      freePremiumSample: 'Pruébelo gratis',
      upgradeToPro: 'Mejorar',
    },
    taskListAssign: {
      description: 'Manten a tu equipo responsable y productivo',
      title: 'Asignar listas de verificación',
      valuePropA:
        'Asigne una lista de verificación a uno o más usuarios en tu cuenta',
      valuePropB:
        'Los usuarios asignados reciben una notificación en tu dispositivo, informándoles que hay trabajo por hacer',
      valuePropC:
        'Evita los errores de "Oh, wow, olvidé hacer eso". Oye, todos lo hacemos!',
    },
    timelinelinks: {
      description:
        'Envía un link del proyecto que le deja a los miembros de tu equipo y clientes ver las fotos a medida que se agregan.',
      title: 'Links de Línea Temporal',
      valuePropA: 'Muestra cada foto del trabajo en orden cronológico',
      valuePropB: 'Genera un link para copiar y pegar o enviar por sms o email',
      valuePropC: 'Enviar a quien sea, incluso si no tienen CompanyCam',
    },
    todos: {
      description: 'Organízalo. Hazlo.',
      title: 'Listas de Tareas',
      valuePropA:
        'Cree fácilmente listas de tareas pendientes dentro de sus proyectos.',
      valuePropB:
        'Mantenga a su gente enfocada en lo que necesitan hacer, mientras sigues su progreso.',
      valuePropC:
        'Evite los errores de "oh rayos, se me olvidó hacer eso". Oye, nos pasa a todos.',
    },
  },
  videos: {
    singleDownloadModal: {
      modified:
        "Es posible que debas hacer clic con el botón derecho y seleccionar 'Save link as…'",
    },
    singleShareModal: {
      shareVideo: 'Compartir vídeo',
    },
    singleVideoDownloadModal: {
      downloadVideo: 'Descargar vídeo',
    },
  },
  viewerFavorites: {
    starredItems: {
      emptyState:
        'Destaque proyectos, usuarios y grupos para acceder fácilmente desde cualquier página.',
      header: 'Elementos Destacados',
      limitInfo:
        'Hay un límite máximo de 50 elementos destacados que se pueden mostrar en esta lista. Tendrá que desmarcar algunos elementos para asegurar que todos estén siempre visibles.',
    },
  },
  hooks: {
    useIgnorableConfirmation: {
      defaultIgnorableLabel: 'No vuelvas a mostrar este mensaje',
    },
  },
  labels: {
    labelForm: {
      colorLabel: 'Color',
      delete: 'Eliminar etiqueta',
      nameLabel: 'Nombre',
      save: 'Salvar Etiqueta',
      placeholder: 'Asigna un nombre a tu nueva etiqueta...',
    },
    labelsContainer: {
      createButton: 'Nueva Etiqueta',
      modalTitle: 'Seleccionar etiquetas',
      saveButton: 'Salvar',
    },
    labelsModal: {
      createLabel: 'Crear etiqueta',
      deleteConfirmationInstructions:
        'Esta seguro/a que quiere eliminar esta etiqueta?',
      deleteCompanyWideWarning:
        'La etiqueta de proyecto será eliminada en toda la empresa y ya no estará disponible para proyectos futuros.',
      deleteProjectWideWarning:
        'Se eliminará de todos los proyectos donde actualmente esté aplicada.',
      editForm: 'Editar etiquetas',
      modalTitle: 'Seleccionar etiquetas',
      nameErrorMsg: 'Asigna un nombre a la etiqueta',
      chooseColorErrorMsg: 'Elige un color para tu etiqueta',
      moreOptions: 'Más opciones',
      noLabelSelected: 'No se seleccionaron etiquetas',
      searchLabels: 'Buscar etiquetas',
      tryAnotherTerm: 'Intenta con otro término de búsqueda.',
      noLabelsFound: 'No se encontraron etiquetas de proyecto.',
    },
  },
  legal: {
    title: 'Legal',
    termsOfService: 'Términos del Servicio',
    privacyPolicy: 'Política de Privacidad',
    termsOfUse: 'Términos de Uso',
    noticeAtCollection: 'Aviso en la Recolección',
    RightToOptOut: 'Derecho a Optar por No Participar',
    copyright: 'CompanyCam. Todos los derechos reservados.',
    companySettings: {
      title: 'Opciones de Privacidad',
      description:
        'Tienes control sobre tus datos personales, y nos encantaría ayudarte a entender tus opciones. Para más detalles, consulta nuestro ',
    },
    agreeTo: 'I agree to',
    contentUpload:
      'Al cargar este Contenido, declaras que tienes los derechos y la autoridad para compartir toda la información del Contenido con CompanyCam, incluida cualquier Información Personal que te pertenezca a ti o a un tercero. Ver los ',
    complete: ' completos.',
  },

  organization: {
    accountAdminBadge: 'Administrador de Cuenta',
    checklistMetricMetadataSecondary: '%{count}% completados',
    columnHeaderAccount: 'Cuenta',
    columnHeaderChecklists: 'Listas de Tareas',
    columnHeaderDocuments: 'Documentos',
    columnHeaderPhotos: 'Fotos',
    columnHeaderProjects: 'Proyectos',
    columnHeaderReports: 'Informes',
    columnHeaderbillableUsers: 'Usuarios Facturables',
    companiesErrorMessage:
      'Hubo un error al cargar sus empresas. Actualice la página para volver a intentarlo.',
    currentUsersTitle: 'Usuarios Actuales',
    dataRefreshDateInfoMessage:
      '<strong>Los datos de esta página se actualizan cada 24 horas.</strong> Ùltima Actualización: %{date}',
    featureUtilizationLabel:
      'Desde <strong>%{date} hasta hoy,</strong> tus cuentas han añadido',
    lastRefreshDateText: 'Última actualización',
    loadingAccounts: 'Cargando cuentas…',
    loadingCompanies: 'Cargando Empresas',
    metricBillableUserMetadataPrimary:
      '<strong>%{count}</strong> en los últimos 30 días',
    metricBillableUserTitle: 'Facturables',
    metricDeactivatedUserTitle: 'Desactivados',
    metricInactiveUserTitle: 'Inactivos',
    metricInactiveUserTooltip:
      'No haber completado una acción en los últimos 30 días',
    metricMetaDataPrimary: '%{count}/proyecto',
    metricUnacceptedUserTitle: 'Invitados',
    metricsErrorMessage:
      'Hubo un error al cargar los datos de su organización. Actualice la página para volver a intentarlo.',
    noAccountsFoundMessage: 'No se encontraron cuentas.',
    noAccountsFoundTitle: 'Rayos.',
    projectMetricMetadata: '%{count} Exhibiciones',
    thirtyDayOverviewTitle: 'Resumen de 30 días',
  },
  taskList: {
    templates: {
      partners: 'Hubo un error al cargar tus socios de plantillas.',
    },
  },
  yearInReview: {
    welcome: {
      header: {
        lineOne: 'Bienvenido a tu',
        lineTwo: 'resumen del año',
      },
      description1: 'Esto es lo que ',
      description2: '%{companyName} ',
      description3: 'hizo en 2024',
    },
    projectsCreated: {
      title: 'Proyectos\nCreados',
      positive_description1: 'Estuviste ',
      positive_description2: 'ocupado ',
      positive_description3: 'este año.',
      negative_description1: '¿Sabías que tienes almacenamiento ilimitado? 😉',
    },
    photosTaken: {
      title: 'Fotos Tomadas',
      positive_description1:
        'Tus clientes deben dormir tranquilos por la noche con toda ',
      positive_description2: 'esa paz mental.',
      negative_description1: 'Fotos o no sucedió. Entonces… ¿sucedió?',
    },
    galleries: {
      title: 'Galerías\nCreadas',
      positive_description1: 'Si ',
      positive_description2: 'compartir fotos es demostrar cariño, ',
      positive_description3: 'este año realmente cuidaste a tus clientes.',
      negative_description1:
        'Haces un trabajo increíble, ¡muéstralo con una galería de fotos de proyectos con tu marca! Mira: ',
      negative_description_link: 'Cómo compartir fotos con una Galería',
    },
    videosCaptured: {
      title: 'Videos\nCapturados',
      positive_description1: 'Y el ',
      positive_description2: 'premio de la Academia ',
      positive_description3: 'es para…',
      negative_description:
        'Elimina la ida y vuelta mostrando y contando lo que sucede en el trabajo.',
    },
    pages: {
      title: 'Páginas\nHechas',
      positive_description1:
        '¡Mírate a ti—manteniendo a tus equipos y clientes ',
      positive_description2: 'en la misma Página(s)!',
      negative_description1:
        'Saca tus notas de tu cabeza y ponlas en una página organizada y compartible. Leer: ',
      negative_description_link: 'Cómo Crear y Utilizar Páginas.',
    },
    checklistsCreated: {
      title: 'Listas de\nVerificación Creadas',
      positive_description1: 'Hacer las cosas ',
      positive_description2: 'bien a la primera ',
      positive_description3:
        'es la clave para ganarse el corazón de tus clientes.',
      negative_description1:
        'Tachar cosas de la lista se siente muy bien. Inténtalo. Mira: ',
      negative_description_link: 'Cómo Crear una Lista de Verificación',
    },
    CoCamInfo: {
      newFeatureHeader: 'Nuevo en CompanyCam:',
      aiFeatureHeader: 'Herramientas impulsadas por IA',
      aiFeatureDescription:
        'Este año, lanzamos acciones poderosas y efectivas que utilizan la magia de la IA para hacer tu vida más fácil.',
      aiFeatureLabel: '¿No has oído hablar de las acciones de IA?',
      aiFeatureCTA: 'Aprender más',
      photoStatHeader: 'Fotos tomadas',
      photoStatDescription:
        '¡Caray! ¡Eso es un montón de fotos tomadas en CompanyCam! Piensa en cuántas veces esas fotos te salvaron el pellejo, sin mencionar todo el espacio extra que dejaron en los rollos de cámara para fotos de tus mascotas. ¡Eso sí es un ganar-ganar!',
      usersStatLabel: 'Nuevos usuarios añadidos',
      usersStatDescription:
        'Tener a todo el equipo en CompanyCam significa que todos pueden encontrar la información y las actualizaciones de proyectos que necesitan. ¡Eso incluye a Barb en la oficina y a Sam haciendo ventas! Añade a todo tu equipo en tu',
      usersStatDescriptionLink: ' página de facturación.',
      projectsStatLabel: 'Proyectos Creados',
      projectsStatDescription:
        'Organización, comunicación y productividad alcanzaron su punto más alto este año. El número de proyectos creados en CompanyCam también fue récord. ¿Casualidad? Nosotros creemos que no.',
      projectsUpgradeStatLabelLink: 'Actualiza a Pro o Premium',
      projectsUpgradeStatLabelTwo: ' para obtener proyectos ilimitados.',
      templatesStatLabel: 'Plantillas creadas',
      templatesStatDescription:
        'La cantidad de tiempo ahorrado con las plantillas fue suficiente para ver cada segundo de los Juegos Olímpicos de París, ¡incluso el breakdance!',
      templatesUpgradeStatLabelLink: 'Actualiza a Premium',
      templatesUpgradeStatLabelTwo: ' y prueba las plantillas!',
      paymentsStatLabel: 'Pagos Recibidos',
      paymentsStatDescription:
        'Finalmente, una manera más fácil para que los contratistas reciban pagos. ¡Diles a tus clientes que dejen sus chequeras en casa!',
      paymentsStatDescriptionLink: '¡Prueba los pagos hoy mismo!',
      integrationsStatLabel: 'Integraciones lanzadas',
      integrationsStatDescription1:
        'Hicimos tantos nuevos amigos este año que finalmente nos sentimos como el chico genial en el patio de recreo. Ahora puedes ',
      integrationsStatDescription2:
        'CompanyCam con QuickBooks y AccuLynx, y pronto estará disponible ServiceTitan!',
      integrationsStatLink: 'integrar ',
      podcastStatLabel: 'Minutos de charla',
      podcastStatDescription:
        'Lanzamos nuestro podcast Good Contractor este año y hemos tenido el honor de contar con increíbles invitados que se han unido para hablar sobre lo que se necesita para ser un buen contratista. ¡Consulta nuestros últimos episodios ',
      podcastStatDescriptionLink: 'aquí!',
      socialShareHeaderBasic:
        'Want a personalized landing page with your company’s stats next year? Upgrade to Pro or Premium on your ',
      socialShareHeaderBasicLink: 'billing page!',
      socialShareHeader: '¡Comparte tu año!',
      socialShareBody:
        'Muestra lo que tu empresa logró en 2024. Make sure to tag us in your post!',
      socialShareWarn:
        'Atención: compartir esto generará un enlace público. ¡Quien tenga acceso al enlace también tendrá acceso a tus estadísticas!',
      shareCTA: 'Compartir en Redes Sociales',
    },
    navList: {
      projectsCreated: 'PROYECTOS CREADOS',
      photosTaken: 'FOTOS TOMADAS',
      videosCaptured: 'VIDEOS CAPTURADOS',
      galleriesMade: 'GALERÍAS CREADAS',
      pagesMade: 'PÁGINAS HECHAS',
      checklistsMade: 'LISTAS DE VERIFICACIÓN CREADAS',
    },
    statsHeaders: {
      header: 'AÑO EN REVISIÓN',
      photosTaken: 'Fotos tomadas',
      projectsCreated: 'Proyectos creados',
      videosCaptured: 'Videos capturados',
      galleriesMade: 'Galerías creadas',
      pagesMade: 'Páginas creadas',
      checklistsCreated: 'Listas de verificación creadas',
    },
  },
  portfolio: {
    showcase: {
      emptyTitle: '¿Listo para mostrar tu trabajo?',
      emptySubtitle:
        'La información en tu perfil ayuda a que los clientes te encuentren en Google y Trusty.',
      trustyLive: '¡Tu perfil de Trusty está en línea!',
    },
    profile: {
      changeProfilePhoto: 'Cambiar Foto de Perfil',
      emptyTitle: 'Crea un perfil en Trusty para acceder a tu portafolio.',
      emptySubtitle:
        'Your profile acts as a digital business card, helping customers find you and your work.',
      setupTitle: 'Consigue más de los trabajos que deseas.',
      setupSubtitleA:
        'Estamos construyendo una nueva forma para que los propietarios de viviendas encuentren, recomienden e interactúen con contratistas en ',
      setupSubtitleB: 'Configura tu perfil de Trusty para:',
      subheader2:
        'Indica a los clientes dónde estás ubicado y cómo pueden contactarte.',
      subheader3:
        'Ayúdanos a categorizar tu negocio para que los clientes puedan buscarte.',
      companyName: 'Nombre de la Empresa',
      address1: 'Dirección 1',
      address2: 'Dirección 2',
      industries: 'Industrias',
      serviceAreas: 'Áreas de Servicio',
      serviceAreasPlaceholder: '¿En qué ciudades te gustaría aparecer listado?',
      setupStep2: 'Configuración del Perfil (1/2)',
      setupStep3: 'Configuración del Perfil (2/2)',
      valueProp1: 'Crear un portafolio personalizado con tu mejor trabajo',
      valueProp2:
        'Mantener todas tus reseñas y fotos de trabajos anteriores en un solo lugar',
      valueProp3: 'Mostrar a los propietarios un mapa interactivo de proyectos',
      googleBusinessSearch: 'Búsqueda en Google Business',
      googleBusinessSearchMessage:
        'Usa la información de tu cuenta de Google Business e incluye reseñas en Trusty.',
      searchGoogle: 'Busca el nombre o la dirección de tu empresa.',
      existingGoogleProfile:
        'Ya existe un perfil para esta empresa. Por favor, inténtalo nuevamente o contacta al soporte.',
      website: 'Sitio Web',
      validation: {
        companyName: 'El nombre de la empresa es obligatorio.',
        invalidPhone: 'Número de teléfono no válido.',
        invalidEmail: 'Dirección de correo electrónico no válida.',
        industries: 'Se requiere al menos 1 industria.',
        serviceAreas: 'Se requiere al menos 1 área de servicio.',
        phoneOrEmail:
          'Se requiere un número de teléfono o un correo electrónico.',
      },
      createSuccess: 'Perfil público creado exitosamente.',
      createError: 'Error al crear el perfil público.',
      updateSuccess: 'Perfil público actualizado exitosamente.',
      updateError: 'Error al actualizar el perfil público.',
      editModalTitle: 'Editar Perfil de Trusty',
      companyNamePlaceholder: 'Mi Empresa',
      address1Placeholder: '123 Main St',
      address2Placeholder: 'Suite 100',
    },
    addToWebsite: 'Agregar al Sitio Web',
    addShowcase: 'Agregar Exhibición',
    editProfile: 'Editar Perfil',
    projectSuggestions: {
      title: '¿Están estos proyectos listos para mostrar?',
    },
  },
  reminders: {
    buttonText: 'Recuérdame',
    titleText: 'Establecer un recordatorio',
    delay: {
      firstOption: 'En 30 minutos',
      secondOption: 'En 4 horas',
      thirdOption: 'En 1 día',
      fourthOption: 'En 3 días',
    },
    successMessage: `Recordatorio establecido. Recibirás una notificación %{delaySelected}.`,
    errorMessage:
      'No se pudo configurar el recordatorio. Revisa tu conexión a internet.',
  },
};
